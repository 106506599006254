import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import {CONFIG_ENV} from '@/config';
import {ServiceBrowseCategories} from '@/services/ServiceBrowseCategories';
import user from '@/store/user';
import {getModule} from "vuex-module-decorators";
import extend from 'quasar/src/utils/extend.js';;
import CategoryImg from "@/components/CategoryImg/CategoryImg.vue";
import HierarchicalChainBrowseHeader from '@/components/HierarchicalChainBrowseHeader/HierarchicalChainBrowseHeader.vue'
import eventbus from "@/store/eventbus";
import { ServiceBrowseArticles } from '@/services/ServiceBrowseArticles';
import { TArticle } from '@/types/TArticle';
import ServiceLogs from '@/services/ServiceLogs';

type TCategory ={ pid:string, name_ro:string, name_en:string, name_hu:string, isParentForArticles:string};
type infoCateg={
    "categoryPid":string,
    "categoryNameRo":string,
    "categoryNameEn":string,
    "categoryNameHu":string
}
@Options({
    name: "BrowseCategories",
    components: {CategoryImg,HierarchicalChainBrowseHeader}
})
export default class BrowseCategories extends Vue {
    @Prop({ default: '0' }) public readonly pid!: string;
    @Prop({ default: 'fromDashboard' }) public readonly typeOfAccess!: string;
    public selectedCategory: TCategory = { pid:'', name_ro:'', name_en:'', name_hu:'', isParentForArticles:''};
    public hierarchicalChain:infoCateg[] = [];
    public dialogTransitionShow='';
    public dialogTransitionHide='';
    public searchItemsText='';
    public loadingItems= false;
    public isMainCategory=false;
    public inputSearchArticle='';
    public items: TArticle[] = [];
    declare public $refs: any;
    public userStore = getModule(user);
    public categories: { pid:string, name_ro:string, name_en:string, name_hu:string, name_de:string, isParentForArticles:string, children:  { pid:string, name_ro:string, name_en:string, name_hu:string}[] }[]=[];
    public EventBusStore = getModule(eventbus);
    public columns : any = [];
    public visibleColumns =[ 'name'];
    public appid = 0;

    public browseCategory(pCategory: TCategory){
        const vueInst=this;
        this.selectedCategory=pCategory;
        console.log('browse categ ' + vueInst.typeOfAccess);
        //extend(true, this.selectedCategory,  pCategory);
        if(vueInst.typeOfAccess=='fromDashboard') {
            if (pCategory.isParentForArticles == 'y') {
                this.$router.push({name: 'BrowseArticles', params: {pidCategory: pCategory.pid}})
            } else {
                vueInst.isMainCategory=true;
                this.$router.push({
                    name: 'BrowseCategories',
                    params: {pid: pCategory.pid, typeOfAccess: 'fromDashboard'}
                })
            }
        }

        if(vueInst.typeOfAccess=='fromCerere') {
            if (pCategory.isParentForArticles == 'y') {
                vueInst.EventBusStore.set_event({name:'eventBrowseArticles',params:{pidCategory: pCategory.pid}});
            }
            else{
                vueInst.isMainCategory=true;
                vueInst.EventBusStore.set_event({name:'eventBrowseCategories',params:{pid: pCategory.pid}});
            }
        }

        if(vueInst.typeOfAccess=='fromOferta') {
            if (pCategory.isParentForArticles == 'y') {
                vueInst.EventBusStore.set_event({name:'eventBrowseArticles',params:{pidCategory: pCategory.pid}});
            }
            else{
                vueInst.isMainCategory=true;
                vueInst.EventBusStore.set_event({name:'eventBrowseCategories',params:{pid: pCategory.pid}});
            }
        }
    }

    public openItem(pid: string, categoryPid: string, product_code: string) {
        console.log("open item " + pid + ' ' + categoryPid + ' ' + product_code);
        const vueInst=this;
        if(vueInst.typeOfAccess=='fromCerere') {
            // if (vueInst.selectedCategory.isParentForArticles == 'y') {
                vueInst.EventBusStore.set_event({name:'eventBrowseArticles',params:{pidCategory: categoryPid, productPid: pid, code: product_code, typeOfAccess: 'fromCerere'}});
            // }
            // else{
            //     vueInst.isMainCategory=true;
            //     vueInst.EventBusStore.set_event({name:'eventBrowseCategories',params:{pid: vueInst.selectedCategory.pid}});
            // }
        } else {
            this.$router.push({name: 'BrowseArticles', params: {pidCategory: categoryPid, productPid: pid, code: product_code, typeOfAccess: 'categoriesItem'}})
        }
    }

    public getCategory(){
        const vueInst=this;
        ServiceBrowseCategories.getDetailsCategoryFromDB(vueInst.pid).then(response => {
            vueInst.$q.loading.hide();
            if(response.status=='success'){
                vueInst.selectedCategory.pid=response.categoryPid;
                vueInst.selectedCategory.name_ro=response.categoryNameRo;
                vueInst.selectedCategory.name_en=response.categoryNameEn;
                vueInst.selectedCategory.name_hu=response.categoryNameHu;
                vueInst.selectedCategory.isParentForArticles=response.isParentForArticles;
                vueInst.categories = response.categories;
                vueInst.hierarchicalChain = response.hierarchicalChain;
            }
        })
    }

    public getCategories(){
        const vueInst=this;
        vueInst.isMainCategory=false;
        ServiceBrowseCategories.getDetailsCategoriesFromDB().then(response => {
            vueInst.$q.loading.hide();
            if(response.status=='success'){
                //extend(true, vueInst.categories,  response.categories);
                vueInst.categories = response.categories;
            }
        })
    }

    public searchItems() {
        const vueInst=this;
        vueInst.loadingItems = true;
        ServiceBrowseArticles.searchArticles(vueInst.searchItemsText).then(response => {
            vueInst.loadingItems = false;
            if (response.status == 'success') {
                vueInst.items = response.products;
            }
        });
    }

    @Watch('pid')
    onPidChanged(newPid: string, oldPid: string) {
        const vueInst=this;
        vueInst.$q.loading.show();
        vueInst.hierarchicalChain=[];
        if(newPid=='0'){
            vueInst.getCategories();
        }else{
            vueInst.getCategory();
        }
    }

    created(){
        this.getCategories();
        this.columns = [
            { name: 'name', label: '', field: 'name', align: 'left'},
        ];
    }

    public activated(){
        const vueInst=this;
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar(this.$t('message.categories'));
        }
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-left';
            this.dialogTransitionHide ='slide-right';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        vueInst.searchItemsText = '';
        vueInst.appid = 0;
        ServiceLogs.updateTimeSpentPerPage('browse_categories', '0', vueInst.appid).then(responce => {
            if(responce.status == 'success'){
                vueInst.appid = responce.appid;
            }
        })
        window.addEventListener('beforeunload', function (event) {
            
            ServiceLogs.updateTimeSpentPerPage('browse_categories', '0', vueInst.appid);
        });
    }

    deactivated(): void {
        const vueInst=this;
        ServiceLogs.updateTimeSpentPerPage('browse_categories', '0', vueInst.appid);
    }
}
