
import { Vue, Options } from 'vue-class-component';
import { Chart, registerables } from 'chart.js';
import { Bar, Doughnut, Line } from 'vue-chartjs';
import ServiceLogs from '@/services/ServiceLogs';
import { TCategoryReport3 } from '@/types/TCategoryReport3';
import { Prop, Watch } from 'vue-property-decorator';
import { TUserAgent } from '@/types/TUserAgent';
import { TUserAgent2 } from '@/types/TUserAgent2';
import { TUserAgent3 } from '@/types/TUserAgent3';
import { TUserAgent4 } from '@/types/TUserAgent4';
import { TUserAgent5 } from '@/types/TUserAgent5';
import { TProductCerere } from '@/types/TProductCerere';
import { TOfferReport } from '@/types/TOfferReport';
import { TCategoryReport2 } from '@/types/TCategoryReport2';
import { TProductBasketReport } from '@/types/TProductBasketReport';
import eventbus from "@/store/eventbus";
import {getModule} from "vuex-module-decorators";
import axios, { AxiosResponse, AxiosResponseHeaders } from 'axios';
import { CONFIG_ENV } from '@/config';
import { ref } from 'vue';
import { TUserMonth } from '@/types/TUserMonth';
import { TUser } from '@/types/TUser';
import { TUserMonthTimeSpent } from '@/types/TUserMonthTimeSpent';
import { TUserTimeSpent } from '@/types/TUserTimeSpent';
import { TUserMonthOffers } from '@/types/TUserMonthOffers';
import { TUserMonthCategories } from '@/types/TUserMonthCategories';
import { TCategoryReportQuantity } from '@/types/TCategoryReportQuantity';
import { TSubcategoryReport } from '@/types/TSubcategoryReport';
import { TUserMonthOffersCount } from '@/types/TUserMonthOffersCount';
import { TCerereHeader } from '@/types/TCerereHeader';
import { TUserAgentOffersTimeBetween } from '@/types/TUserAgentOffersTimeBetween';

type UserActiveInactive = TUser & {status:string};
type TUserMonthStatus = {
    month: string;
    usersActive:UserActiveInactive[];
    usersInactive:UserActiveInactive[];
};

type TCerereHeaderQuantity = TCerereHeader & {quantity: number};
type TCerereHeaderTime = TCerereHeader & {time: number};

Chart.register(...registerables);

@Options({
    name: "AdminReportsDetails",
    components: { Bar, Line, Doughnut }
})

export default class AdminReportsDetails extends Vue {
    @Prop({ default: 'usersLogs' }) public readonly typeOfAccess!: string;
    @Prop({ default: 'inactiveUsers' }) public readonly chartType!: string;
    public chartData = {};
    public chartData2 = {};
    public loadedData = false;
    public options = {
        responsive: true,
        maintainAspectRatio: false,
    };

    

    public optionsPie = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            hover: {
				mode: null  // Disable hover interactions
			},
			tooltips: {
				enabled: false  // Disable tooltips
			},
        }
    };

    public chartDataCountOffers = {};
    public chartDataCountOffersNoAnswers = {};
    public chartDataCountOffersModified = {};
    public chartDataOffersTimeBetween = {};
    public chartDataOffersQuantity = {};
    public chartDataOffersQuantityCanceled = {};
    public chartDataCategoriesQuantity = {};
    public chartDataPieCategoriesQuantity = {};
    public chartDataPieBasket = {};
    public chartDataCountOffersTimeSpent = {};
    public chartDataCountOffersTimeReply = {};
    public chartDataUsersTimeSpent = {};
    public chartDataNoClicks = {};
    public chartDataBasket = {};
    public categoriesNoCLicks: TCategoryReport3[] = [];
    public categoriesCanceled: TCategoryReport2[] = [];
    public categoriesFiltered: TCategoryReport2[] = [];
    public categoriesNoCLicksFiltered: TCategoryReport3[] = [];

    public visibleColumnsUsersEvolution: string[] = [ 'userid', 'firstName', 'lastName', 'denumire', 'agent_name', 'status'];
    public columnsUsersEvolution: any = [];
    public visibleColumnsUsersTimeSpent: string[] = ['userid', 'firstName', 'lastName', 'denumire', 'timeSpent', 'avgTime', 'count_login', 'agent'];
    public columnsUsersTimeSpent: any = [];
    public columnsOffersBasket: any = [];
    
    public visibleColumnsOffersCount: string[] = ['id_offer', 'first_name', 'last_name', 'denumire', 'status', 'agent'];
    public visibleColumnsOffersQuantity: string[] = ['id_offer', 'first_name', 'last_name', 'denumire', 'agent', 'status', 'quantity'];
    public visibleColumnsOffersTime: string[] = ['id_offer', 'first_name', 'last_name', 'denumire', 'status', 'time'];
    
    public visibleColumnsBasketQuantity: string[] = ['userid', 'first_name', 'last_name', 'denumire', 'category', 'qum2'];
    public visibleColumnsCountClick: string[] = ['userid', 'firstName', 'lastName', 'Company', 'countClick'];
    public columnsMonths: any = [];
    public visibleColumnsMonths: any = [];
    public columnsBasket: any = []
    public visibleColumnsBasket: string[] = ['agent', 'no_clients', 'tons'];
    public columnsOffers: any = [];
    public visibleColumnsOffers: string[] = ['id_cerere', 'firstName', 'lastName', 'Company', 'status', 'agent'];
    public columnsSubcategory: any = [];
    public visibleColumnsSubcategoryQuantity: string[] = [];
    public visibleColumnsSubcategoryCount: string[] = [];

    public agents: TUserAgent[] = [];
    public agentsFiltered: TUserAgent[] = [];
    public agentsOffers: TUserAgent2[] = [];
    public agentsBasket: TUserAgent3[] = [];
    public agentsTimeSpent: TUserAgentOffersTimeBetween[] = [];
    public agentsOffersFiltered: TUserAgent2[] = [];
    public agentsBasketFiltered: TUserAgent3[] = [];
    public agentsTimeSpentFiltered: TUserAgentOffersTimeBetween[] = [];
    public myPagination:any={rowsPerPage:10}
    public myPagination2:any={rowsPerPage:20}
    public searchAgent='';
    public EventBusStore = getModule(eventbus);
    public downloadingXLS=false;
    public startDate='';
    public endDate='';
    public myLocale=CONFIG_ENV.myLocale;

    
    public months: any = [];
    public chartDataPie= {};
    public chartDataPieOffers = {};
    public usersActiveInactive: UserActiveInactive[] = [];
    public filteredUsersActiveInactive: UserActiveInactive[] = [];
    public clickedDetails = false;
    public monthData: TUserMonthStatus[] = [];
    public monthDataTimeSpent: TUserMonthTimeSpent[] = [];
    public monthDataOffers: TUserMonthOffers[] = [];
    public monthDataCategories: TUserMonthCategories[] = [];
    public monthDataOffersCount: TUserMonthOffersCount[] = [];
    public clickedAgentBasket = false;
    public selectedAgentBasket: TUserAgent3 = {
        userid: '',
        firstName: '',
        lastName: '',
        agentId: '',
        users: []
    };
    public clickedDetailsTimeSpent = false;
    public usersTimeSpent: TUserTimeSpent[] = [];
    public filteredUsersTimeSpent: TUserTimeSpent[] = [];
    public clickedDetailsOffers = false;
    public offers: TCerereHeader[] = [];
    public filteredOffers : TCerereHeader[] = [];
    public clickedDetailsCategories = false;
    public subcategories: TSubcategoryReport[] = [];
    public filteredSubcategories : TSubcategoryReport[] = [];
    public total = 0;
    public offersQuantity: TOfferReport[] = [];
    public filteredOffersQuantity : TCerereHeaderQuantity[] = [];
    public clickedDetailsOffersTimeBetween = false;
    public offersTimeBetween : TOfferReport[] = [];
    public filteredOffersTimeBetween : TCerereHeaderTime[] = [];
    public clickedDetailsOffersQuantity = false;
    public totalPieTitle = '';

    public goBack(){
        this.$router.back();
    }
	public colorsArray = [
		'#E97132', '#156082', '#196B24', '#0F9ED5',
		'#4FBD4F', '#5364B9', '#CF2CE0', '#DC3030',
		'#42505f', '#ffbf00', '#fa9af7', '#ecdcf0',
		'#ff1493', '#e1ca09', '#f90001', '#003366',
		'#00699c', '#b4bcc2', '#ca14af', '#4666ff'
	];

	public getMinutesDifference(dateString1: string, dateString2: string): number {
		// Convert string dates to Date objects
		const date1 = new Date(dateString1);
		const date2 = new Date(dateString2);
		// Calculate the difference in milliseconds
		const differenceInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
	
		// Convert milliseconds to minutes
		const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

		return differenceInMinutes;
	}

	public transformDate(date: number): string {
		let date2 = '';
		if (date < 60) { // minutes
			date2 = date.toFixed(2) + ' minutes';
		} else if(date < 1440) { // hours
			date2 = (date / 60).toFixed(2) + ' hours';
		} else { // days
			date2 = (date / 1440).toFixed(2) + ' days';
		}	
		return date2;
	} 

    public filterAgents() {
        const vueInst = this;
        vueInst.filteredUsersActiveInactive = vueInst.usersActiveInactive.filter(user => user.agent_name.toLowerCase().includes(vueInst.searchAgent.toLowerCase()));
    }

    public filterAgentsTimeSpent() {
        const vueInst = this;
        vueInst.filteredUsersTimeSpent = vueInst.usersTimeSpent.filter(user => user.agent.toLowerCase().includes(vueInst.searchAgent.toLowerCase()));
    }

    public filterAgentsOffers() {
        const vueInst = this;
        vueInst.agentsOffersFiltered = vueInst.agentsOffers.filter(agent => agent.firstName.toLowerCase().includes(vueInst.searchAgent.toLowerCase()) || agent.lastName.toLowerCase().includes(vueInst.searchAgent.toLowerCase()));
    }

    public filterCategories() {
        const vueInst = this;
        vueInst.filteredSubcategories = vueInst.subcategories.filter(category => this.$i18n.locale == 'de' ? category.name_de.toLowerCase().includes(vueInst.searchAgent.toLowerCase()) : 
                                                                            this.$i18n.locale == 'ro' ? category.name_ro.toLowerCase().includes(vueInst.searchAgent.toLowerCase()) : 
                                                                            this.$i18n.locale == 'en' ? category.name_en.toLowerCase().includes(vueInst.searchAgent.toLowerCase()) : 
                                                                            category.name_hu.toLowerCase().includes(vueInst.searchAgent.toLowerCase())  )
    }

    public filterCategoriesNoClicks() {
        const vueInst = this;
        vueInst.categoriesNoCLicksFiltered = vueInst.categoriesNoCLicks.filter(category =>  this.$i18n.locale == 'de' ? category.name_de.toLowerCase().includes(vueInst.searchAgent.toLowerCase()) : 
                                                                            this.$i18n.locale == 'ro' ? category.name_ro.toLowerCase().includes(vueInst.searchAgent.toLowerCase()) : 
                                                                            this.$i18n.locale == 'en' ? category.name_en.toLowerCase().includes(vueInst.searchAgent.toLowerCase()) : 
                                                                            category.name_hu.toLowerCase().includes(vueInst.searchAgent.toLowerCase())  )
    }

    public filterOffersTimeBetween() {
        const vueInst = this;
        vueInst.filteredOffersTimeBetween = vueInst.offersTimeBetween.filter(offer => offer.offer.agent.toLowerCase().includes(vueInst.searchAgent.toLowerCase())).map(offer => {
            return {
                ...offer.offer, time: this.getTimeBetweenSendAndReceiveOffer(offer)
            };
        });
    }

    public filterOffersQuantity() {
        const vueInst = this;
        vueInst.filteredOffersQuantity = vueInst.offersQuantity.filter(offer => offer.offer.agent.toLowerCase().includes(vueInst.searchAgent.toLowerCase())).map(offer => {
            return {
                ...offer.offer, quantity: this.getQuantityOffer(offer.products), time: 0
            };
        });
    }

    public filterOffersCount(){
        const vueInst = this;
        vueInst.filteredOffers = vueInst.offers.filter(offer => offer.agent.toLowerCase().includes(vueInst.searchAgent.toLowerCase()));
    }

    public filterAgentsBasket(){
        const vueInst = this;
        vueInst.agentsBasketFiltered = vueInst.agentsBasket.filter(agent => agent.firstName.toLowerCase().includes(vueInst.searchAgent.toLowerCase()) || agent.lastName.toLowerCase().includes(vueInst.searchAgent.toLowerCase()));
    }

    public statusColor(status: string) {
        switch (status) {
            case 'p': return '#E4D900';
            case 'c':  return '#FF0000';
            case 'o':  return '#FFA500';
            case 'k':  return '#008000';
            case 'm':  return '#000000';
            case 'r':  return '#990000';
        }
    }

    public statusHumanReadable(status: string) {
        switch (status) {
            case 'p': return this.$t('message.request_sent');
            case 'c': return this.$t('message.cancelled');
            case 'o': return this.$t('message.offer_sent');
            case 'k': return this.$t('message.order_sent');
            case 'm': return this.$t('message.order_modified');
            case 'r': return this.$t('message.offer_rejected');
        }
    }

    public getQuantityOfferCategory(products: TProductCerere[], pid_category: string): number{
        const quantity = products.reduce((offerTotal, product) => {
            let sum = offerTotal;
            if(product.pid_category == pid_category){
                sum = sum + Math.round(product.qum2);
            }
            return sum;
        }, 0);
        return Math.round(quantity);
    }

    public getQuantityOffer(products: TProductCerere[]): number{
        const quantity = products.reduce((offerTotal, product) => {
            return offerTotal + Math.round(product.qum2);
        }, 0);
        return quantity;
    }


	public getTotalQuantityOffers(offers: TOfferReport[]): number{
        const quantity = offers.reduce((total, offer) => {
            return total + Math.round(this.getQuantityOffer(offer.products));
        }, 0);
        return Math.round(quantity/1000);
    }

    public getTotalQuantityUsers(users: TProductBasketReport[]): number {
        const quantity = users.reduce((total, user) => {
            return total + Math.round(Number(user.qum2));
        }, 0) / 1000;
        return Math.round(quantity);
    }

    public transformQuantity(quantity: number){
        return Math.round(quantity);
    }

    public getTimeBetweenSendAndReceiveOffer(offer: TOfferReport): number {
        // console.log(offer.offers_time_track);
        let time = 0;
        if(offer.offers_time_track && offer.offers_time_track.length > 0) {
            let creation_date = '';
            let modify_date = '';
            let send_offer = '';
            let send_modify_offer = '';
            let accept_date = '';
            let cancel_date = '';
            offer.offers_time_track.forEach(track => {
                switch (track.date_type) {
                    case 1:
                        creation_date = track.track_date;
                        break;
                    case 2:
                        modify_date = track.track_date;
                        break;
                    case 3:
                        cancel_date = track.track_date;
                        break;
                    case 4:
                        accept_date = track.track_date;
                        break;
                    case 5:
                        send_offer = track.track_date;
                        break;
                    case 6:
                        send_modify_offer = track.track_date;
                        break;
                }
            });
            if(creation_date != '' && send_offer != '') {
                time = this.getMinutesDifference(creation_date, send_offer);
            }
            if(modify_date != '' && send_modify_offer != '') {
                time = this.getMinutesDifference(modify_date, send_modify_offer);
            }
        }
        return time;
    }

    public getAvgTimeBetweenSendAndReceiveOffer(offers: TOfferReport[]) : number {
		let avg = 0;
		let sum = 0;
		let count = 0;

		offers.forEach(offer => {
			const time = this.getTimeBetweenSendAndReceiveOffer(offer);
			if(time > 0) {
				sum += time;
				count++;
			}
		})

		if (count > 0) {
			avg = sum / count;
		}
		return Math.round(avg);
	}

	public getRandomColorFromArray() {
		// Generate a random index to select a color from the array
		const randomIndex = Math.floor(Math.random() * this.colorsArray.length);
	
		// Return the randomly selected color
		return this.colorsArray[randomIndex];
	}

    public getTimeReplyOffer(offer: TOfferReport): string {
        let time = '';
        if(offer.offers_time_track && offer.offers_time_track.length > 0) {
            let creation_date = '';
            let modify_date = '';
            let send_offer = '';
            let send_modify_offer = '';
            let accept_date = '';
            let cancel_date = '';
            offer.offers_time_track.forEach(track => {
                switch (track.date_type) {
                    case 1:
                        creation_date = track.track_date;
                        break;
                    case 2:
                        modify_date = track.track_date;
                        break;
                    case 3:
                        cancel_date = track.track_date;
                        break;
                    case 4:
                        accept_date = track.track_date;
                        break;
                    case 5:
                        send_offer = track.track_date;
                        break;
                    case 6:
                        send_modify_offer = track.track_date;
                        break;
                }
            });
            if(creation_date != '') {
                if(accept_date != '') {
                    time = this.getMinutesDifference(creation_date, accept_date).toFixed(2);
                } else if(cancel_date != '') {
                    time = this.getMinutesDifference(creation_date, cancel_date).toFixed(2);
                }
            } 
        }
        return time;
    }

    async downloadXLS(report: string, sheetName: string){
        const vueInst = this;
        vueInst.downloadingXLS=true;
        const params = new URLSearchParams();
        params.set('report', report);
        params.set('sheetName', sheetName);
        params.set('userid', this.$t('message.user_id'));
        params.set('first_name', this.$t('message.first_name'));
        params.set('last_name', this.$t('message.last_name'));
        params.set('company', this.$t('message.company'));
        params.set('time_spent', this.$t('message.time_spent'));
        params.set('avg_time_per_day', this.$t('message.avg_time_per_day'));
        params.set('no_logins', this.$t('message.no_logins'));
        params.set('last_login', this.$t('message.last_login'));
        params.set('request_id', this.$t('message.request_id'));
        params.set('status', this.$t('message.status'));
        params.set('category', this.$t('message.category'));
        params.set('total_quantity', this.$t('message.total_quantity'));
        params.set('time', this.$t('message.time'));
        params.set('count_clicks', this.$t('message.count_clicks'));
        params.set('agent', this.$t('message.agent2'));
        params.set('no_users', this.$t('message.no_users'));
        params.set('lang', this.$i18n.locale);
        params.set('no_offers', this.$t('message.count_offers'));
        params.set('request_sent', this.$t('message.request_sent'));
        params.set('cancelled', this.$t('message.cancelled'));
        params.set('offer_sent', this.$t('message.offer_sent'));
        params.set('offer_rejected', this.$t('message.offer_rejected'));
        params.set('order_sent', this.$t('message.order_sent'));
        params.set('order_modified', this.$t('message.order_modified'));
        params.set('time_minutes', this.$t('message.time_minutes'));
        params.set('startDate', this.startDate);
        params.set('endDate', this.endDate);
        params.set('luna', this.$t('message.month'));
        params.set('status_active', this.$t('message.status_active'));
        params.set('status_inactive', this.$t('message.status_inactive'));
        params.set('locale', this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
        params.set('tons', this.$t('message.tons'));
        params.set('no_logins_total', this.$t('message.no_logins_total'));
        params.set('time_spent_on_portal', this.$t('message.time_spent_on_portal'));
        params.set('avg_time_on_portal', this.$t('message.avg_time_on_portal'));
        params.set('offers_time_reply', this.$t('message.offers_time_reply'));
        params.set('subgroup', this.$t('message.subgroup'));
        params.set('group', this.$t('message.group'));
        await axios.post(`${CONFIG_ENV.URL_LOGS.exportExcell}/${report}`, params,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/vnd.ms-excel'
                },
                responseType: 'blob'
            }).then((response: AxiosResponse) => {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const objectUrl = window.URL.createObjectURL(blob)
            //window.open(objectUrl)
            const link = document.createElement('a');
            link.href = objectUrl;
            // const fileName = (((response.headers as AxiosResponseHeaders)!['content-disposition'] as string)?.split(';')?.find(n => n.includes('filename=')) as string)?.replace('filename=', '')?.replaceAll('"', '')?.trim();
            link.setAttribute('download', sheetName + '.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            vueInst.downloadingXLS=false;
        }).catch((error: any) => { alert(error) })
    }

    @Watch('EventBusStore.event.eventId')
    onEventIdChanged() {
        const vueInst=this;
        if(vueInst.EventBusStore.event.name=='changeLanguage'){
            vueInst.columnsUsersEvolution = [
                { name: 'userid', label: this.$t('message.user_id'), field: 'userid', align: 'left', sortable: true},
                { name: 'firstName', label: this.$t('message.first_name'), field: 'firstName', align: 'left', sortable: true  },
                { name: 'lastName', label: this.$t('message.last_name'), field: 'lastName', align: 'left', sortable: true },
                { name: 'companyName', label: this.$t('message.company'), field: 'companyName', align: 'left', sortable: true },
                { name: 'agent_name', label: this.$t('message.agent2'), field: 'agent_name', align: 'left', sortable: true },
                { name: 'status', label: this.$t('message.status'), field: 'status', align: 'left', sortable: true }
            ];
    
            vueInst.columnsUsersTimeSpent = [
                { name: 'userid', label: this.$t('message.user_id'), field: 'userid', align: 'left', sortable: true},
                { name: 'first_name', label: this.$t('message.first_name'), field: 'first_name', align: 'left', sortable: true  },
                { name: 'last_name', label: this.$t('message.last_name'), field: 'last_name', align: 'left', sortable: true },
                { name: 'denumire', label: this.$t('message.company'), field: 'denumire', align: 'left', sortable: true },
                { name: 'agent', label: this.$t('message.agent2'), field: 'agent', align: 'left', sortable: true },
                { name: 'timeSpent', label: this.$t('message.time_spent'), field: 'timeSpent', align: 'left', sortable: true },
                { name: 'avgTime', label: this.$t('message.avg_time_per_day'), field: 'avgTime', align: 'left', sortable: true },
                { name: 'count_login', label: this.$t('message.no_logins'), field: 'count_login', align: 'left', sortable: true }
            ];
    
            vueInst.columnsOffersBasket = [
                { name: 'userid', label: this.$t('message.user_id'), field: 'userid', align: 'left', sortable: true},
                { name: 'id_cerere', label: this.$t('message.request_id'), field: 'id_cerere', align: 'left', sortable: true},
                { name: 'first_name', label: this.$t('message.first_name'), field: 'first_name', align: 'left', sortable: true  },
                { name: 'last_name', label: this.$t('message.last_name'), field: 'last_name', align: 'left', sortable: true },
                { name: 'denumire', label: this.$t('message.company'), field: 'denumire', align: 'left', sortable: true },
                { name: 'status', label: this.$t('message.status'), field: 'status', align: 'left', sortable: true },
                { name: this.$i18n.locale == 'de' ? 'category_name_de' : this.$i18n.locale == 'ro' ? 'category_name_ro' : this.$i18n.locale == 'en' ? 'category_name_en' : 'category_name_hu', label: this.$t('message.category'), 
                    field: this.$i18n.locale == 'de' ? 'category_name_de' : this.$i18n.locale == 'ro' ? 'category_name_ro' : this.$i18n.locale == 'en' ? 'category_name_en' : 'category_name_hu', align: 'left', sortable: true },
                { name: 'qum2', label: this.$t('message.tons'), field: 'qum2', align: 'left', sortable: true, sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10)  },
            ];

            vueInst.columnsOffers = [
                { name: 'id_offer', label: this.$t('message.request_id'), field: 'id_offer', align: 'left', sortable: true},
                { name: 'first_name', label: this.$t('message.first_name'), field: 'first_name', align: 'left', sortable: true  },
                { name: 'last_name', label: this.$t('message.last_name'), field: 'last_name', align: 'left', sortable: true },
                { name: 'denumire', label: this.$t('message.company'), field: 'denumire', align: 'left', sortable: true },
                { name: 'status', label: this.$t('message.status'), field: 'status', align: 'left', sortable: true },
                { name: 'agent', label: this.$t('message.agent2'), field: 'agent', align: 'left', sortable: true },
                { name: 'time', label: this.$t('message.offers_time_reply'), field: 'time', align: 'left', sortable: true },
                { name: 'quantity', label: this.$t('message.tons'), field: 'quantity', align: 'left', sortable: true },
            ];

            vueInst.columnsBasket = [
                { name: 'agent', label: this.$t('message.agent2'), field: 'agent', align: 'left', sortable: true},
                { name: 'no_clients', label: this.$t('message.no_clients'), field: 'no_clients', align: 'left', sortable: true  },
                { name: 'tons', label: this.$t('message.tons'), field: 'tons', align: 'left', sortable: true }
            ];

            vueInst.columnsSubcategory = [
                { name: this.$i18n.locale == 'de' ? 'name_de' : this.$i18n.locale == 'ro' ? 'name_ro' : this.$i18n.locale == 'en' ? 'name_en' : 'name_hu', label: this.$t('message.subgroup'), 
                    field: this.$i18n.locale == 'de' ? 'name_de' : this.$i18n.locale == 'ro' ? 'name_ro' : this.$i18n.locale == 'en' ? 'name_en' : 'name_hu', align: 'left', sortable: true},
                { name: 'quantity', label: this.$t('message.tons'), field: 'quantity', align: 'left', sortable: true  },
                { name: 'countClicks', label: this.$t('message.count_clicks'), field: 'countClicks', align: 'left', sortable: true }
            ];
        }
    }


    public formatDate (date: Date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns 0-based index
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    public formatDateMonth(inputDate: string, locale: string) {
        const [day, month, year] = inputDate.split('.');
    
        // Create a Date object using the parsed components (subtract 1 from month since Date expects zero-based month)
        const dateObject = new Date(`${year}-${month}-${day}`);
    
        // Get the full month name based on the specified locale
        const options : Intl.DateTimeFormatOptions = { month: 'long', year: 'numeric' };
        const formatter = new Intl.DateTimeFormat(locale, options);
        const formattedDate = formatter.format(dateObject);
    
        return formattedDate;
    }

    public reverseArray(arr: any[]) {
        // Create a new array to store reversed elements
        const reversedArray = [];
    
        // Iterate over the original array from end to start
        for (let i = arr.length - 1; i >= 0; i--) {
            // Push each element from the original array into the new array (in reversed order)
            reversedArray.push(arr[i]);
        }
    
        // Return the reversed array
        return reversedArray;
    }

    public selectedMonth = '';
    public selectedRowIndex = -1;
    public selectedColumnName = '';

    public isCellSelected(rowIndex: number, columnName: string) {
        // console.log(rowIndex, columnName, this.selectedRowIndex, this.selectedColumnName);
        return rowIndex === this.selectedRowIndex && columnName === this.selectedColumnName;
    }

    public selectMonth(month: any, rowIndex: number){
        
        if(month['name'] === 'no_clients') return;
        const vueInst = this;
        vueInst.selectedMonth = month['name'];
        vueInst.selectedRowIndex = rowIndex;
        vueInst.selectedColumnName =  month['name'];
        const m = vueInst.monthData.filter(m => m.month === month['name'])[0];
        vueInst.clickedDetails = true;
        vueInst.searchAgent = '';
        switch (rowIndex) {
            case 0: // active clients
                vueInst.usersActiveInactive = [];
                vueInst.filteredUsersActiveInactive = [];
                vueInst.usersActiveInactive = m.usersActive;
                vueInst.selectedMonth = vueInst.selectedMonth + ' ' + this.$t('message.clients_active'); 
                break;
            case 1: // inactive clients
                vueInst.usersActiveInactive = [];
                vueInst.filteredUsersActiveInactive = [];
                vueInst.usersActiveInactive = m.usersInactive;
                vueInst.selectedMonth = vueInst.selectedMonth + ' ' + this.$t('message.clients_inactive'); 
                break;
            case 2: // total clients
                vueInst.usersActiveInactive = [];
                vueInst.filteredUsersActiveInactive = [];
                vueInst.usersActiveInactive = [...m.usersActive, ...m.usersInactive];
                vueInst.selectedMonth = vueInst.selectedMonth + ' ' + this.$t('message.total'); 
                break;
        }
        vueInst.filteredUsersActiveInactive = vueInst.usersActiveInactive;
    }

    public selectMonthTimeSpent(month: any, rowIndex: number){
        if(month['name'] === 'no_clients') return;
        const vueInst = this;
        vueInst.selectedMonth = month['name'];
        vueInst.selectedRowIndex = rowIndex;
        vueInst.selectedColumnName =  month['name'];
        const m = vueInst.monthDataTimeSpent.filter(m => this.formatDateMonth(m.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale) === month['name'])[0];
     

        vueInst.clickedDetailsTimeSpent = true;
        vueInst.searchAgent = '';
        switch (rowIndex) {
            case 0: // active clients
                vueInst.selectedMonth = vueInst.selectedMonth + ' ' + this.$t('message.no_logins_total'); 
                vueInst.visibleColumnsUsersTimeSpent = [ 'userid', 'first_name', 'last_name', 'denumire', 'agent', 'count_login'];
                break;
            case 1: // inactive clients
                vueInst.selectedMonth = vueInst.selectedMonth + ' ' + this.$t('message.time_spent_on_portal'); 
                vueInst.visibleColumnsUsersTimeSpent = [ 'userid', 'first_name', 'last_name', 'denumire', 'agent', 'timeSpent'];
                break;
            case 2: // total clients
                vueInst.selectedMonth = vueInst.selectedMonth + ' ' + this.$t('message.avg_time_on_portal'); 
                vueInst.visibleColumnsUsersTimeSpent = [ 'userid', 'first_name', 'last_name', 'denumire', 'agent', 'avgTime'];
                break;
        }
        vueInst.usersTimeSpent = [];
        vueInst.filteredUsersTimeSpent = [];
        vueInst.usersTimeSpent = m.users;
        vueInst.filteredUsersTimeSpent = vueInst.usersTimeSpent;
    }

    
    public selectMonthOffers(month: any, rowIndex: number){
        if(month['name'] === 'no_clients') return;
        const vueInst = this;
        vueInst.selectedMonth = month['name'];
        vueInst.selectedRowIndex = rowIndex;
        vueInst.selectedColumnName =  month['name'];
        const m = vueInst.monthDataOffersCount.filter(m => this.formatDateMonth(m.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale) === month['name'])[0];
     
        vueInst.clickedDetailsOffers = true;
        vueInst.searchAgent = '';
        switch (rowIndex) {
            case 0: // Cereri cu status comandat 
            vueInst.offers = [];
            vueInst.filteredOffers = [];
                vueInst.selectedMonth = vueInst.selectedMonth + ' ' + this.$t('message.request_ordered'); 
                vueInst.offers = m.offers.filter(offer => offer.status === 'k');
                vueInst.filteredOffers = vueInst.offers;
                break;
            case 1: // Cereri cu status respins 
            vueInst.offers = [];
            vueInst.filteredOffers = [];
                vueInst.selectedMonth = vueInst.selectedMonth + ' ' + this.$t('message.request_canceled'); 
                vueInst.offers = m.offers.filter(offer => offer.status === 'c');
                vueInst.filteredOffers = vueInst.offers;
                break;
            case 2: // Cereri deschise 
            vueInst.offers = [];
            vueInst.filteredOffers = [];
                vueInst.selectedMonth = vueInst.selectedMonth + ' ' + this.$t('message.request_opened'); 
                vueInst.offers = m.offers.filter(offer => offer.status === 'p' || offer.status === 'm' || offer.status === 'o');
                vueInst.filteredOffers = vueInst.offers;
                break;
            case 3: // Total cereri 
            vueInst.offers = [];
            vueInst.filteredOffers = [];
                vueInst.selectedMonth = vueInst.selectedMonth + ' ' + this.$t('message.request_total'); 
                vueInst.offers = m.offers;
                vueInst.filteredOffers = vueInst.offers;
                break;
        }
    }

    public selectMonthOffersQuantity(month: any, rowIndex: number){
        if(month['name'] === 'no_clients') return;
        const vueInst = this;
        vueInst.clickedDetailsOffersQuantity = true
        vueInst.selectedMonth = month['name'];
        vueInst.selectedRowIndex = rowIndex;
        vueInst.selectedColumnName =  month['name'];
        const m = vueInst.monthDataOffers.filter(m => this.formatDateMonth(m.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale) === month['name'])[0];
     
        vueInst.searchAgent = '';
        switch (rowIndex) {
            case 0: // Cereri cu status comandat 
                vueInst.offersQuantity = [];
                vueInst.selectedMonth = vueInst.selectedMonth + ' ' + this.$t('message.request_ordered'); 
                vueInst.offersQuantity = m.offers.filter(offer => offer.offer.status === 'k');
                vueInst.filterOffersQuantity();
                break;
            case 1: // Cereri cu status respins 
                vueInst.offersQuantity = [];
                vueInst.selectedMonth = vueInst.selectedMonth + ' ' + this.$t('message.request_canceled'); 
                vueInst.offersQuantity = m.offers.filter(offer => offer.offer.status === 'c');
                vueInst.filterOffersQuantity();
                break;
            case 2: // Cereri deschise 
                vueInst.offersQuantity = [];
                vueInst.selectedMonth = vueInst.selectedMonth + ' ' + this.$t('message.request_opened'); 
                vueInst.offersQuantity = m.offers.filter(offer => offer.offer.status === 'p' || offer.offer.status === 'm' || offer.offer.status === 'o');
                vueInst.filterOffersQuantity();
                break;
            case 3: // Total cereri 
                vueInst.offersQuantity = [];
                vueInst.selectedMonth = vueInst.selectedMonth + ' ' + this.$t('message.request_total'); 
                vueInst.offersQuantity = m.offers;
                vueInst.filterOffersQuantity();
                break;
        }

    }


    public selectMonthCategories(month: any, rowIndex: number){
        if(month['name'] === 'no_clients' || rowIndex == 8) return;
        const vueInst = this;
        vueInst.selectedMonth = month['name'];
        vueInst.selectedRowIndex = rowIndex;
        vueInst.selectedColumnName =  month['name'];
        const m = vueInst.monthDataCategories.filter(m => this.formatDateMonth(m.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale) === month['name'])[0];
     
        vueInst.clickedDetailsCategories = true;
        vueInst.searchAgent = '';
        
        vueInst.subcategories = [];
        vueInst.filteredSubcategories = [];
        vueInst.subcategories = m.categories[rowIndex].subcategories;
        vueInst.filteredSubcategories = vueInst.subcategories;

        vueInst.selectedMonth = vueInst.selectedMonth + ' ' + (this.$i18n.locale == 'de' ? m.categories[rowIndex].category_name_de : this.$i18n.locale == 'ro' ? m.categories[rowIndex].category_name_ro : 
                                                               this.$i18n.locale == 'en' ? m.categories[rowIndex].category_name_en : m.categories[rowIndex].category_name_hu)

    }

    public selectBasketAgent(agent: TUserAgent3, rowIndex: number){
        const vueInst = this;
        vueInst.selectedAgentBasket = agent;
        vueInst.clickedAgentBasket = true;
        vueInst.selectedRowIndex = rowIndex;
        vueInst.selectedColumnName =  '';
    }

    public selectMonthOffersTimeBetween(month: any, rowIndex: number){
        if(month['name'] === 'no_clients') return;
        const vueInst = this;
        const agent = vueInst.agentsTimeSpent[rowIndex];
        vueInst.clickedDetailsOffersTimeBetween = true;
        vueInst.selectedMonth = month['name'] + ' ' + agent.firstName + ' ' + agent.lastName;
        vueInst.selectedRowIndex = rowIndex;
        vueInst.selectedColumnName =  month['name'];
        const m = agent.months.filter(m => this.formatDateMonth(m.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale) === month['name'])[0];
        // console.log(m);
        vueInst.offersTimeBetween = m.offers;
        vueInst.filterOffersTimeBetween();
    }

    public loadData(){
        const vueInst = this;
        vueInst.clickedDetails = false;
        vueInst.clickedDetailsTimeSpent = false;
        vueInst.$q.loading.show();
        if(this.chartType == 'inactiveUsers') {
            ServiceLogs.getUsersNumberLogsPerAgent(this.startDate, this.endDate).then(response => {
                if(response.status == 'success') {
                    // console.log(response.agents);
                    const labels : string[] = [];
                    vueInst.usersActiveInactive = [];
                    const data: number[] = [];
                    const data2: number[] = [];
                    const data3: number[] = [];
                    vueInst.monthData = [];
                    vueInst.months = [];
                    
                    vueInst.columnsMonths = [
                        { name: 'no_clients', label: this.$t('message.no_clients'), field: 'no_clients', align: 'left', sortable: false, classes: 'bold-label'}
                    ];
                    vueInst.visibleColumnsMonths = ['no_clients'];
                    response.months.forEach(month => {
                        // console.log(agent);
                        labels.push(this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale));
                        data.push(month.usersInactive.length);
                        data2.push(month.usersActive.length);
                        data3.push(month.usersActive.length + month.usersInactive.length);
                        vueInst.monthData.push({month: this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale), 
                                                usersActive: month.usersActive.map(user => ({...user,status: this.$t('message.status_active')})),
                                                usersInactive : month.usersInactive.map(user => ({...user,status: this.$t('message.status_inactive')}))
                                            })
                    });

                    const labelsReversed = this.reverseArray(labels);
                    const revData = this.reverseArray(data);
                    const revData2 = this.reverseArray(data2);
                    const revData3 = this.reverseArray(data3);
                    const arrDataActive: any = [];
                    const arrDataInactive: any = [];
                    const arrDataTotal: any = [];
                    arrDataActive.push({name: 'no_clients', value: this.$t('message.no_clients_active')});
                    arrDataInactive.push({name: 'no_clients', value: this.$t('message.no_clients_inactive')});
                    arrDataTotal.push({name: 'no_clients', value: this.$t('message.no_total_clients')});

                    labelsReversed.forEach((label, index) => {
                        vueInst.columnsMonths.push( { name: label, label: label, field: label, align: 'left', sortable: false});
                        vueInst.visibleColumnsMonths.push(label);
                        arrDataActive.push({name: label, value: revData2[index]});
                        arrDataInactive.push({name: label, value: revData[index]});
                        arrDataTotal.push({name: label, value: revData3[index]});
                    });

                    vueInst.months.push(arrDataActive, arrDataInactive, arrDataTotal);
                    
                    vueInst.loadedData = true;
                    vueInst.chartData = {
                        labels : labels,
                        datasets: [
                           {
                              label: this.$t('message.inactive_users'),
                              data: data,
                              backgroundColor: '#E97132',
                              borderColor: '#E97132',
                              tension: 0.5,
                              pointRadius: 16,
                              datalabels: {
                                color: '#ffffff'
                              }
                            },
                            {
                              label: this.$t('message.active_users'),
                              data: data2,
                              backgroundColor: '#156082',
                              borderColor: '#156082',
                              tension: 0.5,
                              pointRadius: 16,
                              datalabels: {
                                color: '#ffffff'
                              }
                            },
                            {
                                label: this.$t('message.total'),
                                data: data3,
                                backgroundColor: '#196B24',
                                borderColor: '#196B24',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                  color: '#ffffff'
                                }
                              }
                          ],
                    }
                    vueInst.$q.loading.hide();
                }
            });
        } else if(this.chartType == 'timeSpent') {
            ServiceLogs.getUsersTimeSpent(this.startDate, this.endDate).then(response => {
                if(response.status == 'success') {

                    const labels : string[] = [];
                    const data: number[] = [];
                    const data2: number[] = [];
                    const data3: number[] = [];
                    vueInst.months = [];
                    vueInst.monthDataTimeSpent = response.months;
                    vueInst.columnsMonths = [
                        { name: 'no_clients', label: this.$t('message.auth_and_time_spent'), field: 'no_clients', align: 'left', sortable: false}
                    ];
                    vueInst.visibleColumnsMonths = ['no_clients'];

                    response.months.forEach(month => {
                        const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                        labels.push(label);
                        data.push(month.total_count_logins);
                        data2.push(month.total_time_spent);
                        data3.push(month.avg_time_spent);
                    });
                    vueInst.loadedData = true;

                    vueInst.chartDataUsersTimeSpent = {
                        labels : labels,
                        datasets: [
                            {
                               label: this.$t('message.no_logins_total'),
                               data: data,
                               backgroundColor: '#E97132',
                               borderColor: '#E97132',
                               tension: 0.5,
                               pointRadius: 24,
                               datalabels: {
                                 color: '#ffffff'
                               }
                             },
                             {
                               label: this.$t('message.time_spent_on_portal'),
                               data: data2,
                               backgroundColor: '#156082',
                               borderColor: '#156082',
                               tension: 0.5,
                               pointRadius: 24,
                               datalabels: {
                                 color: '#ffffff'
                               }
                             },
                             {
                                 label: this.$t('message.avg_time_on_portal'),
                                 data: data3,
                                 backgroundColor: '#196B24',
                                 borderColor: '#196B24',
                                 tension: 0.5,
                                 pointRadius: 24,
                                 datalabels: {
                                   color: '#ffffff'
                                 }
                               }
                           ],
                    }

                    const labelsReversed = this.reverseArray(labels);
                    const revData = this.reverseArray(data);
                    const revData2 = this.reverseArray(data2);
                    const revData3 = this.reverseArray(data3);
                    const arrDataActive: any = [];
                    const arrDataInactive: any = [];
                    const arrDataTotal: any = [];
                    arrDataActive.push({name: 'no_clients', value: this.$t('message.no_logins_total')});
                    arrDataInactive.push({name: 'no_clients', value: this.$t('message.time_spent_on_portal')});
                    arrDataTotal.push({name: 'no_clients', value: this.$t('message.avg_time_on_portal')});

                    labelsReversed.forEach((label, index) => {
                        vueInst.columnsMonths.push( { name: label, label: label, field: label, align: 'left', sortable: false});
                        vueInst.visibleColumnsMonths.push(label);
                        arrDataActive.push({name: label, value: revData[index]});
                        arrDataInactive.push({name: label, value: revData2[index]});
                        arrDataTotal.push({name: label, value: revData3[index]});
                    });

                    vueInst.months.push(arrDataActive, arrDataInactive, arrDataTotal);
                    vueInst.$q.loading.hide();
                }
            });
        }  else if(this.chartType == 'offersQuantityCategories') {
            ServiceLogs.getCategoriesDetailsReportQuantity(vueInst.startDate, vueInst.endDate).then(response => {
                if(response.status == 'success'){
                    const labels : string[] = [];  
                    vueInst.months = [];
                    vueInst.monthDataCategories = response.months;
                    vueInst.columnsMonths = [
                        { name: 'no_clients', label: this.$t('message.group'), field: 'no_clients', align: 'left', sortable: false}
                    ];
                    vueInst.visibleColumnsMonths = ['no_clients'];
                    const data1: number[] = [];
                    const data2: number[] = [];
                    const data3: number[] = [];
                    const data4: number[] = [];
                    const data5: number[] = [];
                    const data6: number[] = [];
                    const data7: number[] = [];
                    const data8: number[] = [];
                    const data9: number[] = [];
                    let labelsCategories: string[] = [];
                    response.months.forEach(month => {
                        labelsCategories = [];
                        const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                        labels.push(label);
                        let qTotal = 0;
                        month.categories.forEach((category, index) => {
                            const quantity = category.subcategories.reduce((total, subcategory) => {
                                return total + Number(subcategory.quantity);
                            }, 0);
                            qTotal += quantity;
                            labelsCategories.push(this.$i18n.locale == 'de' ? category.category_name_de : this.$i18n.locale == 'ro' ? category.category_name_ro : this.$i18n.locale == 'en' ? category.category_name_en : category.category_name_hu)
                            switch(index) {
                                case 0: 
                                    data1.push(Math.round(quantity/1000));
                                    break;
                                case 1: 
                                    data2.push(Math.round(quantity/1000));
                                    break;
                                case 2: 
                                    data3.push(Math.round(quantity/1000));
                                    break;
                                case 3: 
                                    data4.push(Math.round(quantity/1000));
                                    break;
                                case 4: 
                                    data5.push(Math.round(quantity/1000));
                                    break;
                                case 5: 
                                    data6.push(Math.round(quantity/1000));
                                    break;
                                case 6: 
                                    data7.push(Math.round(quantity/1000));
                                    break;
                                case 7: 
                                    data8.push(Math.round(quantity/1000));
                                    break;
                            }
                        });
                        data9.push(Math.round(qTotal/1000));
                        labelsCategories.push(this.$t('message.tons_total'));
                    });

                    vueInst.loadedData = true;
                    vueInst.chartDataCategoriesQuantity = {
                        labels : labels,
                        datasets: [
                            {
                                label: labelsCategories[0],
                                data: data1,
                                backgroundColor: '#E97132',
                                borderColor: '#E97132',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[1],
                                data: data2,
                                backgroundColor: '#156082',
                                borderColor: '#156082',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[2],
                                data: data3,
                                backgroundColor: '#196B24',
                                borderColor: '#196B24',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[3],
                                data: data4,
                                backgroundColor: '#0F9ED5',
                                borderColor: '#0F9ED5',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[4],
                                data: data5,
                                backgroundColor: '#4FBD4F',
                                borderColor: '#4FBD4F',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[5],
                                data: data6,
                                backgroundColor: '#5364B9',
                                borderColor: '#5364B9',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[6],
                                data: data7,
                                backgroundColor: '#CF2CE0',
                                borderColor: '#CF2CE0',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[7],
                                data: data8,
                                backgroundColor: '#DC3030',
                                borderColor: '#DC3030',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[8],
                                data: data9,
                                backgroundColor: '#0D3A4E',
                                borderColor: '#0D3A4E',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            }
                        ],
                    }

                    const labelsReversed = this.reverseArray(labels);
                    const revData1 = this.reverseArray(data1);
                    const revData2 = this.reverseArray(data2);
                    const revData3 = this.reverseArray(data3);
                    const revData4 = this.reverseArray(data4);
                    const revData5 = this.reverseArray(data5);
                    const revData6 = this.reverseArray(data6);
                    const revData7 = this.reverseArray(data7);
                    const revData8 = this.reverseArray(data8);
                    const revData9 = this.reverseArray(data9);
                    const arrData1: any = [];
                    const arrData2: any = [];
                    const arrData3: any = [];
                    const arrData4: any = [];
                    const arrData5: any = [];
                    const arrData6: any = [];
                    const arrData7: any = [];
                    const arrData8: any = [];
                    const arrDataTotal: any = [];
                    arrDataTotal.push({name: 'no_clients', value: this.$t('message.tons_total')});
                    arrData1.push({name: 'no_clients', value: labelsCategories[0]})
                    arrData2.push({name: 'no_clients', value: labelsCategories[1]})
                    arrData3.push({name: 'no_clients', value: labelsCategories[2]})
                    arrData4.push({name: 'no_clients', value: labelsCategories[3]})
                    arrData5.push({name: 'no_clients', value: labelsCategories[4]})
                    arrData6.push({name: 'no_clients', value: labelsCategories[5]})
                    arrData7.push({name: 'no_clients', value: labelsCategories[6]})
                    arrData8.push({name: 'no_clients', value: labelsCategories[7]})

                    labelsReversed.forEach((label, index) => {
                        vueInst.columnsMonths.push( { name: label, label: label, field: label, align: 'left', sortable: false});
                        vueInst.visibleColumnsMonths.push(label);
                        arrData1.push({name: label, value: revData1[index]})
                        arrData2.push({name: label, value: revData2[index]})
                        arrData3.push({name: label, value: revData3[index]})
                        arrData4.push({name: label, value: revData4[index]})
                        arrData5.push({name: label, value: revData5[index]})
                        arrData6.push({name: label, value: revData6[index]})
                        arrData7.push({name: label, value: revData7[index]})
                        arrData8.push({name: label, value: revData8[index]})
                        arrDataTotal.push({name: label, value: revData9[index]});
                    });

                    vueInst.months.push(arrData1, arrData2, arrData3, arrData4, arrData5, arrData6, arrData7, arrData8, arrDataTotal);
                }
                vueInst.$q.loading.hide();
            });
        }
        if(vueInst.chartType === 'countOffers') {
            ServiceLogs.getOffersDetailsReportCount(vueInst.startDate, vueInst.endDate).then(response => {
                if(response.status == 'success') {
                  const labels : string[] = [];
                  const data: number[] = [];
                  const data2: number[] = [];
                  const data3: number[] = [];
                  const data4: number[] = [];
                  vueInst.months = [];
                  vueInst.monthDataOffersCount = response.months;
                  vueInst.columnsMonths = [
                      { name: 'no_clients', label: this.$t('message.requests'), field: 'no_clients', align: 'left', sortable: false}
                  ];
                  vueInst.visibleColumnsMonths = ['no_clients'];
    
                  response.months.forEach(month => {
                      const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                      labels.push(label);
                    //   labels.push('');
                      data.push(month.offers.filter(offer => offer.status === 'k').length); //Cereri cu status comandat 
                      data2.push(month.offers.filter(offer => offer.status === 'c').length); // Cereri cu status respins
                      data3.push(month.offers.filter(offer => offer.status === 'p' || offer.status === 'm' || offer.status === 'o').length); // Cereri deschise 
                      data4.push(month.offers.length);// total cereri
                  });
                  vueInst.loadedData = true;
                  vueInst.chartDataCountOffers = {
                      labels : labels,
                      datasets: [
                          {
                             label: this.$t('message.request_ordered'),
                             data: data,
                             backgroundColor: '#E97132',
                             borderColor: '#E97132',
                             tension: 0.5,
                             pointRadius: 16,
                             datalabels: {
                               color: '#ffffff'
                             }
                           },
                           {
                             label: this.$t('message.request_canceled'),
                             data: data2,
                             backgroundColor: '#156082',
                             borderColor: '#156082',
                             tension: 0.5,
                             pointRadius: 16,
                             datalabels: {
                               color: '#ffffff'
                             }
                           },
                           {
                               label: this.$t('message.request_opened'),
                               data: data3,
                               backgroundColor: '#196B14',
                               borderColor: '#196B14',
                               tension: 0.5,
                               pointRadius: 16,
                               datalabels: {
                                 color: '#ffffff'
                               }
                             },
                          {
                              label: this.$t('message.request_total'),
                              data: data4,
                              backgroundColor: '#0F9ED5',
                              borderColor: '#0F9ED5',
                              tension: 0.5,
                              pointRadius: 16,
                              datalabels: {
                                  color: '#ffffff'
                              }
                          }
                      ],
                    }
                    
                    const labelsReversed = this.reverseArray(labels);
                    const revData = this.reverseArray(data);
                    const revData2 = this.reverseArray(data2);
                    const revData3 = this.reverseArray(data3);
                    const revData4 = this.reverseArray(data4);
                    const arrDataOrdered: any = [];
                    const arrDataCanceled: any = [];
                    const arrDataOpened: any = [];
                    const arrDataTotal: any = [];
                    arrDataOrdered.push({name: 'no_clients', value: this.$t('message.request_ordered')});
                    arrDataCanceled.push({name: 'no_clients', value: this.$t('message.request_canceled')});
                    arrDataOpened.push({name: 'no_clients', value: this.$t('message.request_opened')});
                    arrDataTotal.push({name: 'no_clients', value: this.$t('message.request_total')});

                    labelsReversed.forEach((label, index) => {
                        vueInst.columnsMonths.push( { name: label, label: label, field: label, align: 'left', sortable: false});
                        vueInst.visibleColumnsMonths.push(label);
                        arrDataOrdered.push({name: label, value: revData[index]});
                        arrDataCanceled.push({name: label, value: revData2[index]});
                        arrDataOpened.push({name: label, value: revData3[index]});
                        arrDataTotal.push({name: label, value: revData4[index]});
                    });

                    vueInst.months.push(arrDataOrdered, arrDataCanceled, arrDataOpened, arrDataTotal);


                  vueInst.$q.loading.hide();
                }
            });
    
        }

        if(this.chartType == 'offersClicksCategories') {
            ServiceLogs.getCategoriesDetailsReportClick(vueInst.startDate, vueInst.endDate).then(response => {
                if(response.status == 'success'){
                    const labels : string[] = [];  
                    vueInst.months = [];
                    vueInst.monthDataCategories = response.months;
                    vueInst.columnsMonths = [
                        { name: 'no_clients', label: this.$t('message.group'), field: 'no_clients', align: 'left', sortable: false}
                    ];
                    vueInst.visibleColumnsMonths = ['no_clients'];
                    const data1: number[] = [];
                    const data2: number[] = [];
                    const data3: number[] = [];
                    const data4: number[] = [];
                    const data5: number[] = [];
                    const data6: number[] = [];
                    const data7: number[] = [];
                    const data8: number[] = [];
                    const data9: number[] = [];
                    let labelsCategories: string[] = [];
                    response.months.forEach(month => {
                        labelsCategories = [];
                        const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                        labels.push(label);
                        let qTotal = 0;
                        month.categories.forEach((category, index) => {
                            const countClicks = category.subcategories.reduce((total, subcategory) => {
                                return total + Number(subcategory.countClicks);
                            }, 0);
                            qTotal += countClicks;
                            labelsCategories.push(this.$i18n.locale == 'de' ? category.category_name_de : this.$i18n.locale == 'ro' ? category.category_name_ro  : this.$i18n.locale == 'en' ? category.category_name_en : category.category_name_hu)
                            switch(index) {
                                case 0: 
                                    data1.push(countClicks);
                                    break;
                                case 1: 
                                    data2.push(countClicks);
                                    break;
                                case 2: 
                                    data3.push(countClicks);
                                    break;
                                case 3: 
                                    data4.push(countClicks);
                                    break;
                                case 4: 
                                    data5.push(countClicks);
                                    break;
                                case 5: 
                                    data6.push(countClicks);
                                    break;
                                case 6: 
                                    data7.push(countClicks);
                                    break;
                                case 7: 
                                    data8.push(countClicks);
                                    break;
                            }
                        });
                        data9.push(Math.round(qTotal));
                        labelsCategories.push(this.$t('message.count_clicks'));
                    });

                    vueInst.loadedData = true;
                    vueInst.chartDataCategoriesQuantity = {
                        labels : labels,
                        datasets: [
                            {
                                label: labelsCategories[0],
                                data: data1,
                                backgroundColor: '#E97132',
                                borderColor: '#E97132',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[1],
                                data: data2,
                                backgroundColor: '#156082',
                                borderColor: '#156082',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[2],
                                data: data3,
                                backgroundColor: '#196B24',
                                borderColor: '#196B24',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[3],
                                data: data4,
                                backgroundColor: '#0F9ED5',
                                borderColor: '#0F9ED5',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[4],
                                data: data5,
                                backgroundColor: '#4FBD4F',
                                borderColor: '#4FBD4F',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[5],
                                data: data6,
                                backgroundColor: '#5364B9',
                                borderColor: '#5364B9',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[6],
                                data: data7,
                                backgroundColor: '#CF2CE0',
                                borderColor: '#CF2CE0',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[7],
                                data: data8,
                                backgroundColor: '#DC3030',
                                borderColor: '#DC3030',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[8],
                                data: data9,
                                backgroundColor: '#0D3A4E',
                                borderColor: '#0D3A4E',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            }
                        ],
                    }

                    const labelsReversed = this.reverseArray(labels);
                    const revData1 = this.reverseArray(data1);
                    const revData2 = this.reverseArray(data2);
                    const revData3 = this.reverseArray(data3);
                    const revData4 = this.reverseArray(data4);
                    const revData5 = this.reverseArray(data5);
                    const revData6 = this.reverseArray(data6);
                    const revData7 = this.reverseArray(data7);
                    const revData8 = this.reverseArray(data8);
                    const revData9 = this.reverseArray(data9);
                    const arrData1: any = [];
                    const arrData2: any = [];
                    const arrData3: any = [];
                    const arrData4: any = [];
                    const arrData5: any = [];
                    const arrData6: any = [];
                    const arrData7: any = [];
                    const arrData8: any = [];
                    const arrDataTotal: any = [];
                    arrDataTotal.push({name: 'no_clients', value: this.$t('message.tons_total')});
                    arrData1.push({name: 'no_clients', value: labelsCategories[0]})
                    arrData2.push({name: 'no_clients', value: labelsCategories[1]})
                    arrData3.push({name: 'no_clients', value: labelsCategories[2]})
                    arrData4.push({name: 'no_clients', value: labelsCategories[3]})
                    arrData5.push({name: 'no_clients', value: labelsCategories[4]})
                    arrData6.push({name: 'no_clients', value: labelsCategories[5]})
                    arrData7.push({name: 'no_clients', value: labelsCategories[6]})
                    arrData8.push({name: 'no_clients', value: labelsCategories[7]})

                    labelsReversed.forEach((label, index) => {
                        vueInst.columnsMonths.push( { name: label, label: label, field: label, align: 'left', sortable: false});
                        vueInst.visibleColumnsMonths.push(label);
                        arrData1.push({name: label, value: revData1[index]})
                        arrData2.push({name: label, value: revData2[index]})
                        arrData3.push({name: label, value: revData3[index]})
                        arrData4.push({name: label, value: revData4[index]})
                        arrData5.push({name: label, value: revData5[index]})
                        arrData6.push({name: label, value: revData6[index]})
                        arrData7.push({name: label, value: revData7[index]})
                        arrData8.push({name: label, value: revData8[index]})
                        arrDataTotal.push({name: label, value: revData9[index]});
                    });

                    vueInst.months.push(arrData1, arrData2, arrData3, arrData4, arrData5, arrData6, arrData7, arrData8, arrDataTotal);
                }
                vueInst.$q.loading.hide();
            });
        }
        if(this.chartType == 'quantityOffers') {
            ServiceLogs.getOffersDetailsReportQuantity(this.startDate, this.endDate).then(response => {
                if(response.status == 'success') {
                    const labels : string[] = [];
                    const data: number[] = [];
                    const data2: number[] = [];
                    const data3: number[] = [];
                    const data4: number[] = [];
                    vueInst.months = [];
                    vueInst.monthDataOffers = response.months;
                    vueInst.columnsMonths = [
                        { name: 'no_clients', label: this.$t('message.requests'), field: 'no_clients', align: 'left', sortable: false}
                    ];
                    vueInst.visibleColumnsMonths = ['no_clients'];

                    response.months.forEach(month => {
                        const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                        labels.push(label);
                        const qK = vueInst.getTotalQuantityOffers(month.offers.filter(offer => offer.offer.status === 'k'));
                        const qC = vueInst.getTotalQuantityOffers(month.offers.filter(offer => offer.offer.status === 'c'));
                        const qO = vueInst.getTotalQuantityOffers(month.offers.filter(offer => offer.offer.status === 'p' || offer.offer.status === 'm' || offer.offer.status === 'o'));
                        data.push(qK); //Cereri cu status comandat 
                        data2.push(qC); // Cereri cu status respins
                        data3.push(qO); // Cereri deschise 
                        data4.push(qK + qC + qO);// total cereri
                    });
                    vueInst.loadedData = true;
                    vueInst.chartDataCountOffers = {
                        labels : labels,
                        datasets: [
                            {
                               label: this.$t('message.request_ordered'),
                               data: data,
                               backgroundColor: '#E97132',
                               borderColor: '#E97132',
                               tension: 0.5,
                               pointRadius: 16,
                               pointStyle: 'circle',
                               datalabels: {
                                 color: '#ffffff'
                               }
                             },
                             {
                               label: this.$t('message.request_canceled'),
                               data: data2,
                               backgroundColor: '#156082',
                               borderColor: '#156082',
                               tension: 0.5,
                               pointRadius: 16,
                               pointStyle: 'circle',
                               datalabels: {
                                 color: '#ffffff'
                               }
                             },
                             {
                                 label: this.$t('message.request_opened'),
                                 data: data3,
                                 backgroundColor: '#196B24',
                                 borderColor: '#196B24',
                                 tension: 0.5,
                                 pointRadius: 16,
                                 pointStyle: 'circle',
                                 datalabels: {
                                   color: '#ffffff'
                                 }
                               },
                            {
                                label: this.$t('message.request_total'),
                                data: data4,
                                backgroundColor: '#0F9ED5',
                                borderColor: '#0F9ED5',
                                tension: 0.5,
                                pointRadius: 16,
                                pointStyle: 'circle',
                                datalabels: {
                                    color: '#ffffff'
                                }
                            }
                        ],
                    }
                    
                    const labelsReversed = this.reverseArray(labels);
                    const revData = this.reverseArray(data);
                    const revData2 = this.reverseArray(data2);
                    const revData3 = this.reverseArray(data3);
                    const revData4 = this.reverseArray(data4);
                    const arrDataOrdered: any = [];
                    const arrDataCanceled: any = [];
                    const arrDataOpened: any = [];
                    const arrDataTotal: any = [];
                    arrDataOrdered.push({name: 'no_clients', value: this.$t('message.request_ordered')});
                    arrDataCanceled.push({name: 'no_clients', value: this.$t('message.request_canceled')});
                    arrDataOpened.push({name: 'no_clients', value: this.$t('message.request_opened')});
                    arrDataTotal.push({name: 'no_clients', value: this.$t('message.request_total')});

                    labelsReversed.forEach((label, index) => {
                        vueInst.columnsMonths.push( { name: label, label: label, field: label, align: 'left', sortable: false});
                        vueInst.visibleColumnsMonths.push(label);
                        arrDataOrdered.push({name: label, value: revData[index]});
                        arrDataCanceled.push({name: label, value: revData2[index]});
                        arrDataOpened.push({name: label, value: revData3[index]});
                        arrDataTotal.push({name: label, value: revData4[index]});
                    });

                    vueInst.months.push(arrDataOrdered, arrDataCanceled, arrDataOpened, arrDataTotal);


                    vueInst.$q.loading.hide();
                }
            });
        }

        if(vueInst.chartType === 'offersTime') {
            ServiceLogs.getOffersDetailsReportTimeBetween(vueInst.startDate, vueInst.endDate).then(response => {
                if(response.status == 'success') {
                    //chartDataOffersTimeBetween
                    let labels : string[] = [];
                    const datasets: any = [];
                    const dataPie: number[] = [];
                    const dataArray: any = [];
                    let dataArrayTable: any = [];
                    const colorsArray: any = [];
                    let totalTime = 0;
                    vueInst.months = [];
                    vueInst.agentsTimeSpent = response.agents;
                    vueInst.columnsMonths = [
                        { name: 'no_clients', label: this.$t('message.agent2'), field: 'no_clients', align: 'left', sortable: false}
                    ];
                    vueInst.visibleColumnsMonths = ['no_clients'];

                    response.agents.forEach((agent, index) => {
                        const data: number[] = [];
                        labels = [];
                        dataArrayTable = [];
                        agent.months.forEach(month => {
                            const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                            labels.push(label);
                            const val = vueInst.getAvgTimeBetweenSendAndReceiveOffer(month.offers);
                            data.push(val);
                            dataArrayTable.unshift({name: label, value: val});
                        });
                        dataArrayTable.unshift({name: 'no_clients', value: agent.firstName + ' ' + agent.lastName});
                        totalTime += data[data.length - 1];
                        dataPie.push(Number(data[data.length - 1]));
                        datasets.push(
                            {
                                label: agent.firstName + ' ' + agent.lastName,
                                data: data,
                                backgroundColor: vueInst.colorsArray[index],
                                borderColor: vueInst.colorsArray[index],
                                tension: 0.5,
                                pointRadius: 20,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            }
                        );
                        dataArray.push(dataArrayTable);
                    });
    
                    vueInst.loadedData = true;
                    vueInst.chartDataOffersTimeBetween = {
                        labels : labels,
                        datasets: datasets
                    }

                    // console.log(dataArray);
                    const labelsReversed = this.reverseArray(labels);

                    labelsReversed.forEach((label) => {
                        vueInst.columnsMonths.push( { name: label, label: label, field: label, align: 'left', sortable: false});
                        vueInst.visibleColumnsMonths.push(label);
                    });
                    
                    vueInst.months = dataArray;

                    vueInst.$q.loading.hide();
                }
            });
        }
    }

    activated(): void {
        const vueInst = this;
        console.log(vueInst.chartType);
        vueInst.myLocale= this.$i18n.locale == 'de' ? CONFIG_ENV.myLocaleGerman : this.$i18n.locale == 'ro' ? CONFIG_ENV.myLocale : this.$i18n.locale == 'en' ? CONFIG_ENV.myLocaleEnglish : CONFIG_ENV.myLocaleHungarian;
        
        // Get today's date
        const today = new Date();
        const january2024 = new Date('01.01.2024');
        
        // Get the date one month ago
        let oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

        if(oneYearAgo < january2024) {
            oneYearAgo = january2024;
        }
        
        // Formatted dates
        vueInst.endDate = this.formatDate(today);
        vueInst.startDate = this.formatDate(oneYearAgo);
        vueInst.visibleColumnsBasketQuantity = ['userid', 'first_name', 'last_name', 'denumire', this.$i18n.locale == 'de' ? 'category_name_de' : this.$i18n.locale == 'ro' ? 'category_name_ro' : this.$i18n.locale == 'en' ? 'category_name_en' : 'category_name_hu', 'qum2'];
        
        vueInst.visibleColumnsSubcategoryQuantity = [this.$i18n.locale == 'de' ? 'name_de' : this.$i18n.locale == 'ro' ? 'name_ro' : this.$i18n.locale == 'en' ? 'name_en' : 'name_hu', 'quantity'];
        vueInst.visibleColumnsSubcategoryCount = [this.$i18n.locale == 'de' ? 'name_de' : this.$i18n.locale == 'ro' ? 'name_ro' : this.$i18n.locale == 'en' ? 'name_en' : 'name_hu', 'countClicks'];

        
        vueInst.columnsUsersEvolution = [
            { name: 'userid', label: this.$t('message.user_id'), field: 'userid', align: 'left', sortable: true},
            { name: 'firstName', label: this.$t('message.first_name'), field: 'firstName', align: 'left', sortable: true  },
            { name: 'lastName', label: this.$t('message.last_name'), field: 'lastName', align: 'left', sortable: true },
            { name: 'companyName', label: this.$t('message.company'), field: 'companyName', align: 'left', sortable: true },
            { name: 'agent_name', label: this.$t('message.agent2'), field: 'agent_name', align: 'left', sortable: true },
            { name: 'status', label: this.$t('message.status'), field: 'status', align: 'left', sortable: true }
        ];

        vueInst.columnsUsersTimeSpent = [
            { name: 'userid', label: this.$t('message.user_id'), field: 'userid', align: 'left', sortable: true},
            { name: 'first_name', label: this.$t('message.first_name'), field: 'first_name', align: 'left', sortable: true  },
            { name: 'last_name', label: this.$t('message.last_name'), field: 'last_name', align: 'left', sortable: true },
            { name: 'denumire', label: this.$t('message.company'), field: 'denumire', align: 'left', sortable: true },
            { name: 'agent', label: this.$t('message.agent2'), field: 'agent', align: 'left', sortable: true },
            { name: 'timeSpent', label: this.$t('message.time_spent'), field: 'timeSpent', align: 'left', sortable: true },
            { name: 'avgTime', label: this.$t('message.avg_time_per_day'), field: 'avgTime', align: 'left', sortable: true },
            { name: 'count_login', label: this.$t('message.no_logins'), field: 'count_login', align: 'left', sortable: true }
        ];

        vueInst.columnsOffersBasket = [
            { name: 'userid', label: this.$t('message.user_id'), field: 'userid', align: 'left', sortable: true},
            { name: 'id_cerere', label: this.$t('message.request_id'), field: 'id_cerere', align: 'left', sortable: true},
            { name: 'first_name', label: this.$t('message.first_name'), field: 'first_name', align: 'left', sortable: true  },
            { name: 'last_name', label: this.$t('message.last_name'), field: 'last_name', align: 'left', sortable: true },
            { name: 'denumire', label: this.$t('message.company'), field: 'denumire', align: 'left', sortable: true },
            { name: 'status', label: this.$t('message.status'), field: 'status', align: 'left', sortable: true },
            { name: this.$i18n.locale == 'de' ? 'category_name_de' : this.$i18n.locale == 'ro' ? 'category_name_ro' : this.$i18n.locale == 'en' ? 'category_name_en' : 'category_name_hu', label: this.$t('message.category'), 
                field: this.$i18n.locale == 'de' ? 'category_name_de' : this.$i18n.locale == 'ro' ? 'category_name_ro' : this.$i18n.locale == 'en' ? 'category_name_en' : 'category_name_hu', align: 'left', sortable: true },
            { name: 'qum2', label: this.$t('message.tons'), field: 'qum2', align: 'left', sortable: true, sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10)  },
        ];

        vueInst.columnsOffers = [
            { name: 'id_offer', label: this.$t('message.request_id'), field: 'id_offer', align: 'left', sortable: true},
            { name: 'first_name', label: this.$t('message.first_name'), field: 'first_name', align: 'left', sortable: true  },
            { name: 'last_name', label: this.$t('message.last_name'), field: 'last_name', align: 'left', sortable: true },
            { name: 'denumire', label: this.$t('message.company'), field: 'denumire', align: 'left', sortable: true },
            { name: 'status', label: this.$t('message.status'), field: 'status', align: 'left', sortable: true },
            { name: 'agent', label: this.$t('message.agent2'), field: 'agent', align: 'left', sortable: true },
            { name: 'time', label: this.$t('message.offers_time_reply'), field: 'time', align: 'left', sortable: true },
            { name: 'quantity', label: this.$t('message.tons'), field: 'quantity', align: 'left', sortable: true },
        ];

        vueInst.columnsBasket = [
            { name: 'agent', label: this.$t('message.agent2'), field: 'agent', align: 'left', sortable: false},
            { name: 'no_clients', label: this.$t('message.no_clients'), field: 'no_clients', align: 'left', sortable: false  },
            { name: 'tons', label: this.$t('message.tons'), field: 'tons', align: 'left', sortable: false }
        ];
        vueInst.columnsSubcategory = [
            { name: this.$i18n.locale == 'de' ? 'name_de' : this.$i18n.locale == 'ro' ? 'name_ro' : this.$i18n.locale == 'en' ? 'name_en' : 'name_hu', label: this.$t('message.subgroup'), 
                field: this.$i18n.locale == 'de' ? 'name_de' : this.$i18n.locale == 'ro' ? 'name_ro' : this.$i18n.locale == 'en' ? 'name_en' : 'name_hu', align: 'left', sortable: true},
            { name: 'quantity', label: this.$t('message.tons'), field: 'quantity', align: 'left', sortable: true  },
            { name: 'countClicks', label: this.$t('message.count_clicks'), field: 'countClicks', align: 'left', sortable: true }
        ];

		vueInst.chartDataCountOffers = {};
		vueInst.chartDataCountOffersNoAnswers = {};
		vueInst.chartDataCountOffersModified = {};
		vueInst.chartDataOffersTimeBetween = {};
		vueInst.chartDataOffersQuantity = {};
		vueInst.chartDataOffersQuantityCanceled = {};
		vueInst.chartDataCategoriesQuantity = {};
		vueInst.chartDataPieBasket = {};
		vueInst.chartDataCountOffersTimeSpent = {};
		vueInst.chartDataCountOffersTimeReply = {};
		vueInst.chartDataUsersTimeSpent = {};
		vueInst.chartDataNoClicks = {};
		vueInst.chartDataBasket = {};
		vueInst.categoriesNoCLicks = [];
		vueInst.categoriesCanceled = [];
        vueInst.agents = [];
        vueInst.agentsFiltered = [];
        vueInst.agentsOffers = [];
        vueInst.agentsBasket = [];
        vueInst.agentsTimeSpent= [];
        vueInst.agentsOffersFiltered = [];
        vueInst.agentsBasketFiltered = [];
        vueInst.agentsTimeSpentFiltered = [];
        vueInst.loadedData = false;
        vueInst.searchAgent = '';
        vueInst.clickedDetails = false;
        vueInst.clickedDetailsCategories = false;
        vueInst.clickedDetailsOffers = false;
        vueInst.clickedDetailsTimeSpent = false;
        vueInst.subcategories = [];
        vueInst.filteredSubcategories = [];
        vueInst.offers = [];
        vueInst.filteredOffers = [];
        vueInst.usersTimeSpent = [];
        vueInst.filteredUsersTimeSpent = [];
        vueInst.usersActiveInactive = [];
        vueInst.filteredUsersActiveInactive = [];
        vueInst.monthData = [];
        vueInst.monthDataTimeSpent = [];
        vueInst.monthDataCategories = [];
        vueInst.monthDataOffers = [];
        vueInst.months = [];
        vueInst.total = 0;
        vueInst.selectedRowIndex = -1;
        vueInst.selectedColumnName = '';

        
        vueInst.$q.loading.show();
      
        if(this.chartType == 'inactiveUsers') {
            ServiceLogs.getUsersNumberLogsPerAgent(this.startDate, this.endDate).then(response => {
                if(response.status == 'success') {
                    // console.log(response.agents);
                    const labels : string[] = [];
                    const data: number[] = [];
                    const data2: number[] = [];
                    const data3: number[] = [];
                    vueInst.months = [];
                    vueInst.monthData = [];
                    vueInst.columnsMonths = [
                        { name: 'no_clients', label: this.$t('message.no_clients'), field: 'no_clients', align: 'left', sortable: false}
                    ];
                    vueInst.visibleColumnsMonths = ['no_clients'];
                    response.months.forEach(month => {
                        // console.log(agent);
                        const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                        labels.push(label);
                        data.push(month.usersInactive.length);
                        data2.push(month.usersActive.length);
                        data3.push(month.usersActive.length + month.usersInactive.length);
                        vueInst.monthData.push({month: this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale), 
                                                usersActive: month.usersActive.map(user => ({...user,status: this.$t('message.status_active')})),
                                                usersInactive : month.usersInactive.map(user => ({...user,status: this.$t('message.status_inactive')}))
                                            })
                    });
                
                    vueInst.filteredUsersActiveInactive = vueInst.usersActiveInactive;
                    vueInst.loadedData = true;
                    vueInst.chartData = {
                        labels : labels,
                        datasets: [
                           {
                              label: this.$t('message.no_clients_inactive'),
                              data: data,
                              backgroundColor: '#E97132',
                              borderColor: '#E97132',
                              tension: 0.5,
                              pointRadius: 16,
                              datalabels: {
                                color: '#ffffff'
                              }
                            },
                            {
                              label: this.$t('message.no_clients_active'),
                              data: data2,
                              backgroundColor: '#156082',
                              borderColor: '#156082',
                              tension: 0.5,
                              pointRadius: 16,
                              datalabels: {
                                color: '#ffffff'
                              }
                            },
                            {
                                label: this.$t('message.no_total_clients'),
                                data: data3,
                                backgroundColor: '#196B24',
                                borderColor: '#196B24',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                  color: '#ffffff'
                                }
                              }
                          ],
                    }
                    vueInst.total = data3[response.months.length - 1];
                    const percentActive = Math.round((response.months[response.months.length - 1].usersActive.length / vueInst.total) * 100);
                    const percentInactive = Math.round((response.months[response.months.length - 1].usersInactive.length / vueInst.total) * 100);
                    vueInst.totalPieTitle = this.$t('message.no_total_clients');
                    vueInst.chartDataPie = {
                        labels : [this.$t('message.no_clients_inactive') + ' %', this.$t('message.no_clients_active') + ' %'],
                        datasets: [
                            
                            {
                                label: this.$t('message.last_month'),
                                data: [percentInactive,percentActive],
                                backgroundColor: ['#E97132', '#156082'],
                                borderColor: ['#E97132', '#156082'],
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                  color: '#ffffff',
                                  formatter: (value: number) => {
                                    // Format value to display as percentage
                                    return value + ' %';
                                  }
                                }
                              }
                        ]
                    }

                    const labelsReversed = this.reverseArray(labels);
                    const revData = this.reverseArray(data);
                    const revData2 = this.reverseArray(data2);
                    const revData3 = this.reverseArray(data3);
                    const arrDataActive: any = [];
                    const arrDataInactive: any = [];
                    const arrDataTotal: any = [];
                    arrDataActive.push({name: 'no_clients', value: this.$t('message.no_clients_active')});
                    arrDataInactive.push({name: 'no_clients', value: this.$t('message.no_clients_inactive')});
                    arrDataTotal.push({name: 'no_clients', value: this.$t('message.no_total_clients')});

                    labelsReversed.forEach((label, index) => {
                        vueInst.columnsMonths.push( { name: label, label: label, field: label, align: 'left', sortable: false});
                        vueInst.visibleColumnsMonths.push(label);
                        arrDataActive.push({name: label, value: revData2[index]});
                        arrDataInactive.push({name: label, value: revData[index]});
                        arrDataTotal.push({name: label, value: revData3[index]});
                    });

                    vueInst.months.push(arrDataActive, arrDataInactive, arrDataTotal);

                    vueInst.$q.loading.hide();
                }
            });
        }

        if(this.chartType == 'basket') {
            ServiceLogs.getBasketReport().then(response =>{
                if(response.status == 'success') {
                    const labels : string[] = [];
                    const dataBasket: number[] = [];
                    let countUsers = 0;
                    const colorsArray: any = [];
                    response.agents.forEach((agent, index) => {
                        labels.push(agent.firstName + ' ' + agent.lastName);
                        dataBasket.push(agent.users.length);
                        countUsers += agent.users.length;
                        
                        const agt: TUserAgent3 = {
                            userid : agent.userid,
                            agentId: agent.agentId,
                            firstName: agent.firstName,
                            lastName: agent.lastName,
                            users: []
                        };
                        agent.users.forEach(user => {
                            const indexToUpdate = agt.users.findIndex(usr => usr.userid == user.userid && usr.pid_category == user.pid_category);
                            if (indexToUpdate !== -1) {
                                agt.users[indexToUpdate].qum2 = 1*(Number(Number(agt.users[indexToUpdate].qum2) + Number(user.qum2)));
                            } else {
                                agt.users.push(user);
                            }
                        });
                        vueInst.agentsBasket.push(agt);
                        const color = vueInst.colorsArray[index];
                        colorsArray.push(color);
                    });
                    vueInst.total = countUsers;
                    vueInst.totalPieTitle = this.$t('message.no_total_clients');

                    vueInst.chartDataPieBasket = {
                        labels : labels.map(label => label + ' %'),
                        datasets: [
                            
                            {
                                label: this.$t('message.users_with_products_in_basket'),
                                data: dataBasket,
                                backgroundColor: colorsArray,
                                borderColor: colorsArray,
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                  color: '#ffffff',
                                  formatter: (value: number) => {
                                    // Format value to display as percentage
                                    return value + ' %';
                                  }
                                }
                              }
                        ],
                    }

                    vueInst.agentsBasketFiltered = vueInst.agentsBasket;
                    vueInst.loadedData = true;
                    vueInst.chartDataBasket = {
                        labels : labels,
                        datasets: [
                            {
                            label: this.$t('message.users_with_products_in_basket'),
                            data: dataBasket,
                            backgroundColor: '#123E6B',
                            borderColor: '#ffffff00',
                            tension: 0.5,
                            pointRadius: 16,
                            datalabels: {
                              color: '#ffffff'
                            }
                            },
                        ],
                    }
                    vueInst.$q.loading.hide();
                }
            });
        }

        if(this.chartType == 'timeSpent') {
            ServiceLogs.getUsersTimeSpent(this.startDate, this.endDate).then(response => {
                if(response.status == 'success') {

                    const labels : string[] = [];
                    const data: number[] = [];
                    const data2: number[] = [];
                    const data3: number[] = [];
                    vueInst.months = [];
                    vueInst.monthDataTimeSpent = response.months;
                    vueInst.columnsMonths = [
                        { name: 'no_clients', label: this.$t('message.auth_and_time_spent'), field: 'no_clients', align: 'left', sortable: false}
                    ];
                    vueInst.visibleColumnsMonths = ['no_clients'];

                    response.months.forEach(month => {
                        const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                        labels.push(label);
                        data.push(month.total_count_logins);
                        data2.push(month.total_time_spent);
                        data3.push(month.avg_time_spent);
                    });
                    vueInst.loadedData = true;

                    vueInst.chartDataUsersTimeSpent = {
                        labels : labels,
                        datasets: [
                            {
                               label: this.$t('message.no_logins_total'),
                               data: data,
                               backgroundColor: '#E97132',
                               borderColor: '#E97132',
                               tension: 0.5,
                               pointRadius: 24,
                               datalabels: {
                                 color: '#ffffff'
                               }
                             },
                             {
                               label: this.$t('message.time_spent_on_portal'),
                               data: data2,
                               backgroundColor: '#156082',
                               borderColor: '#156082',
                               tension: 0.5,
                               pointRadius: 24,
                               datalabels: {
                                 color: '#ffffff'
                               }
                             },
                             {
                                 label: this.$t('message.avg_time_on_portal'),
                                 data: data3,
                                 backgroundColor: '#196B24',
                                 borderColor: '#196B24',
                                 tension: 0.5,
                                 pointRadius: 24,
                                 datalabels: {
                                   color: '#ffffff'
                                 }
                               }
                           ],
                    }

                    const labelsReversed = this.reverseArray(labels);
                    const revData = this.reverseArray(data);
                    const revData2 = this.reverseArray(data2);
                    const revData3 = this.reverseArray(data3);
                    const arrDataActive: any = [];
                    const arrDataInactive: any = [];
                    const arrDataTotal: any = [];
                    arrDataActive.push({name: 'no_clients', value: this.$t('message.no_logins_total')});
                    arrDataInactive.push({name: 'no_clients', value: this.$t('message.time_spent_on_portal')});
                    arrDataTotal.push({name: 'no_clients', value: this.$t('message.avg_time_on_portal')});

                    labelsReversed.forEach((label, index) => {
                        vueInst.columnsMonths.push( { name: label, label: label, field: label, align: 'left', sortable: false});
                        vueInst.visibleColumnsMonths.push(label);
                        arrDataActive.push({name: label, value: revData[index]});
                        arrDataInactive.push({name: label, value: revData2[index]});
                        arrDataTotal.push({name: label, value: revData3[index]});
                    });

                    vueInst.months.push(arrDataActive, arrDataInactive, arrDataTotal);
                    vueInst.$q.loading.hide();
                }
            });
        }
        if(this.chartType == 'offersQuantityCategories') {
            ServiceLogs.getCategoriesDetailsReportQuantity(vueInst.startDate, vueInst.endDate).then(response => {
                if(response.status == 'success'){
                    const labels : string[] = [];  
                    vueInst.months = [];
                    vueInst.monthDataCategories = response.months;
                    vueInst.columnsMonths = [
                        { name: 'no_clients', label: this.$t('message.group'), field: 'no_clients', align: 'left', sortable: false}
                    ];
                    vueInst.visibleColumnsMonths = ['no_clients'];
                    const data1: number[] = [];
                    const data2: number[] = [];
                    const data3: number[] = [];
                    const data4: number[] = [];
                    const data5: number[] = [];
                    const data6: number[] = [];
                    const data7: number[] = [];
                    const data8: number[] = [];
                    const data9: number[] = [];
                    let labelsCategories: string[] = [];
                    response.months.forEach(month => {
                        labelsCategories = [];
                        const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                        labels.push(label);
                        let qTotal = 0;
                        month.categories.forEach((category, index) => {
                            const quantity = category.subcategories.reduce((total, subcategory) => {
                                return total + Number(subcategory.quantity);
                            }, 0);
                            qTotal += quantity;
                            labelsCategories.push(this.$i18n.locale == 'de' ? category.category_name_de : this.$i18n.locale == 'ro' ? category.category_name_ro : this.$i18n.locale == 'ro' ? category.category_name_ro : this.$i18n.locale == 'en' ? category.category_name_en : category.category_name_hu)
                            switch(index) {
                                case 0: 
                                    data1.push(Math.round(quantity/1000));
                                    break;
                                case 1: 
                                    data2.push(Math.round(quantity/1000));
                                    break;
                                case 2: 
                                    data3.push(Math.round(quantity/1000));
                                    break;
                                case 3: 
                                    data4.push(Math.round(quantity/1000));
                                    break;
                                case 4: 
                                    data5.push(Math.round(quantity/1000));
                                    break;
                                case 5: 
                                    data6.push(Math.round(quantity/1000));
                                    break;
                                case 6: 
                                    data7.push(Math.round(quantity/1000));
                                    break;
                                case 7: 
                                    data8.push(Math.round(quantity/1000));
                                    break;
                            }
                        });
                        data9.push(Math.round(qTotal/1000));
                        labelsCategories.push(this.$t('message.tons_total'));
                    });

                    vueInst.loadedData = true;
                    vueInst.chartDataCategoriesQuantity = {
                        labels : labels,
                        datasets: [
                            {
                                label: labelsCategories[0],
                                data: data1,
                                backgroundColor: '#E97132',
                                borderColor: '#E97132',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[1],
                                data: data2,
                                backgroundColor: '#156082',
                                borderColor: '#156082',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[2],
                                data: data3,
                                backgroundColor: '#196B24',
                                borderColor: '#196B24',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[3],
                                data: data4,
                                backgroundColor: '#0F9ED5',
                                borderColor: '#0F9ED5',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[4],
                                data: data5,
                                backgroundColor: '#4FBD4F',
                                borderColor: '#4FBD4F',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[5],
                                data: data6,
                                backgroundColor: '#5364B9',
                                borderColor: '#5364B9',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[6],
                                data: data7,
                                backgroundColor: '#CF2CE0',
                                borderColor: '#CF2CE0',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[7],
                                data: data8,
                                backgroundColor: '#DC3030',
                                borderColor: '#DC3030',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[8],
                                data: data9,
                                backgroundColor: '#0D3A4E',
                                borderColor: '#0D3A4E',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            }
                        ],
                    }
                    vueInst.totalPieTitle = this.$t('message.tons_total');
                    vueInst.total = data9[response.months.length - 1];
                    const percent1 = Math.round((data1[data1.length-1] / vueInst.total) * 100);
                    const percent2 = Math.round((data2[data2.length-1] / vueInst.total) * 100);
                    const percent3 = Math.round((data3[data3.length-1] / vueInst.total) * 100);
                    const percent4 = Math.round((data4[data4.length-1] / vueInst.total) * 100);
                    const percent5 = Math.round((data5[data5.length-1] / vueInst.total) * 100);
                    const percent6 = Math.round((data6[data6.length-1] / vueInst.total) * 100);
                    const percent7 = Math.round((data7[data7.length-1] / vueInst.total) * 100);
                    const percent8 = Math.round((data8[data8.length-1] / vueInst.total) * 100);
                    vueInst.chartDataPieCategoriesQuantity = {
                        labels : labelsCategories.map(label => label + ' %'),
                        datasets: [
                            
                            {
                                label: this.$t('message.last_month'),
                                data: [percent1, percent2, percent3, percent4, percent5, percent6, percent7, percent8],
                                backgroundColor: ['#E97132', '#156082', '#196B24', '#0F9ED5', '#4FBD4F', '#5364B9', '#CF2CE0', '#DC3030'],
                                borderColor: ['#E97132', '#156082', '#196B24', '#0F9ED5', '#4FBD4F', '#5364B9', '#CF2CE0', '#DC3030'],
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                  color: '#ffffff',
                                  formatter: (value: number) => {
                                    // Format value to display as percentage
                                    return value + ' %';
                                  }
                                }
                              }
                        ],
                    }

                    const labelsReversed = this.reverseArray(labels);
                    const revData1 = this.reverseArray(data1);
                    const revData2 = this.reverseArray(data2);
                    const revData3 = this.reverseArray(data3);
                    const revData4 = this.reverseArray(data4);
                    const revData5 = this.reverseArray(data5);
                    const revData6 = this.reverseArray(data6);
                    const revData7 = this.reverseArray(data7);
                    const revData8 = this.reverseArray(data8);
                    const revData9 = this.reverseArray(data9);
                    const arrData1: any = [];
                    const arrData2: any = [];
                    const arrData3: any = [];
                    const arrData4: any = [];
                    const arrData5: any = [];
                    const arrData6: any = [];
                    const arrData7: any = [];
                    const arrData8: any = [];
                    const arrDataTotal: any = [];
                    arrDataTotal.push({name: 'no_clients', value: this.$t('message.tons_total')});
                    arrData1.push({name: 'no_clients', value: labelsCategories[0]})
                    arrData2.push({name: 'no_clients', value: labelsCategories[1]})
                    arrData3.push({name: 'no_clients', value: labelsCategories[2]})
                    arrData4.push({name: 'no_clients', value: labelsCategories[3]})
                    arrData5.push({name: 'no_clients', value: labelsCategories[4]})
                    arrData6.push({name: 'no_clients', value: labelsCategories[5]})
                    arrData7.push({name: 'no_clients', value: labelsCategories[6]})
                    arrData8.push({name: 'no_clients', value: labelsCategories[7]})

                    labelsReversed.forEach((label, index) => {
                        vueInst.columnsMonths.push( { name: label, label: label, field: label, align: 'left', sortable: false});
                        vueInst.visibleColumnsMonths.push(label);
                        arrData1.push({name: label, value: revData1[index]})
                        arrData2.push({name: label, value: revData2[index]})
                        arrData3.push({name: label, value: revData3[index]})
                        arrData4.push({name: label, value: revData4[index]})
                        arrData5.push({name: label, value: revData5[index]})
                        arrData6.push({name: label, value: revData6[index]})
                        arrData7.push({name: label, value: revData7[index]})
                        arrData8.push({name: label, value: revData8[index]})
                        arrDataTotal.push({name: label, value: revData9[index]});
                    });

                    vueInst.months.push(arrData1, arrData2, arrData3, arrData4, arrData5, arrData6, arrData7, arrData8, arrDataTotal);
                }
                vueInst.$q.loading.hide();
            });
        }

        if(this.chartType == 'offersClicksCategories') {
            ServiceLogs.getCategoriesDetailsReportClick(vueInst.startDate, vueInst.endDate).then(response => {
                if(response.status == 'success'){
                    const labels : string[] = [];  
                    vueInst.months = [];
                    vueInst.monthDataCategories = response.months;
                    vueInst.columnsMonths = [
                        { name: 'no_clients', label: this.$t('message.group'), field: 'no_clients', align: 'left', sortable: false}
                    ];
                    vueInst.visibleColumnsMonths = ['no_clients'];
                    const data1: number[] = [];
                    const data2: number[] = [];
                    const data3: number[] = [];
                    const data4: number[] = [];
                    const data5: number[] = [];
                    const data6: number[] = [];
                    const data7: number[] = [];
                    const data8: number[] = [];
                    const data9: number[] = [];
                    let labelsCategories: string[] = [];
                    response.months.forEach(month => {
                        labelsCategories = [];
                        const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                        labels.push(label);
                        let qTotal = 0;
                        month.categories.forEach((category, index) => {
                            const countClicks = category.subcategories.reduce((total, subcategory) => {
                                return total + Number(subcategory.countClicks);
                            }, 0);
                            qTotal += countClicks;
                            labelsCategories.push(this.$i18n.locale == 'de' ? category.category_name_de : this.$i18n.locale == 'ro' ? category.category_name_ro : this.$i18n.locale == 'en' ? category.category_name_en : category.category_name_hu)
                            switch(index) {
                                case 0: 
                                    data1.push(countClicks);
                                    break;
                                case 1: 
                                    data2.push(countClicks);
                                    break;
                                case 2: 
                                    data3.push(countClicks);
                                    break;
                                case 3: 
                                    data4.push(countClicks);
                                    break;
                                case 4: 
                                    data5.push(countClicks);
                                    break;
                                case 5: 
                                    data6.push(countClicks);
                                    break;
                                case 6: 
                                    data7.push(countClicks);
                                    break;
                                case 7: 
                                    data8.push(countClicks);
                                    break;
                            }
                        });
                        data9.push(qTotal);
                        labelsCategories.push(this.$t('message.count_clicks'));
                    });

                    vueInst.loadedData = true;
                    vueInst.chartDataCategoriesQuantity = {
                        labels : labels,
                        datasets: [
                            {
                                label: labelsCategories[0],
                                data: data1,
                                backgroundColor: '#E97132',
                                borderColor: '#E97132',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[1],
                                data: data2,
                                backgroundColor: '#156082',
                                borderColor: '#156082',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[2],
                                data: data3,
                                backgroundColor: '#196B24',
                                borderColor: '#196B24',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[3],
                                data: data4,
                                backgroundColor: '#0F9ED5',
                                borderColor: '#0F9ED5',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[4],
                                data: data5,
                                backgroundColor: '#4FBD4F',
                                borderColor: '#4FBD4F',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[5],
                                data: data6,
                                backgroundColor: '#5364B9',
                                borderColor: '#5364B9',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[6],
                                data: data7,
                                backgroundColor: '#CF2CE0',
                                borderColor: '#CF2CE0',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[7],
                                data: data8,
                                backgroundColor: '#DC3030',
                                borderColor: '#DC3030',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            },
                            {
                                label: labelsCategories[8],
                                data: data9,
                                backgroundColor: '#0D3A4E',
                                borderColor: '#0D3A4E',
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            }
                        ],
                    }
                    vueInst.totalPieTitle = this.$t('message.count_clicks');
                    vueInst.total = data9[response.months.length - 1];
                    const percent1 = Math.round((data1[data1.length-1] / vueInst.total) * 100);
                    const percent2 = Math.round((data2[data2.length-1] / vueInst.total) * 100);
                    const percent3 = Math.round((data3[data3.length-1] / vueInst.total) * 100);
                    const percent4 = Math.round((data4[data4.length-1] / vueInst.total) * 100);
                    const percent5 = Math.round((data5[data5.length-1] / vueInst.total) * 100);
                    const percent6 = Math.round((data6[data6.length-1] / vueInst.total) * 100);
                    const percent7 = Math.round((data7[data7.length-1] / vueInst.total) * 100);
                    const percent8 = Math.round((data8[data8.length-1] / vueInst.total) * 100);
                    vueInst.chartDataPieCategoriesQuantity = {
                        labels : labelsCategories.map(label => label + ' %'),
                        datasets: [
                            
                            {
                                label: this.$t('message.last_month'),
                                data: [percent1, percent2, percent3, percent4, percent5, percent6, percent7, percent8],
                                backgroundColor: ['#E97132', '#156082', '#196B24', '#0F9ED5', '#4FBD4F', '#5364B9', '#CF2CE0', '#DC3030'],
                                borderColor: ['#E97132', '#156082', '#196B24', '#0F9ED5', '#4FBD4F', '#5364B9', '#CF2CE0', '#DC3030'],
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                  color: '#ffffff',
                                  formatter: (value: number) => {
                                    // Format value to display as percentage
                                    return value + ' %';
                                  }
                                }
                              }
                        ],
                    }


                    const labelsReversed = this.reverseArray(labels);
                    const revData1 = this.reverseArray(data1);
                    const revData2 = this.reverseArray(data2);
                    const revData3 = this.reverseArray(data3);
                    const revData4 = this.reverseArray(data4);
                    const revData5 = this.reverseArray(data5);
                    const revData6 = this.reverseArray(data6);
                    const revData7 = this.reverseArray(data7);
                    const revData8 = this.reverseArray(data8);
                    const revData9 = this.reverseArray(data9);
                    const arrData1: any = [];
                    const arrData2: any = [];
                    const arrData3: any = [];
                    const arrData4: any = [];
                    const arrData5: any = [];
                    const arrData6: any = [];
                    const arrData7: any = [];
                    const arrData8: any = [];
                    const arrDataTotal: any = [];
                    arrDataTotal.push({name: 'no_clients', value: this.$t('message.count_clicks')});
                    arrData1.push({name: 'no_clients', value: labelsCategories[0]})
                    arrData2.push({name: 'no_clients', value: labelsCategories[1]})
                    arrData3.push({name: 'no_clients', value: labelsCategories[2]})
                    arrData4.push({name: 'no_clients', value: labelsCategories[3]})
                    arrData5.push({name: 'no_clients', value: labelsCategories[4]})
                    arrData6.push({name: 'no_clients', value: labelsCategories[5]})
                    arrData7.push({name: 'no_clients', value: labelsCategories[6]})
                    arrData8.push({name: 'no_clients', value: labelsCategories[7]})

                    labelsReversed.forEach((label, index) => {
                        vueInst.columnsMonths.push( { name: label, label: label, field: label, align: 'left', sortable: false});
                        vueInst.visibleColumnsMonths.push(label);
                        arrData1.push({name: label, value: revData1[index]})
                        arrData2.push({name: label, value: revData2[index]})
                        arrData3.push({name: label, value: revData3[index]})
                        arrData4.push({name: label, value: revData4[index]})
                        arrData5.push({name: label, value: revData5[index]})
                        arrData6.push({name: label, value: revData6[index]})
                        arrData7.push({name: label, value: revData7[index]})
                        arrData8.push({name: label, value: revData8[index]})
                        arrDataTotal.push({name: label, value: revData9[index]});
                    });

                    vueInst.months.push(arrData1, arrData2, arrData3, arrData4, arrData5, arrData6, arrData7, arrData8, arrDataTotal);
                }
                vueInst.$q.loading.hide();
            });
        }

        if(vueInst.chartType === 'countOffers') {
            ServiceLogs.getOffersDetailsReportCount(vueInst.startDate, vueInst.endDate).then(response => {
                if(response.status == 'success') {
                  const labels : string[] = [];
                  const data: number[] = [];
                  const data2: number[] = [];
                  const data3: number[] = [];
                  const data4: number[] = [];
                  vueInst.months = [];
                  vueInst.monthDataOffersCount = response.months;
                  vueInst.columnsMonths = [
                      { name: 'no_clients', label: this.$t('message.requests'), field: 'no_clients', align: 'left', sortable: true}
                  ];
                  vueInst.visibleColumnsMonths = ['no_clients'];
    
                  response.months.forEach(month => {
                      const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                      labels.push(label);
                    //   labels.push('');
                      data.push(month.offers.filter(offer => offer.status === 'k').length); //Cereri cu status comandat 
                      data2.push(month.offers.filter(offer => offer.status === 'c').length); // Cereri cu status respins
                      data3.push(month.offers.filter(offer => offer.status === 'p' || offer.status === 'm' || offer.status === 'o').length); // Cereri deschise 
                      data4.push(month.offers.length);// total cereri
                  });
                  vueInst.loadedData = true;
                  vueInst.chartDataCountOffers = {
                      labels : labels,
                      datasets: [
                          {
                             label: this.$t('message.request_ordered'),
                             data: data,
                             backgroundColor: '#E97132',
                             borderColor: '#E97132',
                             tension: 0.5,
                             pointRadius: 16,
                             datalabels: {
                               color: '#ffffff'
                             }
                           },
                           {
                             label: this.$t('message.request_canceled'),
                             data: data2,
                             backgroundColor: '#156082',
                             borderColor: '#156082',
                             tension: 0.5,
                             pointRadius: 16,
                             datalabels: {
                               color: '#ffffff'
                             }
                           },
                           {
                               label: this.$t('message.request_opened'),
                               data: data3,
                               backgroundColor: '#196B14',
                               borderColor: '#196B14',
                               tension: 0.5,
                               pointRadius: 16,
                               datalabels: {
                                 color: '#ffffff'
                               }
                             },
                          {
                              label: this.$t('message.request_total'),
                              data: data4,
                              backgroundColor: '#0F9ED5',
                              borderColor: '#0F9ED5',
                              tension: 0.5,
                              pointRadius: 16,
                              datalabels: {
                                  color: '#ffffff'
                              }
                          }
                      ],
                    }
                    
                    vueInst.totalPieTitle = this.$t('message.request_total');
                    const percentK = Math.round((response.months[response.months.length - 1].offers.filter(offer => offer.status === 'k').length / data4[response.months.length - 1]) * 100);
                    const percentC = Math.round((response.months[response.months.length - 1].offers.filter(offer => offer.status === 'c').length / data4[response.months.length - 1]) * 100);
                    const percentO = Math.round((response.months[response.months.length - 1].offers.filter(offer => offer.status === 'p' || offer.status === 'm' || offer.status === 'o').length / data4[response.months.length - 1]) * 100);
                    vueInst.total = data4[response.months.length - 1];
                    vueInst.chartDataPieOffers = {
                        labels : [this.$t('message.request_ordered') + ' %', this.$t('message.request_canceled') + ' %', this.$t('message.request_opened') + ' %'],
                        datasets: [
                            
                            {
                                label: this.$t('message.last_month'),
                                data: [percentK, percentC, percentO],
                                backgroundColor: ['#E97132', '#156082', '#196B24'],
                                borderColor: ['#E97132', '#156082', '#196B24'],
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff',
                                    formatter: (value: number) => {
                                    // Format value to display as percentage
                                    return value + ' %';
                                    }
                                }
                            }
                        ],
                    }
                    
                    const labelsReversed = this.reverseArray(labels);
                    const revData = this.reverseArray(data);
                    const revData2 = this.reverseArray(data2);
                    const revData3 = this.reverseArray(data3);
                    const revData4 = this.reverseArray(data4);
                    const arrDataOrdered: any = [];
                    const arrDataCanceled: any = [];
                    const arrDataOpened: any = [];
                    const arrDataTotal: any = [];
                    arrDataOrdered.push({name: 'no_clients', value: this.$t('message.request_ordered')});
                    arrDataCanceled.push({name: 'no_clients', value: this.$t('message.request_canceled')});
                    arrDataOpened.push({name: 'no_clients', value: this.$t('message.request_opened')});
                    arrDataTotal.push({name: 'no_clients', value: this.$t('message.request_total')});

                    labelsReversed.forEach((label, index) => {
                        vueInst.columnsMonths.push( { name: label, label: label, field: label, align: 'left', sortable: false});
                        vueInst.visibleColumnsMonths.push(label);
                        arrDataOrdered.push({name: label, value: revData[index]});
                        arrDataCanceled.push({name: label, value: revData2[index]});
                        arrDataOpened.push({name: label, value: revData3[index]});
                        arrDataTotal.push({name: label, value: revData4[index]});
                    });

                    vueInst.months.push(arrDataOrdered, arrDataCanceled, arrDataOpened, arrDataTotal);


                  vueInst.$q.loading.hide();
                }
            });
    
        }

        
        if(this.chartType == 'quantityOffers') {
            ServiceLogs.getOffersDetailsReportQuantity(this.startDate, this.endDate).then(response => {
                if(response.status == 'success') {
                    const labels : string[] = [];
                    const data: number[] = [];
                    const data2: number[] = [];
                    const data3: number[] = [];
                    const data4: number[] = [];
                    vueInst.months = [];
                    vueInst.monthDataOffers = response.months;
                    vueInst.columnsMonths = [
                        { name: 'no_clients', label: this.$t('message.requests'), field: 'no_clients', align: 'left', sortable: false}
                    ];
                    vueInst.visibleColumnsMonths = ['no_clients'];

                    response.months.forEach(month => {
                        const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                        labels.push(label);
                        const qK = vueInst.getTotalQuantityOffers(month.offers.filter(offer => offer.offer.status === 'k'));
                        const qC = vueInst.getTotalQuantityOffers(month.offers.filter(offer => offer.offer.status === 'c'));
                        const qO = vueInst.getTotalQuantityOffers(month.offers.filter(offer => offer.offer.status === 'p' || offer.offer.status === 'm' || offer.offer.status === 'o'));
                        data.push(qK); //Cereri cu status comandat 
                        data2.push(qC); // Cereri cu status respins
                        data3.push(qO); // Cereri deschise 
                        data4.push(qK + qC + qO);// total cereri
                        // data4.push(month.offers.length);// total cereri
                    });
                    vueInst.loadedData = true;
                    vueInst.chartDataCountOffers = {
                        labels : labels,
                        datasets: [
                            {
                               label: this.$t('message.request_ordered'),
                               data: data,
                               backgroundColor: '#E97132',
                               borderColor: '#E97132',
                               tension: 0.5,
                               pointRadius: 16,
                               pointStyle: 'circle',
                               datalabels: {
                                 color: '#ffffff'
                               }
                             },
                             {
                               label: this.$t('message.request_canceled'),
                               data: data2,
                               backgroundColor: '#156082',
                               borderColor: '#156082',
                               tension: 0.5,
                               pointRadius: 16,
                               pointStyle: 'circle',
                               datalabels: {
                                 color: '#ffffff'
                               }
                             },
                             {
                                 label: this.$t('message.request_opened'),
                                 data: data3,
                                 backgroundColor: '#196B24',
                                 borderColor: '#196B24',
                                 tension: 0.5,
                                 pointRadius: 16,
                                 pointStyle: 'circle',
                                 datalabels: {
                                   color: '#ffffff'
                                 }
                               },
                            {
                                label: this.$t('message.request_total'),
                                data: data4,
                                backgroundColor: '#0F9ED5',
                                borderColor: '#0F9ED5',
                                tension: 0.5,
                                pointRadius: 16,
                                pointStyle: 'circle',
                                datalabels: {
                                    color: '#ffffff'
                                }
                            }
                        ],
                    }
                    
                    vueInst.totalPieTitle = this.$t('message.request_total');
                    vueInst.total = data4[response.months.length - 1];
                    const percentK = Math.round((data[response.months.length - 1] / vueInst.total) * 100);
                    const percentC = Math.round((data2[response.months.length - 1] / vueInst.total) * 100);
                    const percentO = Math.round((data3[response.months.length - 1] / vueInst.total) * 100);
                    vueInst.chartDataPieOffers = {
                        labels : [this.$t('message.request_ordered') + ' %', this.$t('message.request_canceled')+ ' %', this.$t('message.request_opened') + ' %'],
                        datasets: [
                            
                            {
                                label: this.$t('message.last_month'),
                                data: [percentK, percentC, percentO],
                                backgroundColor: ['#E97132', '#156082', '#196B24'],
                                borderColor: ['#E97132', '#156082', '#196B24'],
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                    color: '#ffffff',
                                    formatter: (value: number) => {
                                    // Format value to display as percentage
                                        return value + ' %';
                                    }  
                                }
                            }
                        ],
                    }
                    
                    const labelsReversed = this.reverseArray(labels);
                    const revData = this.reverseArray(data);
                    const revData2 = this.reverseArray(data2);
                    const revData3 = this.reverseArray(data3);
                    const revData4 = this.reverseArray(data4);
                    const arrDataOrdered: any = [];
                    const arrDataCanceled: any = [];
                    const arrDataOpened: any = [];
                    const arrDataTotal: any = [];
                    arrDataOrdered.push({name: 'no_clients', value: this.$t('message.request_ordered')});
                    arrDataCanceled.push({name: 'no_clients', value: this.$t('message.request_canceled')});
                    arrDataOpened.push({name: 'no_clients', value: this.$t('message.request_opened')});
                    arrDataTotal.push({name: 'no_clients', value: this.$t('message.request_total')});

                    labelsReversed.forEach((label, index) => {
                        vueInst.columnsMonths.push( { name: label, label: label, field: label, align: 'left', sortable: false});
                        vueInst.visibleColumnsMonths.push(label);
                        arrDataOrdered.push({name: label, value: revData[index]});
                        arrDataCanceled.push({name: label, value: revData2[index]});
                        arrDataOpened.push({name: label, value: revData3[index]});
                        arrDataTotal.push({name: label, value: revData4[index]});
                    });

                    vueInst.months.push(arrDataOrdered, arrDataCanceled, arrDataOpened, arrDataTotal);


                    vueInst.$q.loading.hide();
                }
            });
        }

        if(vueInst.chartType === 'offersTime') {
            ServiceLogs.getOffersDetailsReportTimeBetween(vueInst.startDate, vueInst.endDate).then(response => {
                if(response.status == 'success') {
                    //chartDataOffersTimeBetween
                    let labels : string[] = [];
                    const labelsPie : string[] = [];
                    const datasets: any = [];
                    const dataPie: number[] = [];
                    const dataArray: any = [];
                    let dataArrayTable: any = [];
                    const colorsArray: any = [];
                    let totalTime = 0;
                    vueInst.months = [];
                    vueInst.agentsTimeSpent = response.agents;
                    vueInst.columnsMonths = [
                        { name: 'no_clients', label: this.$t('message.agent2'), field: 'no_clients', align: 'left', sortable: false}
                    ];
                    vueInst.visibleColumnsMonths = ['no_clients'];

                    response.agents.forEach((agent, index) => {
                        const data: number[] = [];
                        labels = [];
                        dataArrayTable = [];
                        agent.months.forEach(month => {
                            const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                            labels.push(label);
                            const val = vueInst.getAvgTimeBetweenSendAndReceiveOffer(month.offers);
                            data.push(val);
                            dataArrayTable.unshift({name: label, value: val});
                        });
                        dataArrayTable.unshift({name: 'no_clients', value: agent.firstName + ' ' + agent.lastName});
                        totalTime += data[data.length - 1];
                        dataPie.push(Number(data[data.length - 1]));
                        const color = vueInst.colorsArray[index];
                        colorsArray.push(color);
                        labelsPie.push(agent.firstName + ' ' + agent.lastName);
                        datasets.push(
                            {
                                label: agent.firstName + ' ' + agent.lastName,
                                data: data,
                                backgroundColor: color,
                                borderColor: color,
                                tension: 0.5,
                                pointRadius: 20,
                                datalabels: {
                                    color: '#ffffff'
                                }
                            }
                        );
                        dataArray.push(dataArrayTable);
                    });

                    vueInst.totalPieTitle = this.$t('message.total_time_agents');
                    vueInst.total = Math.round(totalTime / response.agents.length);
                    vueInst.chartDataPieOffers = {
                        labels : labelsPie,
                        datasets: [
                            
                            {
                                label: this.$t('message.last_month'),
                                data: dataPie,
                                backgroundColor: colorsArray,
                                borderColor: colorsArray,
                                tension: 0.5,
                                pointRadius: 16,
                                datalabels: {
                                color: '#ffffff'  
                                }
                            }
                        ],
                    }
    
                    vueInst.loadedData = true;
                    vueInst.chartDataOffersTimeBetween = {
                        labels : labels,
                        datasets: datasets
                    }

                    const labelsReversed = this.reverseArray(labels);

                    labelsReversed.forEach((label) => {
                        vueInst.columnsMonths.push( { name: label, label: label, field: label, align: 'left', sortable: false});
                        vueInst.visibleColumnsMonths.push(label);
                    });
                    
                    vueInst.months = dataArray;

                    vueInst.$q.loading.hide();
                }
            });
        }
    }  
}