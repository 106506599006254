import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import user from '@/store/user';
import nomenclatoare from '@/store/nomenclatoare';
import {getModule} from 'vuex-module-decorators';
import {ServiceAdminNomCategory} from '@/services/ServiceAdminNomCategory'
import {ServiceAdminNomProducts} from '@/services/ServiceAdminNomProducts'
import {TArticle} from "@/types/TArticle";
import {TOptionCategory} from "@/types/TOptionCategory";
import throttle from 'quasar/src/utils/throttle.js';;
import ContacteleMeleColorMetal from "@/components/ContacteleMeleColorMetal.vue";
import {EnumUM} from "@/types/EnumUM";

type TArticleStr = {
    appid:string;
    pid:string;
    categoryPid:string;
    lantHierarchyCategoriesRo: string;
    lantHierarchyCategoriesEn: string;
    lantHierarchyCategoriesHu: string;
    lantHierarchyCategoriesDe: string;
    code: string;
    name_ro:string;
    name_en:string;
    name_hu:string;
    name_de:string;
    UMBase:string;
    um1: string;
    um2: string;
    um1ToUm2: number;
    isActive:string;
    withLength: string;
    withWidth: string;
    withThickness: string;
    withDiameter: string;
    withHeight: string;
    withAlloy: string;
    withEyes: string;
    withEyes2: string;
    sizeLength:number|null;
    sizeWidth:number|null;
    sizeThickness:number|null;
    sizeDiameter:number|null;
    sizeHeight:number|null;
    sizeAlloy: string|null;
    sizeEyes: number|null;
    sizeEyes2: number|null;
}

@Options({
    name: "EditArticle",
    components: {}
})
export default class EditArticle extends Vue {
    @Prop({ default: '0' }) public readonly pid!: string|null;
    @Prop({ default: '0' }) public readonly product_code!: string|null;
    //@Prop() public fnOnSaveData!: (userid:string)=>void;
    @Prop() public closeHandler!: (needRefresh:boolean)=>void;
    public ajaxLoadingData=true;
    public storeNomenclatoare = getModule(nomenclatoare);
    public article:TArticle={
        appid: '',
        pid: '',
        categoryPid: '',
        lantHierarchyCategoriesRo: '',
        lantHierarchyCategoriesEn: '',
        lantHierarchyCategoriesHu: '',
        code: '',
        name_ro: '',
        name_en: '',
        name_hu: '',
        UMBase: '',
        um1: '',
        um2: '',
        um1ToUm2: 1,
        isActive: false,
        withLength: false,
        withWidth: false,
        withThickness: false,
        withDiameter: false,
        withHeight: false,
        withAlloy: false,
        withEyes: false,
        withEyes2: false,
        sizeLength: 0,
        sizeWidth: 0,
        sizeThickness: 0,
        sizeDiameter: 0,
        sizeHeight: 0,
        sizeAlloy: '',
        sizeEyes: 0,
        sizeEyes2: 0,
        lantHierarchyCategoriesDe: '',
        name_de: ''
    };
    public urlUserImgProfileByAppid = '';
    declare public $refs: any;
    public filteredOptionsCategories:TOptionCategory[]=[];
    public userStore = getModule(user);
    public nomenclatoareStore = getModule(nomenclatoare);

    public goToView(pRouteViewName: string ) {
        this.$router.push({ name: pRouteViewName })
    }

    get optionsUM(): EnumUM[] {
        return this.storeNomenclatoare.optionsUM;
    }

    public onSubmit(){
        const vueInst=this;
        //alert('de discutat, nu stiu daca ar trebui sa modifice ceva in portaL, poate trebuie sa vina totul din Sales?!')

        vueInst.$q.loading.show()
        ServiceAdminNomProducts.postProduct(vueInst.article).then(response=>{
            vueInst.$q.loading.hide();
            if(response.status=='success'){
                vueInst.closeHandler(true);
            }
        });

    }

    public updateUM(){
        const vueInst = this;
        if(vueInst.article.UMBase == 'M' && vueInst.article.sizeLength) {
            vueInst.article.um1ToUm2 = vueInst.article.sizeLength * Number(vueInst.article.um2);
        } else {
            vueInst.article.um1ToUm2 = Number(vueInst.article.um2);
        }
    }

    getCategoryDetailsAndSetSizes(pCategoryPid:string){
        const vueInst=this;
        console.log('getCategoryDetailsAndSetSizes=%o',pCategoryPid);
        const pCategory=vueInst.optionsCategories.find(category=>{
            return category.pid==pCategoryPid;
        })
        console.log('getCategoryDetailsAndSetSizes pCategory=%o',pCategory)
        if(pCategory) {

            vueInst.article.lantHierarchyCategoriesRo='';
            vueInst.article.lantHierarchyCategoriesEn='';
            vueInst.article.lantHierarchyCategoriesHu='';

            // vueInst.article.um1 = '';
            // vueInst.article.um2 = '';
            // vueInst.article.um1ToUm2 = 1;

            // vueInst.article.sizeLength = null;
            // vueInst.article.sizeWidth = null;
            // vueInst.article.sizeThickness = null;
            // vueInst.article.sizeDiameter = null;
            // vueInst.article.sizeHeight = null;
            // vueInst.article.sizeAlloy = null;
            // vueInst.article.sizeType = null;

            vueInst.article.withLength = (pCategory.withLength == 'y' ? true : false);
            vueInst.article.withWidth = (pCategory.withWidth == 'y' ? true : false);
            vueInst.article.withThickness = (pCategory.withThickness == 'y' ? true : false);
            vueInst.article.withDiameter = (pCategory.withDiameter == 'y' ? true : false);
            vueInst.article.withHeight = (pCategory.withHeight == 'y' ? true : false);
            vueInst.article.withAlloy = (pCategory.withAlloy == 'y' ? true : false);
            vueInst.article.withEyes = (pCategory.withEyes == 'y' ? true : false);
        }
    }

    private get optionsCategories():TOptionCategory[]{
      return this.nomenclatoareStore.optionsCategories;
    }

    public getListDataCategoryThenArticleFromDB(){
        const vueInst=this;
        ServiceAdminNomCategory.getNomCategorylistData().then(response=>{
            console.log('response=%o',response)
            if(response.status=='success'){
                vueInst.nomenclatoareStore.set_categories(JSON.parse(JSON.stringify(response.listDataCategories)));
                vueInst.filteredOptionsCategories=vueInst.optionsCategories;
                if(vueInst.pid && vueInst.pid!='0' && vueInst.product_code && vueInst.product_code!='0'){
                    ServiceAdminNomProducts.getArticleApollo(vueInst.product_code).then(() => {
                        vueInst.getArticleFromDB();
                    });
                }else{
                    vueInst.$q.loading.hide();
                }
            }
        })
    }

    public getArticleFromDB(){
        const vueInst=this;
        if(vueInst.pid && vueInst.pid!='0'){
            ServiceAdminNomProducts.getArticleFromDB(vueInst.pid).then(response=>{
                vueInst.$q.loading.hide();
                const item :TArticleStr= JSON.parse(JSON.stringify(response.product)) as TArticleStr;
                if(response.status=='success'){
                    vueInst.article= {
                        appid:item.appid,
                        pid:item.pid,
                        categoryPid:item.categoryPid,
                        lantHierarchyCategoriesRo:item.lantHierarchyCategoriesRo,
                        lantHierarchyCategoriesEn:item.lantHierarchyCategoriesEn,
                        lantHierarchyCategoriesHu:item.lantHierarchyCategoriesHu,
                        code: item.code,
                        name_ro:item.name_ro,
                        name_en:item.name_en,
                        name_hu:item.name_hu,
                        UMBase:item.UMBase,
                        um1: item.um1,
                        um2: item.um2,
                        um1ToUm2: item.um1ToUm2,
                        isActive:item.isActive=='y'?true:false,
                        withLength: item.withLength=='y'?true:false,
                        withWidth: item.withWidth=='y'?true:false,
                        withThickness: item.withThickness=='y'?true:false,
                        withDiameter: item.withDiameter=='y'?true:false,
                        withHeight: item.withHeight=='y'?true:false,
                        withAlloy: item.withAlloy=='y'?true:false,
                        withEyes: item.withEyes=='y'?true:false,
                        withEyes2: item.withEyes2=='y'?true:false,
                        sizeLength:item.sizeLength,
                        sizeWidth:item.sizeWidth,
                        sizeThickness:item.sizeThickness,
                        sizeDiameter:item.sizeDiameter,
                        sizeHeight:item.sizeHeight,
                        sizeAlloy: item.sizeAlloy,
                        sizeEyes: item.sizeEyes,
                        sizeEyes2: item.sizeEyes2,
                        name_de: item.name_de,
                        lantHierarchyCategoriesDe: item.lantHierarchyCategoriesDe
                    }
                }
            })
        }
    }

    public focusOnArticleName(){
        this.$refs.refArticleName.focus();
    }

    public focusOnCategorySelection(){
        this.$refs.refArticleCategorySelection.focus();
    }

    public focusOnArticleType(){
        this.$refs.refArticleType.focus();
    }
    public focusOnUM2(){
        this.$refs.refUM2.focus();
    }

    filterFnCategories (val:string, update:any) {
        const vueInst=this;

        if (val === '') {
            update(() => {
                vueInst.filteredOptionsCategories=vueInst.optionsCategories;
            })
            return
        }

        update(() => {
            const needle = val.toLowerCase()
            vueInst.filteredOptionsCategories = vueInst.optionsCategories.filter(v => this.$i18n.locale == 'de' ? v.lantHierarchyCategoriesDe.toLowerCase().indexOf(needle) > -1 :
                                                                                      this.$i18n.locale == 'ro' ? v.lantHierarchyCategoriesRo.toLowerCase().indexOf(needle) > -1 :
                                                                                      this.$i18n.locale == 'en' ? v.lantHierarchyCategoriesEn.toLowerCase().indexOf(needle) > -1 : v.lantHierarchyCategoriesHu.toLowerCase().indexOf(needle) > -1 )
        })
    }

    public onReset(){
        this.article={
            appid:'',
            pid:'',
            categoryPid:'',
            lantHierarchyCategoriesRo:'',
            lantHierarchyCategoriesEn:'',
            lantHierarchyCategoriesHu:'',
            code: '',
            name_ro:'',
            name_en:'',
            name_hu:'',
            UMBase:'',
            um1:'',
            um2: '',
            um1ToUm2: 1,
            isActive:false,
            withLength: false,
            withWidth: false,
            withThickness: false,
            withDiameter: false,
            withHeight: false,
            withAlloy: false,
            withEyes: false,
            withEyes2: false,
            sizeLength:0,
            sizeWidth:0,
            sizeThickness:0,
            sizeDiameter:0,
            sizeHeight:0,
            sizeAlloy:'',
            sizeEyes:0,
            sizeEyes2:0,
            lantHierarchyCategoriesDe: '',
            name_de: ''
        }
    }

    public created(){
        const vueInst=this;
        vueInst.$q.loading.show();
        vueInst.onReset();
        vueInst.getListDataCategoryThenArticleFromDB();
    }
}
