
import { Vue, Options } from 'vue-class-component';
// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, Line } from 'vue-chartjs';
import { ref } from 'vue';
import ServiceLogs from '@/services/ServiceLogs';
import { TCategoryReport } from '@/types/TCategoryReport';
import { TUserMonth } from '@/types/TUserMonth';
import { TProductCerere } from '@/types/TProductCerere';
import { TOfferReport } from '@/types/TOfferReport';


Chart.register(...registerables, ChartDataLabels);

@Options({
    name: "AdminReports",
    components: { Bar, Line}
})

export default class AdminReports extends Vue {

    public chartData = {};
    public chartData2 = {};
    public loadedData = false;
  
    public options = {
        responsive: true,
        maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
				maxHeight: 0,
				maxWidth: 0,
				hidden: true
			},
			hover: {
				mode: null  // Disable hover interactions
			},
			tooltip: {
				enabled: false  // Disable tooltips
			},
		},
        events: []
    };

    public chartDataCountOffers = {};
    public chartDataOffersTimeBetween = {};
    public chartDataCountOffersModified = {};
    public chartDataCountOffersTimeRequest = {};
    public chartDataOffersQuantity = {};
    public chartDataCategoriesClicks = {};
    public chartDataCategoriesQuantity = {};
    public chartDataCategoriesQuantityCanceled = {};
    public chartDataCountOffersTimeSpent = {};
    public chartDataCountOffersTimeReply = {};
    public chartDataUsersTimeSpent = {};
    public chartDataNoClicks = {};
    public chartDataBasket = {};
    public categories: TCategoryReport[] = [];
    public categoriesNoCLicks: TCategoryReport[] = [];
    public categoriesCanceled: TCategoryReport[] = [];
    
    public months: TUserMonth[] = [];
    
    public startDate='';
    public endDate='';

    public loadingUsersEvolution = true;
    public loadingUsersWithProductsInBasket = true;
    public loadingOffersCategoriesQuantity = true;
	public loadingOffersCategoriesClicks = true;
    public loadingCountOffers = true;
    public loadingNoLoginsAndTimeSpent = true;
	public loadingOffersQuantity = true;
	public loadingOffersTime = true;
	public colorsArray = [
		'#E97132', '#156082', '#196B14', '#0F9ED5',
		'#4FBD4F', '#5364B9', '#CF2CE0', '#DC3030',
		'#42505f', '#ffbf00', '#fa9af7', '#ecdcf0',
		'#ff1493', '#e1ca09', '#f90001', '#003366',
		'#00699c', '#b4bcc2', '#ca14af', '#4666ff'
	];

	public getMinutesDifference(dateString1: string, dateString2: string): number {
		// Convert string dates to Date objects
		const date1 = new Date(dateString1);
		const date2 = new Date(dateString2);
	
		// Calculate the difference in milliseconds
		const differenceInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
	
		// Convert milliseconds to minutes
		const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
	
		return differenceInMinutes;
	}

	public transformDate(date: number): number {
		let date2 = 0;
		if (date < 60) { // minutes
			date2 = date;
		} else if(date < 1440) { // hours
			date2 = date / 60;
		} else { // days
			date2 = date / 1440;
		}	
		return date2;
	} 

	public formatDate (date: Date) {
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns 0-based index
		const year = date.getFullYear();
		return `${day}.${month}.${year}`;
	}

	public formatDateMonth(inputDate: string, locale: string) {
		const [day, month, year] = inputDate.split('.');

		// Create a Date object using the parsed components (subtract 1 from month since Date expects zero-based month)
		const dateObject = new Date(`${year}-${month}-${day}`);

		// Get the full month name based on the specified locale
		const options : Intl.DateTimeFormatOptions = { month: 'long', year: 'numeric' };
		const formatter = new Intl.DateTimeFormat(locale, options);
		const formattedDate = formatter.format(dateObject);

		return formattedDate;
	}

	public getQuantityOffer(products: TProductCerere[]): number{
        const quantity = products.reduce((offerTotal, product) => {
            return offerTotal + Math.round(product.qum2);
        }, 0);
        return quantity;
    }


	public getTotalQuantityOffers(offers: TOfferReport[]): number{
        const quantity = offers.reduce((total, offer) => {
            return total + Math.round(this.getQuantityOffer(offer.products));
        }, 0);
        return Math.round(quantity/1000);
    }

	public getTimeBetweenSendAndReceiveOffer(offer: TOfferReport): number {
        // console.log(offer.offers_time_track);
        let time = 0;
        if(offer.offers_time_track && offer.offers_time_track.length > 0) {
            let creation_date = '';
            let modify_date = '';
            let send_offer = '';
            let send_modify_offer = '';
            let accept_date = '';
            let cancel_date = '';
            offer.offers_time_track.forEach(track => {
                switch (track.date_type) {
                    case 1:
                        creation_date = track.track_date;
                        break;
                    case 2:
                        modify_date = track.track_date;
                        break;
                    case 3:
                        cancel_date = track.track_date;
                        break;
                    case 4:
                        accept_date = track.track_date;
                        break;
                    case 5:
                        send_offer = track.track_date;
                        break;
                    case 6:
                        send_modify_offer = track.track_date;
                        break;
                }
            });
            if(creation_date != '' && send_offer != '') {
                time = this.getMinutesDifference(creation_date, send_offer);
            }
            if(modify_date != '' && send_modify_offer != '') {
                time = this.getMinutesDifference(modify_date, send_modify_offer);
            }
        }
        return time;
    }

	public getAvgTimeBetweenSendAndReceiveOffer(offers: TOfferReport[]) : number {
		let avg = 0;
		let sum = 0;
		let count = 0;

		offers.forEach(offer => {
			const time = this.getTimeBetweenSendAndReceiveOffer(offer);
			if(time > 0) {
				sum += time;
				count++;
			}
		})

		if (count > 0) {
			avg = sum / count;
		}
		return Math.round(avg);
	}

	public getRandomColorFromArray() {
		// Generate a random index to select a color from the array
		const randomIndex = Math.floor(Math.random() * this.colorsArray.length);
	
		// Return the randomly selected color
		return this.colorsArray[randomIndex];
	}


	public openDetails(typeOfAccess: string, chartType: string){
		this.$router.push({name: 'AdminReportsDetails',  params: {
			typeOfAccess : typeOfAccess,
			chartType: chartType
		}});
	}

    mounted(): void {
		this.chartDataCountOffers = {};
		this.chartDataOffersTimeBetween = {};
		this.chartDataCountOffersModified = {};
		this.chartDataCountOffersTimeRequest = {};
		this.chartDataOffersQuantity = {};
		this.chartDataCategoriesClicks = {};
		this.chartDataCategoriesQuantity = {};
		this.chartDataCategoriesQuantityCanceled = {};
		this.chartDataCountOffersTimeSpent = {};
		this.chartDataCountOffersTimeReply = {};
		this.chartDataUsersTimeSpent = {};
		this.chartDataNoClicks = {};
		this.chartDataBasket = {};
		this.categories= [];
		this.categoriesNoCLicks = [];
		this.categoriesCanceled = [];
        const vueInst = this;
        // vueInst.$q.loading.show();

        // Get today's date
        const today = new Date();
        const january2014 = new Date('01.01.2024');
        
        // Get the date one month ago
        let oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

        if(oneYearAgo < january2014) {
            oneYearAgo = january2014;
        }
         // Formatted dates
        vueInst.endDate = this.formatDate(today);
        vueInst.startDate = this.formatDate(oneYearAgo);

        
        vueInst.loadingUsersEvolution = true;
        vueInst.loadingUsersWithProductsInBasket = true;
        vueInst.loadingOffersCategoriesQuantity = true;
		vueInst.loadingOffersCategoriesClicks = true;
        vueInst.loadingCountOffers = true;
        vueInst.loadingNoLoginsAndTimeSpent = true;

        ServiceLogs.getUsersNumberLogsPerAgent(vueInst.startDate, vueInst.endDate).then(response => {
            if(response.status == 'success') {
                // console.log(response.agents);
                const labels : string[] = [];
                const data: number[] = [];
                const data2: number[] = [];
                const data3: number[] = [];
                vueInst.months = response.months;
                response.months.forEach(month => {
                    // console.log(agent);
                    labels.push(this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale));
                    // labels.push('');
                    data.push(month.usersInactive.length);
                    data2.push(month.usersActive.length);
                    data3.push(month.usersActive.length + month.usersInactive.length);
                });
                vueInst.loadedData = true;
                vueInst.loadingUsersEvolution = false;
                vueInst.chartData = {
                    labels : labels,
                    datasets: [
                        {
                          label: this.$t('message.no_clients_inactive'),
                          data: data,
                          backgroundColor: '#E97132',
                          borderColor: '#E97132',
                          tension: 0.5,
                          pointRadius: 16,
                          datalabels: {
                            color: '#ffffff'
                          }
                        },
                        {
                          label:  this.$t('message.no_clients_active'),
                          data: data2,
                          backgroundColor: '#156082',
                          borderColor: '#156082',
                          tension: 0.5,
                          pointRadius: 16,
                          datalabels: {
                            color: '#ffffff'
                          }
                        },
                        {
                            label: this.$t('message.no_total_clients'),
                            data: data3,
                            backgroundColor: '#196B14',
                            borderColor: '#196B14',
                            tension: 0.5,
                            pointRadius: 16,
                            datalabels: {
                              color: '#ffffff'
                            }
                          }
                      ],
                }
				// vueInst.$q.loading.hide();
            }
        });

		ServiceLogs.getOffersDetailsReportCount(vueInst.startDate, vueInst.endDate).then(response => {
            if(response.status == 'success') {
              const labels : string[] = [];
              const data: number[] = [];
              const data2: number[] = [];
              const data3: number[] = [];
              const data4: number[] = [];
              vueInst.months = [];

              response.months.forEach(month => {
                  const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                  labels.push(label);
                //   labels.push('');
                  data.push(month.offers.filter(offer => offer.status === 'k').length); //Cereri cu status comandat 
                  data2.push(month.offers.filter(offer => offer.status === 'c').length); // Cereri cu status respins
                  data3.push(month.offers.filter(offer => offer.status === 'p' || offer.status === 'm' || offer.status === 'o').length); // Cereri deschise 
                  data4.push(month.offers.length);// total cereri
              });
              vueInst.loadedData = true;
              vueInst.loadingCountOffers = false;
              vueInst.chartDataCountOffers = {
                  labels : labels,
                  datasets: [
                      {
                         label: this.$t('message.request_ordered'),
                         data: data,
                         backgroundColor: '#E97132',
                         borderColor: '#E97132',
                         tension: 0.5,
                         pointRadius: 16,
                         datalabels: {
                           color: '#ffffff'
                         }
                       },
                       {
                         label: this.$t('message.request_canceled'),
                         data: data2,
                         backgroundColor: '#156082',
                         borderColor: '#156082',
                         tension: 0.5,
                         pointRadius: 16,
                         datalabels: {
                           color: '#ffffff'
                         }
                       },
                       {
                           label: this.$t('message.request_opened'),
                           data: data3,
                           backgroundColor: '#196B14',
                           borderColor: '#196B14',
                           tension: 0.5,
                           pointRadius: 16,
                           datalabels: {
                             color: '#ffffff'
                           }
                         },
                      {
                          label: this.$t('message.request_total'),
                          data: data4,
                          backgroundColor: '#0F9ED5',
                          borderColor: '#0F9ED5',
                          tension: 0.5,
                          pointRadius: 16,
                          datalabels: {
                              color: '#ffffff'
                          }
                      }
                  ],
              }
              vueInst.$q.loading.hide();
            }
        });


    //     // vueInst.$q.loading.show();
		ServiceLogs.getBasketReport().then(response =>{
			if(response.status == 'success') {
                const labels : string[] = [];
                const dataBasket: number[] = [];
                response.agents.forEach(agent => {
                    labels.push(agent.firstName.charAt(0) + '. ' + agent.lastName);
                    // labels.push('');
                    dataBasket.push(agent.users.length);
                });

                vueInst.loadingUsersWithProductsInBasket = false;

				vueInst.chartDataBasket = {
                labels : labels,
                datasets: [
                    {
                    label: this.$t('message.users_with_products_in_basket'),
                    data: dataBasket,
                    backgroundColor: '#123E6B',
                    borderColor: '#ffffff00',
                    pointRadius: 16,
                    datalabels: {
                        color: '#ffffff'
                    }
                    },
                ],
            }
				// vueInst.$q.loading.hide();
			}
		});

		ServiceLogs.getOffersDetailsReportTimeBetween(vueInst.startDate, vueInst.endDate).then(response => {
            if(response.status == 'success') {
				//chartDataOffersTimeBetween
				let labels : string[] = [];
				const datasets: any = [];
				response.agents.forEach((agent, index) => {
					const data: number[] = [];
					labels = [];
					agent.months.forEach(month => {
						const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
						labels.push(label);
						data.push(vueInst.getAvgTimeBetweenSendAndReceiveOffer(month.offers));
					});
					datasets.push(
						{
							label: agent.firstName + ' ' + agent.lastName,
							data: data,
							backgroundColor: vueInst.colorsArray[index],
							borderColor: vueInst.colorsArray[index],
							tension: 0.5,
							pointRadius: 20,
							datalabels: {
								color: '#ffffff'
							}
						}
					);
				});

				vueInst.loadingOffersTime = false;
				vueInst.chartDataOffersTimeBetween = {
					labels : labels,
					datasets: datasets
				}
			}
		});

    //     // vueInst.$q.loading.show();
		ServiceLogs.getUsersTimeSpent(vueInst.startDate, vueInst.endDate).then(response => {
            if(response.status == 'success') {

            const labels : string[] = [];
            const data: number[] = [];
            const data2: number[] = [];
            const data3: number[] = [];

            response.months.forEach(month => {
                const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
                labels.push(label);
                // labels.push('');
                data.push(month.total_count_logins);
                data2.push(month.total_time_spent);
                data3.push(month.avg_time_spent);
            });
            vueInst.loadedData = true;
            vueInst.loadingNoLoginsAndTimeSpent = false;

            vueInst.chartDataUsersTimeSpent = {
                labels : labels,
                datasets: [
                  {
                     label: this.$t('message.no_logins_total'),
                     data: data,
                     backgroundColor: '#E97132',
                     borderColor: '#E97132',
                     tension: 0.5,
                     pointRadius: 24,
                     datalabels: {
                       color: '#ffffff'
                     }
                   },
                   {
                     label: this.$t('message.time_spent_on_portal'),
                     data: data2,
                     backgroundColor: '#156082',
                     borderColor: '#156082',
                     tension: 0.5,
                     pointRadius: 24,
                     datalabels: {
                       color: '#ffffff'
                     }
                   },
                   {
                       label: this.$t('message.avg_time_on_portal'),
                       data: data3,
                       backgroundColor: '#196B14',
                       borderColor: '#196B14',
                       tension: 0.5,
                       pointRadius: 24,
                       datalabels: {
                         color: '#ffffff'
                       }
                     }
                 ],
          }
      }
    });

	ServiceLogs.getCategoriesDetailsReportClick(vueInst.startDate, vueInst.endDate).then(response => {
		if(response.status == 'success'){
			const labels : string[] = [];  
			const data11: number[] = [];
			const data22: number[] = [];
			const data33: number[] = [];
			const data44: number[] = [];
			const data55: number[] = [];
			const data66: number[] = [];
			const data77: number[] = [];
			const data88: number[] = [];
			let labelsCategories: string[] = [];
			response.months.forEach(month => {
				labelsCategories = [];
				const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
				labels.push(label);
				// labels.push('');
				month.categories.forEach((category, index) => {
					const clicks = category.subcategories.reduce((total, subcategory) => {
						return total + Number(subcategory.countClicks);
					}, 0);
					labelsCategories.push(this.$i18n.locale == 'de' ? category.category_name_de : this.$i18n.locale == 'ro' ? category.category_name_ro : this.$i18n.locale == 'en' ? category.category_name_en : category.category_name_hu)
					switch(index) {
						case 0: 
							data11.push(Number(clicks));
							break;
						case 1: 
							data22.push(Number(clicks));
							break;
						case 2: 
							data33.push(Number(clicks));
							break;
						case 3: 
							data44.push(Number(clicks));
							break;
						case 4: 
							data55.push(Number(clicks));
							break;
						case 5: 
							data66.push(Number(clicks));
							break;
						case 6: 
							data88.push(Number(clicks));
							break;
						case 7: 
							data88.push(Number(clicks));
							break;
					}
				});
			});

			vueInst.loadedData = true;
			vueInst.loadingOffersCategoriesClicks = false;
			vueInst.chartDataCategoriesClicks = {
				labels : labels,
				datasets: [
					{
						label: labelsCategories[0],
						data: data11,
						backgroundColor: '#E97132',
						borderColor: '#E97132',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
							color: '#ffffff'
						}
					},
					{
						label: labelsCategories[1],
						data: data22,
						backgroundColor: '#156082',
						borderColor: '#156082',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
							color: '#ffffff'
						}
					},
					{
						label: labelsCategories[2],
						data: data33,
						backgroundColor: '#196B14',
						borderColor: '#196B14',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
						color: '#ffffff'
						}
					},
					{
						label: labelsCategories[3],
						data: data44,
						backgroundColor: '#A02B93',
						borderColor: '#A02B93',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
						color: '#ffffff'
						}
					},
					{
						label: labelsCategories[4],
						data: data55,
						backgroundColor: '#0F9ED5',
						borderColor: '#0F9ED5',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
							color: '#ffffff'
						}
					},
					{
						label: labelsCategories[5],
						data: data66,
						backgroundColor: '#4FBD4F',
						borderColor: '#4FBD4F',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
							color: '#ffffff'
						}
					},
					{
						label: labelsCategories[6],
						data: data77,
						backgroundColor: '#5364B9',
						borderColor: '#5364B9',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
							color: '#ffffff'
						}
					},
					{
						label: labelsCategories[7],
						data: data88,
						backgroundColor: '#CF2CE0',
						borderColor: '#CF2CE0',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
							color: '#ffffff'
						}
					}
					],
				}
		}
			
			vueInst.$q.loading.hide();
	});

	ServiceLogs.getCategoriesDetailsReportQuantity(vueInst.startDate, vueInst.endDate).then(response => {
		if(response.status == 'success'){
			const labels : string[] = [];  
			const data1: number[] = [];
			const data2: number[] = [];
			const data3: number[] = [];
			const data4: number[] = [];
			const data5: number[] = [];
			const data6: number[] = [];
			const data7: number[] = [];
			const data8: number[] = [];
			let labelsCategories: string[] = [];
			response.months.forEach(month => {
				labelsCategories = [];
				const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
				labels.push(label);
				// labels.push('');
				month.categories.forEach((category, index) => {
					const quantity = category.subcategories.reduce((total, subcategory) => {
						return total + Number(subcategory.quantity);
					}, 0);
					labelsCategories.push(this.$i18n.locale == 'de' ? category.category_name_de : this.$i18n.locale == 'ro' ? category.category_name_ro : this.$i18n.locale == 'en' ? category.category_name_en : category.category_name_hu)
					switch(index) {
						case 0: 
							data1.push(Math.floor(quantity/1000));
							break;
						case 1: 
							data2.push(Math.floor(quantity/1000));
							break;
						case 2: 
							data3.push(Math.floor(quantity/1000));
							break;
						case 3: 
							data4.push(Math.floor(quantity/1000));
							break;
						case 4: 
							data5.push(Math.floor(quantity/1000));
							break;
						case 5: 
							data6.push(Math.floor(quantity/1000));
							break;
						case 6: 
							data7.push(Math.floor(quantity/1000));
							break;
						case 7: 
							data8.push(Math.floor(quantity/1000));
							break;
					}
				});
		});
  
			vueInst.loadedData = true;
			vueInst.loadingOffersCategoriesQuantity = false;
			vueInst.chartDataCategoriesQuantity = {
				labels : labels,
				datasets: [
					{
						label: labelsCategories[0],
						data: data1,
						backgroundColor: '#E97132',
						borderColor: '#E97132',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
							color: '#ffffff'
						}
					},
					{
						label: labelsCategories[1],
						data: data2,
						backgroundColor: '#156082',
						borderColor: '#156082',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
							color: '#ffffff'
						}
					},
					{
						label: labelsCategories[2],
						data: data3,
						backgroundColor: '#196B14',
						borderColor: '#196B14',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
						color: '#ffffff'
						}
					},
					{
						label: labelsCategories[3],
						data: data4,
						backgroundColor: '#A02B93',
						borderColor: '#A02B93',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
						color: '#ffffff'
						}
					},
					{
						label: labelsCategories[4],
						data: data5,
						backgroundColor: '#0F9ED5',
						borderColor: '#0F9ED5',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
							color: '#ffffff'
						}
					},
					{
						label: labelsCategories[5],
						data: data6,
						backgroundColor: '#4FBD4F',
						borderColor: '#4FBD4F',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
							color: '#ffffff'
						}
					},
					{
						label: labelsCategories[6],
						data: data7,
						backgroundColor: '#5364B9',
						borderColor: '#5364B9',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
							color: '#ffffff'
						}
					},
					{
						label: labelsCategories[7],
						data: data8,
						backgroundColor: '#CF2CE0',
						borderColor: '#CF2CE0',
						tension: 0.5,
						pointRadius: 16,
						datalabels: {
							color: '#ffffff'
						}
					}
					],
				}
  
			}

			vueInst.$q.loading.hide();
		});


		ServiceLogs.getOffersDetailsReportQuantity(vueInst.startDate, vueInst.endDate).then(response => {
			if(response.status == 'success') {
				const labels : string[] = [];
				const data: number[] = [];
				const data2: number[] = [];
				const data3: number[] = [];
				const data4: number[] = [];
				vueInst.months = [];
  
				response.months.forEach(month => {
					const label = this.formatDateMonth(month.month, this.$i18n.locale == 'en' ? 'en-US' : this.$i18n.locale);
					labels.push(label);
					//   labels.push('');
					const qK = vueInst.getTotalQuantityOffers(month.offers.filter(offer => offer.offer.status === 'k'));
					const qC = vueInst.getTotalQuantityOffers(month.offers.filter(offer => offer.offer.status === 'c'));
					const qO = vueInst.getTotalQuantityOffers(month.offers.filter(offer => offer.offer.status === 'p' || offer.offer.status === 'm' || offer.offer.status === 'o'));
					data.push(qK); //Cereri cu status comandat 
					data2.push(qC); // Cereri cu status respins
					data3.push(qO); // Cereri deschise 
					data4.push(qK + qC + qO);// total cereri
				});
				vueInst.loadedData = true;
				vueInst.loadingOffersQuantity = false;
				vueInst.chartDataOffersQuantity = {
					labels : labels,
					datasets: [
						{
							label: this.$t('message.request_ordered'),
							data: data,
							backgroundColor: '#E97132',
							borderColor: '#E97132',
							tension: 0.5,
							pointRadius: 16,
							datalabels: {
								color: '#ffffff'
							}
						},
						{
							label: this.$t('message.request_canceled'),
							data: data2,
							backgroundColor: '#156082',
							borderColor: '#156082',
							tension: 0.5,
							pointRadius: 16,
							datalabels: {
								color: '#ffffff'
							}
						},
						{
							label: this.$t('message.request_opened'),
							data: data3,
							backgroundColor: '#196B14',
							borderColor: '#196B14',
							tension: 0.5,
							pointRadius: 16,
							datalabels: {
								color: '#ffffff'
							}
						},
						{
							label: this.$t('message.request_total'),
							data: data4,
							backgroundColor: '#0F9ED5',
							borderColor: '#0F9ED5',
							tension: 0.5,
							pointRadius: 16,
							datalabels: {
								color: '#ffffff'
							}
						}
					],
				}
				vueInst.$q.loading.hide();
			}
		});

	}  
}