import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import {CONFIG_ENV} from '@/config';
import {ServiceAdminNomProducts} from '@/services/ServiceAdminNomProducts';
import user from '@/store/user';
import eventbus from '@/store/eventbus';
import nomenclatoare from '@/store/nomenclatoare';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TArticle} from "@/types/TArticle";
import EditArticle from "@/components/Admin/EditArticle/EditArticle.vue";
import {TOptionCategory} from "@/types/TOptionCategory";




@Options({
    name: "AdminArticles",
    components: {EditArticle}
})
export default class AdminArticles extends Vue {
    public inputSearchArticle='';
    private currentPageNumber=1;
    private maxPagesNumber=1;
    private totalItems=0;
    public filterText = '';
    declare public $refs: any;
    public dialogTransitionShow ='';
    public dialogTransitionHide ='';
    public userStore = getModule(user);
    public EventBusStore = getModule(eventbus);
    public isShoDialogEditArticle=false;
    public selectedPidArticle='';
    public selectedProductCode = '';
    public arrArticles: TArticle[]=[];
    public nomenclatoareStore = getModule(nomenclatoare);
    public loadingApollo = false;

    get user(): TUser {
        return this.userStore.user;
    }

    private get optionsCategories():TOptionCategory[]{
        return this.nomenclatoareStore.optionsCategories;
    }

    onOpenFormAddNewArticle(){
        this.selectedPidArticle='0';
        console.log('isAdminArticlesActivated=%o',this.isAdminArticlesActivated)
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        this.isShoDialogEditArticle=true  && this.isAdminArticlesActivated;
    }

    inputPaginator(params:number){
        this.getPageWithArticlesFromDB(params);
    }

    closeFormEditArticle(needRefresh:boolean){
        const vueInst=this;
        vueInst.isShoDialogEditArticle=false;
        if(needRefresh){
            vueInst.getPageWithArticlesFromDB(vueInst.currentPageNumber);
        }
    }

    openProductForEditing(particle:TArticle){
        const vueInst=this;
        //alert('2optiune dezactivata, nu cred ca trebuie sa se poata edita din Portal')
        vueInst.isShoDialogEditArticle=true;
        vueInst.selectedPidArticle=particle.pid;
        vueInst.selectedProductCode=particle.code;
    }

    changeStatus2(newValue:string, evt:any){
        console.log('changeStatus with newValue=%o for evt=%o',newValue,evt)
    }

    changeStatus(particle: TArticle){
        const vueInst=this;
        let newStatus='x';
        if(typeof(particle.isActive) === 'string'){
            if(particle.isActive=='y'){newStatus='y';}
            if(particle.isActive=='n'){newStatus='n';}
        }
        if(typeof(particle.isActive) === 'boolean'){
            if(particle.isActive){newStatus='y';}else{newStatus='n';}
        }

        ServiceAdminNomProducts.changeStatusActivInactiv(particle.pid,newStatus).then(response=>{
            vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                icon: 'positive',
                position: 'top',
                timeout: 2000,
                message: response.message
            })
        })
    }

    public getPageWithArticlesFromDB(pageNumber:number){
        const vueInst=this;
        ServiceAdminNomProducts.getNomProductsData(pageNumber,vueInst.filterText).then(response=>{
            if(response.status=='success'){
                vueInst.arrArticles=[];
                vueInst.currentPageNumber=pageNumber;
                vueInst.maxPagesNumber=response.totalPages;
                vueInst.totalItems=response.totalItems;
                response.products.forEach(product=>{
                    const lantHierarchyCategory=vueInst.nomenclatoareStore.optionsCategories.find(category=>{
                        return category.pid==product.categoryPid;
                    });
                    console.log('found lantHierarchyCategory=%o',lantHierarchyCategory)
                    vueInst.arrArticles.push({
                        appid:product.appid,
                        pid:product.pid,
                        categoryPid:product.categoryPid,
                        lantHierarchyCategoriesRo: (lantHierarchyCategory!== undefined?lantHierarchyCategory.lantHierarchyCategoriesRo:'xxx'),
                        lantHierarchyCategoriesEn: (lantHierarchyCategory!== undefined?lantHierarchyCategory.lantHierarchyCategoriesEn:'xxx'),
                        lantHierarchyCategoriesHu: (lantHierarchyCategory!== undefined?lantHierarchyCategory.lantHierarchyCategoriesHu:'xxx'),
                        code: product.code,
                        name_ro:product.name_ro,
                        name_en:product.name_en,
                        name_hu:product.name_hu,
                        UMBase:product.UMBase,
                        um1: product.um1,
                        um2: product.um2,
                        um1ToUm2: product.um1ToUm2,
                        isActive:product.isActive,
                        withLength: product.withLength,
                        withWidth: product.withWidth,
                        withThickness: product.withThickness,
                        withDiameter: product.withDiameter,
                        withHeight:product.withHeight,
                        withAlloy:product.withAlloy,
                        withEyes:product.withEyes,
                        withEyes2:product.withEyes2,
                        sizeLength:product.sizeLength,
                        sizeWidth:product.sizeWidth,
                        sizeThickness:product.sizeThickness,
                        sizeDiameter:product.sizeDiameter,
                        sizeHeight:product.sizeHeight,
                        sizeAlloy:product.sizeAlloy,
                        sizeEyes:product.sizeEyes,
                        sizeEyes2: product.sizeEyes2,
                        name_de: product.name_de,
                        lantHierarchyCategoriesDe: (lantHierarchyCategory!== undefined?lantHierarchyCategory.lantHierarchyCategoriesDe:'xxx')
                    });
                });
            }
        });
    }

    public get isAdminArticlesActivated():boolean{
        return this.$route.name=='AdminArticles';
    }

    public syncronizeApollo() {
        const vueInst = this;
        vueInst.loadingApollo = true;
        ServiceAdminNomProducts.getArticlesApollo().then(() => {
            vueInst.loadingApollo = false;
            vueInst.getPageWithArticlesFromDB(1);
        }).catch(()=>{
            vueInst.loadingApollo = false;
        });
    }

    @Watch('EventBusStore.event.eventId')
    onEventIdChanged() {
        const vueInst=this;
        if(vueInst.EventBusStore.event.name=='eventClickAddBtn'){
            if(vueInst.isAdminArticlesActivated){
                vueInst.onOpenFormAddNewArticle();
            }
        }
    }

    @Watch('currentPageNumber')
    onCurrentPageNumberChanged() {
        const vueInst=this;
        vueInst.getPageWithArticlesFromDB(vueInst.currentPageNumber);
    }

    @Watch('filterText')
    onFilterTextChanged() {
        const vueInst=this;
        vueInst.getPageWithArticlesFromDB(vueInst.currentPageNumber);
    }

    public created() {
        const vueInst=this;
        console.log(this.$route.name)
        this.getPageWithArticlesFromDB(1);
    }

    public activated(){
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar(this.$t('message.manage_items'));
        }
        this.getPageWithArticlesFromDB(1);
    }

}

