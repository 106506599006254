import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65c73097"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"1vh"} }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { style: {"display":"flex","justify-content":"flex-end"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_form, {
        onSubmit: _ctx.onSubmit,
        onReset: _ctx.onReset,
        class: "form__new__user"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_input, {
            outlined: "",
            disable: _ctx.isEditUser,
            modelValue: _ctx.user.userid,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.userid) = $event)),
            label: _ctx.$t('message.user_id'),
            hint: _ctx.$t('message.usage_user_id'),
            class: "form__input",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnFirstName, ["prevent"]), ["enter"]),
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field'), val => val.match(/^[a-zA-Z0-9_]*$/) || this.$t('message.invalid_user_id_format')]
          }, null, 8, ["disable", "modelValue", "label", "hint", "onKeydown", "rules"]),
          _createVNode(_component_q_input, {
            outlined: "",
            ref: "refFirstName",
            modelValue: _ctx.user.firstName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.firstName) = $event)),
            label: _ctx.$t('message.first_name'),
            class: "form__input",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnLastName, ["prevent"]), ["enter"]),
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
          }, null, 8, ["modelValue", "label", "onKeydown", "rules"]),
          _createVNode(_component_q_input, {
            outlined: "",
            ref: "refLastName",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnEmail, ["prevent"]), ["enter"]),
            modelValue: _ctx.user.lastName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.lastName) = $event)),
            label: _ctx.$t('message.last_name'),
            class: "form__input",
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
          }, null, 8, ["onKeydown", "modelValue", "label", "rules"]),
          _createVNode(_component_q_input, {
            outlined: "",
            ref: "refEmail",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnPhoneNr, ["prevent"]), ["enter"]),
            modelValue: _ctx.user.emailAddress,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.emailAddress) = $event)),
            type: "email",
            label: _ctx.$t('message.email'),
            class: "form__input",
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
          }, null, 8, ["onKeydown", "modelValue", "label", "rules"]),
          _createVNode(_component_q_input, {
            outlined: "",
            ref: "refPhoneNr",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnPhoneNr, ["prevent"]), ["enter"]),
            modelValue: _ctx.user.phoneNr,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.phoneNr) = $event)),
            label: _ctx.$t('message.phone'),
            class: "form__input",
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
          }, null, 8, ["onKeydown", "modelValue", "label", "rules"]),
          _createVNode(_component_q_select, {
            outlined: "",
            "use-input": "",
            modelValue: _ctx.userType,
            "onUpdate:modelValue": [
              _cache[5] || (_cache[5] = ($event: any) => ((_ctx.userType) = $event)),
              _cache[6] || (_cache[6] = val => _ctx.updateType(val))
            ],
            options: _ctx.userTypesOptions,
            label: _ctx.$t('message.type'),
            "option-value": "id",
            "option-label": _ctx.$i18n.locale == 'de' ? 'user_type_de' : _ctx.$i18n.locale == 'ro' ? 'user_type_ro' : _ctx.$i18n.locale == 'en' ? 'user_type_en' : 'user_type_hu',
            class: "form__input",
            "lazy-rules": "",
            "emit-value": "",
            "map-options": ""
          }, null, 8, ["modelValue", "options", "label", "option-label"]),
          (_ctx.user.functie=='2' || _ctx.user.functie=='3')
            ? (_openBlock(), _createBlock(_component_q_input, {
                key: 0,
                outlined: "",
                ref: "refAgentId",
                onKeydown: _withKeys(_withModifiers(_ctx.focusOnAgentId, ["prevent"]), ["enter"]),
                modelValue: _ctx.user.agentId,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.user.agentId) = $event)),
                type: "text",
                label: _ctx.$t('message.agent_id'),
                class: "form__input",
                "lazy-rules": "",
                rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
              }, null, 8, ["onKeydown", "modelValue", "label", "rules"]))
            : _createCommentVNode("", true),
          (_ctx.user.functie != '1')
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 1,
                outlined: "",
                modelValue: _ctx.userTeam,
                "onUpdate:modelValue": [
                  _cache[8] || (_cache[8] = ($event: any) => ((_ctx.userTeam) = $event)),
                  _cache[9] || (_cache[9] = val => _ctx.updateTeam(val))
                ],
                options: _ctx.salesTeamsOptions,
                label: _ctx.$t('message.sales_team_id'),
                "option-value": "id_team",
                "option-label": "team_name",
                class: "form__input",
                "lazy-rules": "",
                "emit-value": "",
                "map-options": "",
                rules: [ val => ((val && val.length > 0) || _ctx.user.functie == '3')   || _ctx.$t('message.enter_field')]
              }, null, 8, ["modelValue", "options", "label", "rules"]))
            : _createCommentVNode("", true),
          (_ctx.user.functie == '4')
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 2,
                outlined: "",
                ref: "refCompany",
                label: _ctx.$t('message.company'),
                class: "form__input",
                modelValue: _ctx.user.companyCif,
                "onUpdate:modelValue": [
                  _cache[10] || (_cache[10] = ($event: any) => ((_ctx.user.companyCif) = $event)),
                  _cache[11] || (_cache[11] = val => _ctx.getCompanyByCif(val))
                ],
                "option-value": "cif",
                "option-label": "name",
                clearable: "",
                "use-input": "",
                "emit-value": "",
                "map-options": "",
                "input-debounce": "500",
                options: _ctx.optionsCompanies,
                onFilter: _ctx.filterCompanies
              }, {
                option: _withCtx((scope) => [
                  _createVNode(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_label, {
                            innerHTML: scope.opt.cif
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_label, {
                            innerHTML: scope.opt.name
                          }, null, 8, ["innerHTML"]),
                          _createVNode(_component_q_item_label, { caption: "" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(scope.opt.code), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1040)
                ]),
                _: 1
              }, 8, ["label", "modelValue", "options", "onFilter"]))
            : _createCommentVNode("", true),
          (_ctx.user.functie=='2' || _ctx.user.functie=='4')
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 3,
                outlined: "",
                ref: "refAssociatedAgent",
                label: (_ctx.user.functie=='4') ? _ctx.$t('message.agent') : _ctx.$t('message.agent_sef'),
                class: "form__input",
                modelValue: _ctx.user.associated_agent,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.user.associated_agent) = $event)),
                "option-value": "agentId",
                "option-label": _ctx.combinedLabel,
                clearable: "",
                "use-input": "",
                "emit-value": "",
                "map-options": "",
                "input-debounce": "500",
                options: _ctx.agents,
                rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
              }, {
                option: _withCtx((scope) => [
                  _createVNode(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_label, {
                            innerHTML: scope.opt.agentId
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_label, {
                            innerHTML: scope.opt.lastName
                          }, null, 8, ["innerHTML"]),
                          _createVNode(_component_q_item_label, { caption: "" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(scope.opt.firstName), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1040)
                ]),
                _: 1
              }, 8, ["label", "modelValue", "option-label", "options", "rules"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_btn, {
              label: _ctx.$t('message.reset'),
              type: "reset",
              color: "primary",
              flat: "",
              class: "q-ml-sm",
              "no-caps": ""
            }, null, 8, ["label"]),
            _createVNode(_component_q_btn, {
              label: _ctx.$t('message.save'),
              type: "submit",
              color: "primary",
              "no-caps": "",
              "text-color": "white"
            }, null, 8, ["label"])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit", "onReset"])
    ])
  ]))
}