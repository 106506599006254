import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a7615636"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "q-pa-md q-gutter-sm",
  style: {"display":"flex"}
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "breadcrumb__category" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showBrowseCategoriesIcon)
      ? (_openBlock(), _createBlock(_component_q_icon, {
          key: 0,
          name: "arrow_back",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onGoToBrowseCategories(_ctx.pid))),
          class: "cursor-pointer",
          color: "primary",
          style: {"font-size":"2rem"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tooltip, {
              "transition-show": "scale",
              "transition-hide": "scale",
              anchor: "center left",
              self: "center right",
              offset: [10, 10]
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('message.go_to_categories')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showInputFastSearch)
      ? (_openBlock(), _createBlock(_component_q_input, {
          key: 1,
          outlined: "",
          modelValue: _ctx.inputFastSearch,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inputFastSearch) = $event)),
          dense: "",
          style: {"flex-grow":"1","padding":"5px"},
          ref: "refInputFastSearch"
        }, {
          append: _withCtx(() => [
            (_ctx.inputFastSearch !== '')
              ? (_openBlock(), _createBlock(_component_q_icon, {
                  key: 0,
                  name: "close",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputFastSearch = '')),
                  class: "cursor-pointer"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_q_icon, { name: "search" })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hierarchicalChain, (category) => {
        return (_openBlock(), _createElementBlock("div", {
          key: category.categoryPid,
          onClick: ($event: any) => (_ctx.onGoToBrowseCategories(category.categoryPid)),
          class: "breadcrumb__category"
        }, [
          _createVNode(_component_q_icon, { name: "arrow_right_alt" }),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$i18n.locale == 'de' ? category.categoryNameDe : _ctx.$i18n.locale == 'ro' ? category.categoryNameRo : _ctx.$i18n.locale == 'en' ? category.categoryNameEn : category.categoryNameHu), 1)
        ], 8, _hoisted_2))
      }), 128))
    ])
  ]))
}