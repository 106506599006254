import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e832297c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"1vh"} }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row justify-start" }
const _hoisted_4 = { class: "col-3 app__label--medium" }
const _hoisted_5 = { class: "col-9 app__property--medium" }
const _hoisted_6 = { class: "row justify-start" }
const _hoisted_7 = { class: "col-3 app__label--medium" }
const _hoisted_8 = { class: "col-9 app__property--medium" }
const _hoisted_9 = { class: "row justify-start" }
const _hoisted_10 = { class: "col-3 app__label--medium" }
const _hoisted_11 = { class: "col-9 app__property--medium" }
const _hoisted_12 = { class: "row justify-start" }
const _hoisted_13 = { class: "col-3 app__label--medium" }
const _hoisted_14 = { class: "col-9 app__property--medium" }
const _hoisted_15 = { class: "row justify-start" }
const _hoisted_16 = { class: "col-3 app__label--medium" }
const _hoisted_17 = { class: "col-9 app__property--medium" }
const _hoisted_18 = { class: "row justify-start" }
const _hoisted_19 = { class: "col-3 app__label--medium" }
const _hoisted_20 = { class: "col-9 app__property--medium" }
const _hoisted_21 = {
  class: "row justify-start",
  style: {"margin-top":"16px"}
}
const _hoisted_22 = { class: "col-3 app__label--medium" }
const _hoisted_23 = { class: "col-9" }
const _hoisted_24 = {
  class: "row justify-start",
  style: {"gap":"4px"}
}
const _hoisted_25 = { class: "row justify-start" }
const _hoisted_26 = { class: "col-3 app__label--medium" }
const _hoisted_27 = { class: "col-9" }
const _hoisted_28 = {
  class: "row justify-start",
  style: {"gap":"4px"}
}
const _hoisted_29 = { class: "col-auto" }
const _hoisted_30 = { class: "col-auto" }
const _hoisted_31 = { class: "col-auto" }
const _hoisted_32 = { class: "col-auto" }
const _hoisted_33 = { class: "col-auto" }
const _hoisted_34 = { class: "col-auto" }
const _hoisted_35 = { class: "col-auto" }
const _hoisted_36 = { class: "row justify-start" }
const _hoisted_37 = { class: "col-3 app__label--medium" }
const _hoisted_38 = { class: "col-9 app__property--medium" }
const _hoisted_39 = { class: "row justify-start" }
const _hoisted_40 = { class: "col-3 app__label--medium" }
const _hoisted_41 = { class: "col-9 app__property--medium" }
const _hoisted_42 = { style: {"display":"flex","justify-content":"flex-end"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_form, {
        onSubmit: _ctx.onSubmit,
        onReset: _ctx.onReset,
        class: "form__edit__article"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('message.extern_id')), 1),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_q_input, {
                outlined: "",
                dense: "",
                modelValue: _ctx.article.code,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.article.code) = $event)),
                class: "form__input",
                label: "",
                onKeydown: _withKeys(_withModifiers(_ctx.focusOnArticleName, ["prevent"]), ["enter"]),
                "lazy-rules": "",
                rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
              }, _createSlots({ _: 2 }, [
                (_ctx.$q.platform.is.mobile)
                  ? {
                      name: "label",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('message.extern_id')), 1)
                      ])
                    }
                  : undefined
              ]), 1032, ["modelValue", "onKeydown", "rules"])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('message.name2')) + " RO", 1),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_q_input, {
                outlined: "",
                dense: "",
                modelValue: _ctx.article.name_ro,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.article.name_ro) = $event)),
                class: "form__input",
                ref: "refArticleName",
                label: "",
                onKeydown: _withKeys(_withModifiers(_ctx.focusOnCategorySelection, ["prevent"]), ["enter"]),
                "lazy-rules": "",
                rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
              }, _createSlots({ _: 2 }, [
                (_ctx.$q.platform.is.mobile)
                  ? {
                      name: "label",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('message.name2')), 1)
                      ])
                    }
                  : undefined
              ]), 1032, ["modelValue", "onKeydown", "rules"])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('message.name2')) + " EN", 1),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_q_input, {
                outlined: "",
                dense: "",
                modelValue: _ctx.article.name_en,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.article.name_en) = $event)),
                class: "form__input",
                ref: "refArticleName",
                label: "",
                onKeydown: _withKeys(_withModifiers(_ctx.focusOnCategorySelection, ["prevent"]), ["enter"]),
                "lazy-rules": "",
                rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
              }, _createSlots({ _: 2 }, [
                (_ctx.$q.platform.is.mobile)
                  ? {
                      name: "label",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('message.name2')), 1)
                      ])
                    }
                  : undefined
              ]), 1032, ["modelValue", "onKeydown", "rules"])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('message.name2')) + " HU", 1),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_q_input, {
                outlined: "",
                dense: "",
                modelValue: _ctx.article.name_hu,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.article.name_hu) = $event)),
                class: "form__input",
                ref: "refArticleName",
                onKeydown: _withKeys(_withModifiers(_ctx.focusOnCategorySelection, ["prevent"]), ["enter"]),
                "lazy-rules": "",
                rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
              }, _createSlots({ _: 2 }, [
                (_ctx.$q.platform.is.mobile)
                  ? {
                      name: "label",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('message.name2')), 1)
                      ])
                    }
                  : undefined
              ]), 1032, ["modelValue", "onKeydown", "rules"])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('message.name2')) + " DE", 1),
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_q_input, {
                outlined: "",
                dense: "",
                modelValue: _ctx.article.name_de,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.article.name_de) = $event)),
                class: "form__input",
                ref: "refArticleName",
                label: "",
                onKeydown: _withKeys(_withModifiers(_ctx.focusOnCategorySelection, ["prevent"]), ["enter"]),
                "lazy-rules": "",
                rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
              }, _createSlots({ _: 2 }, [
                (_ctx.$q.platform.is.mobile)
                  ? {
                      name: "label",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('message.name2')), 1)
                      ])
                    }
                  : undefined
              ]), 1032, ["modelValue", "onKeydown", "rules"])
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('message.category')), 1),
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_q_select, {
                outlined: "",
                dense: "",
                ref: "refArticleCategorySelection",
                modelValue: _ctx.article.categoryPid,
                "onUpdate:modelValue": [
                  _cache[5] || (_cache[5] = ($event: any) => ((_ctx.article.categoryPid) = $event)),
                  _ctx.getCategoryDetailsAndSetSizes
                ],
                "fill-input": true,
                "emit-value": true,
                "map-options": true,
                "option-value": "pid",
                "option-label": _ctx.$i18n.locale == 'de' ? 'lantHierarchyCategoriesDe' : _ctx.$i18n.locale == 'ro' ? 'lantHierarchyCategoriesRo' : _ctx.$i18n.locale == 'en' ? 'lantHierarchyCategoriesEn' : 'lantHierarchyCategoriesHu',
                label: "",
                options: _ctx.filteredOptionsCategories,
                onFilter: _ctx.filterFnCategories,
                style: {"max-width":"80vw"}
              }, _createSlots({
                "no-option": _withCtx(() => [
                  _createVNode(_component_q_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, { class: "text-grey" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('message.no_data')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, [
                (_ctx.$q.platform.is.mobile)
                  ? {
                      name: "label",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('message.category')), 1)
                      ])
                    }
                  : undefined
              ]), 1032, ["modelValue", "option-label", "onUpdate:modelValue", "options", "onFilter"])
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('message.measure_units')), 1),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_q_select, {
                  class: "form__input--sizes",
                  outlined: "",
                  dense: "",
                  modelValue: _ctx.article.UMBase,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.article.UMBase) = $event)),
                  options: _ctx.optionsUM,
                  label: _ctx.$t('message.um1'),
                  style: {"width":"100px"}
                }, null, 8, ["modelValue", "options", "label"]),
                _createVNode(_component_q_input, {
                  outlined: "",
                  dense: "",
                  ref: "refUM2",
                  modelValue: _ctx.article.um2,
                  "onUpdate:modelValue": [
                    _cache[7] || (_cache[7] = ($event: any) => ((_ctx.article.um2) = $event)),
                    _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateUM()))
                  ],
                  label: _ctx.$t('message.um2'),
                  class: "form__input--sizes"
                }, null, 8, ["modelValue", "label"]),
                _createVNode(_component_q_input, {
                  outlined: "",
                  dense: "",
                  disable: "",
                  ref: "refThickness",
                  modelValue: _ctx.article.um1ToUm2,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.article.um1ToUm2) = $event)),
                  label: _ctx.$t('message.um1_to_um2'),
                  class: "form__input--sizes"
                }, null, 8, ["modelValue", "label"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t('message.configuration')), 1),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    ref: "refLength",
                    disable: !_ctx.article.withLength,
                    modelValue: _ctx.article.sizeLength,
                    "onUpdate:modelValue": [
                      _cache[10] || (_cache[10] = ($event: any) => ((_ctx.article.sizeLength) = $event)),
                      _cache[11] || (_cache[11] = ($event: any) => (_ctx.updateUM()))
                    ],
                    label: _ctx.$t('message.length') + (_ctx.article.UMBase == 'M' ? '(M)' : '(mm)'),
                    class: "form__input--sizes"
                  }, null, 8, ["disable", "modelValue", "label"])
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    disable: !_ctx.article.withWidth,
                    ref: "refWidth",
                    modelValue: _ctx.article.sizeWidth,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.article.sizeWidth) = $event)),
                    label: _ctx.$t('message.width') + '(mm)',
                    class: "form__input--sizes"
                  }, null, 8, ["disable", "modelValue", "label"])
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    disable: !_ctx.article.withThickness,
                    ref: "refThickness",
                    modelValue: _ctx.article.sizeThickness,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.article.sizeThickness) = $event)),
                    label: _ctx.$t('message.thickness') + '(mm)',
                    class: "form__input--sizes"
                  }, null, 8, ["disable", "modelValue", "label"])
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    disable: !_ctx.article.withDiameter,
                    ref: "refDiameter",
                    modelValue: _ctx.article.sizeDiameter,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.article.sizeDiameter) = $event)),
                    label: _ctx.$t('message.diameter') + '(mm)',
                    class: "form__input--sizes"
                  }, null, 8, ["disable", "modelValue", "label"])
                ]),
                _createElementVNode("div", _hoisted_33, [
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    disable: !_ctx.article.withHeight,
                    ref: "refHeight",
                    modelValue: _ctx.article.sizeHeight,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.article.sizeHeight) = $event)),
                    label: _ctx.$t('message.height') + '(mm)',
                    class: "form__input--sizes"
                  }, null, 8, ["disable", "modelValue", "label"])
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    disable: !_ctx.article.withEyes,
                    ref: "refType",
                    modelValue: _ctx.article.sizeEyes,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.article.sizeEyes) = $event)),
                    label: _ctx.$t('message.eyes') + '(mm)',
                    class: "form__input--sizes"
                  }, null, 8, ["disable", "modelValue", "label"])
                ]),
                _createElementVNode("div", _hoisted_35, [
                  _createVNode(_component_q_input, {
                    outlined: "",
                    dense: "",
                    disable: !_ctx.article.withEyes2,
                    ref: "refType",
                    modelValue: _ctx.article.sizeEyes2,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.article.sizeEyes2) = $event)),
                    label: _ctx.$t('message.eyes') + ' 2(mm)',
                    class: "form__input--sizes"
                  }, null, 8, ["disable", "modelValue", "label"])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.$t('message.aliaj')), 1),
            _createElementVNode("div", _hoisted_38, [
              _createVNode(_component_q_input, {
                outlined: "",
                dense: "",
                modelValue: _ctx.article.sizeAlloy,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.article.sizeAlloy) = $event)),
                class: "form__input",
                ref: "refArticleAliaj",
                label: "",
                disable: !_ctx.article.withAlloy,
                onKeydown: _withKeys(_withModifiers(_ctx.focusOnArticleType, ["prevent"]), ["enter"]),
                "lazy-rules": "",
                rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
              }, _createSlots({ _: 2 }, [
                (_ctx.$q.platform.is.mobile)
                  ? {
                      name: "label",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('message.aliaj')), 1)
                      ])
                    }
                  : undefined
              ]), 1032, ["modelValue", "disable", "onKeydown", "rules"])
            ])
          ]),
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.$t('message.client_visible')), 1),
            _createElementVNode("div", _hoisted_41, [
              _createVNode(_component_q_toggle, {
                modelValue: _ctx.article.isActive,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.article.isActive) = $event)),
                "true-value": true,
                "false-value": false
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_42, [
            _createVNode(_component_q_btn, {
              label: _ctx.$t('message.reset'),
              type: "reset",
              color: "primary",
              flat: "",
              disable: "",
              class: "q-ml-sm",
              "no-caps": ""
            }, null, 8, ["label"]),
            _createVNode(_component_q_btn, {
              label: _ctx.$t('message.save'),
              type: "submit",
              color: "primary",
              "no-caps": "",
              "text-color": "white"
            }, null, 8, ["label"])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit", "onReset"])
    ])
  ]))
}