import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import draggable from "vuedraggable";
import {CONFIG_ENV} from '@/config';
import {ServiceAdminNomCategory} from '@/services/ServiceAdminNomCategory';
import user from '@/store/user';
import nomenclatoare from '@/store/nomenclatoare';
import eventbus from '@/store/eventbus';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TCategory} from "@/types/TCategory";
import EditArticle from "*.vue";
import {TValueLabel} from "@/types/TValueLabel";
import {TDimensionsOrder} from "@/types/TDimensionsOrder";
import {EnumUM} from "@/types/EnumUM";
import {TUm1Um2} from "@/types/TUm1Um2";

@Options({
    name: "AdminCategory",
    components: {draggable}
})
export default class AdminCategory extends Vue {
    public inputSearchArticle='';
    public isAdminCategoryActivated=false;
    declare public $refs: any;
    public userStore = getModule(user);
    public storeNomenclatoare = getModule(nomenclatoare);
    public EventBusStore = getModule(eventbus);
    public filter = '';
    public isOpenDialogCategory=false;
    public dialogTransitionShow='';
    public dialogTransitionHide='';
    public ImgCategoryString64='';
    public filesSelectedForUpload:any= [];
    public label_editing_category_ro='';
    public label_editing_category_en='';
    public label_editing_category_hu='';
    public label_editing_category_de='';
    public editingCategory:TCategory&TDimensionsOrder&TUm1Um2&{img:any|null,parent_name_ro:string|null,parent_name_en:string|null,parent_name_hu:string|null,parent_name_de:string|null,hasChilds:string}={
        appid:'',
        pid:'',
        name_ro:'',
        name_en:'',
        name_hu:'',
        name_de:'',
        parent_pid:'',
        parent_name_ro:'',
        parent_name_en:'',
        parent_name_hu:'',
        parent_name_de:'',
        category_level:'',
        children:null,
        img:null,
        withLength: '',
        withWidth: '',
        withThickness: '',
        withDiameter: '',
        withHeight: '',
        withAlloy: '',
        withEyes: '',
        withEyes2: '',
        is_parent_for_articles: 'n',
        hasChilds:'n',
        positionLength: 0,
        positionWidth: 0,
        positionThickness: 0,
        positionDiameter: 0,
        positionHeight: 0,
        positionAlloy: 0,
        positionEyes: 0,
        positionEyes2: 0,
        um1:'x',
        um2:'x',
        ord:0
    }
    public treeDataCategories:TCategory[]=[];

    public listDimensionsNotUsed:TValueLabel[]= [];
    public listDimensionsUsed:TValueLabel[]= [];
    public selected = null;
    public orderChanged = false;

    /*
    public treeDataCategories:TCategory[]=[{appid:'1'
                                    ,name:'Aluminiu'
                                    ,category_level:'1'
                                    ,children:[{appid:'2',name:'Table subtiri',category_level:'2',children:[]}
                                              ,{appid:'3',name:'Profile',category_level:'2',children:[{appid:'31',name:'Profile L',category_level:'3',children:[]}
                                                                                                      ,{appid:'32',name:'Profile T',category_level:'3',children:[]}
                                                                                                      ,{appid:'33',name:'Profile U',category_level:'3',children:[]}]}
                                                ]
                                    },
                                    {appid:'10'
                                        ,name:'Cupru'
                                        ,category_level:'1'
                                        ,children:[{appid:'11',name:'Placi Cupru',category_level:'2',children:[]}
                                                  ,{appid:'12',name:'Bare Cupru',category_level:'2',children:[]}
                                        ]
                                    },
                                    {appid:'20'
                                        ,name:'Alama'
                                        ,category_level:'1'
                                        ,children:[]
                                    }];
*/

    get user(): TUser {
        return this.userStore.user;
    }

    get optionsUM(): EnumUM[] {
        return this.storeNomenclatoare.optionsUM;
    }

    resetFilter(){
        this.filter='';
    }

    openFormAddNewCategory(parent_pid: string, parent_name_ro:string, parent_name_en:string, parent_name_hu:string, parent_name_de:string){
        const vueInst=this;
        vueInst.onResetFormCategory();
        vueInst.listDimensionsNotUsed = [
            { label: this.$t('message.length'), value: 'l' },
            { label: this.$t('message.width'), value: 'w' },
            { label: this.$t('message.thickness'), value: 't' },
            { label: this.$t('message.diameter'), value: 'd' },
            { label: this.$t('message.height'), value: 'h' },
            { label: this.$t('message.aliaj'), value: 'a' },
            { label: this.$t('message.eye'), value: 'k' }
        ];
        vueInst.listDimensionsUsed=[];
        vueInst.isOpenDialogCategory=true && vueInst.isAdminCategoryActivated;
        vueInst.editingCategory.parent_pid=parent_pid;
        vueInst.editingCategory.parent_name_ro=parent_name_ro;
        vueInst.editingCategory.parent_name_en=parent_name_en;
        vueInst.editingCategory.parent_name_hu=parent_name_hu;
        vueInst.editingCategory.parent_name_de=parent_name_de;
        if(parent_pid && parent_pid.toString().length>0){
            vueInst.label_editing_category_ro=this.$t('message.subcategory_name') + ' RO';
            vueInst.label_editing_category_en=this.$t('message.subcategory_name') + ' EN';
            vueInst.label_editing_category_hu=this.$t('message.subcategory_name') + ' HU';
            vueInst.label_editing_category_de=this.$t('message.subcategory_name') + ' DE';
        }else{
            vueInst.label_editing_category_ro=this.$t('message.category_name') + ' RO';
            vueInst.label_editing_category_en=this.$t('message.category_name') + ' EN';
            vueInst.label_editing_category_hu=this.$t('message.category_name') + ' HU';
            vueInst.label_editing_category_de=this.$t('message.category_name') + ' DE';
        }
    }

    openForModifyCategory(pid:string){
        const vueInst=this;
        const orderedDimensions:TValueLabel[]=[];
        vueInst.isOpenDialogCategory=true;
        vueInst.onResetFormCategory();
        vueInst.listDimensionsNotUsed= [];
        vueInst.listDimensionsUsed=[];
        ServiceAdminNomCategory.getCategory(pid).then(response=>{
            if(response.status=='success'){
                vueInst.editingCategory.pid=pid;
                vueInst.editingCategory.name_ro=response.category.name_ro;
                vueInst.editingCategory.name_en=response.category.name_en;
                vueInst.editingCategory.name_hu=response.category.name_hu;
                vueInst.editingCategory.name_de=response.category.name_de;
                vueInst.editingCategory.parent_pid=response.category.parent_pid;
                vueInst.editingCategory.parent_name_ro=response.category.parent_name_ro;
                vueInst.editingCategory.parent_name_en=response.category.parent_name_en;
                vueInst.editingCategory.parent_name_hu=response.category.parent_name_hu;
                vueInst.editingCategory.parent_name_de=response.category.parent_name_de;
                vueInst.editingCategory.withLength=response.category.withLength;
                vueInst.editingCategory.withWidth=response.category.withWidth;
                vueInst.editingCategory.withThickness=response.category.withThickness;
                vueInst.editingCategory.withDiameter=response.category.withDiameter;
                vueInst.editingCategory.withHeight=response.category.withHeight;
                vueInst.editingCategory.withAlloy=response.category.withAlloy;
                vueInst.editingCategory.withEyes=response.category.withEyes;
                vueInst.editingCategory.withEyes2=response.category.withEyes2;
                vueInst.editingCategory.um1=response.category.um1;
                vueInst.editingCategory.um2=response.category.um2;
                vueInst.editingCategory.positionLength=response.category.positionLength*1;
                vueInst.editingCategory.positionWidth=response.category.positionWidth*1;
                vueInst.editingCategory.positionThickness=response.category.positionThickness*1;
                vueInst.editingCategory.positionDiameter=response.category.positionDiameter*1;
                vueInst.editingCategory.positionHeight=response.category.positionHeight*1;
                vueInst.editingCategory.positionAlloy=response.category.positionAlloy*1;
                vueInst.editingCategory.positionEyes=response.category.positionEyes*1;
                vueInst.editingCategory.positionEyes2=response.category.positionEyes2*1;
                vueInst.editingCategory.is_parent_for_articles=response.category.is_parent_for_articles;
                vueInst.editingCategory.hasChilds=response.category.hasChilds;
                if(vueInst.editingCategory.parent_pid && vueInst.editingCategory.parent_pid.toString().length>0){
                    vueInst.label_editing_category_ro=this.$t('message.subcategory_name') + ' RO';
                    vueInst.label_editing_category_en=this.$t('message.subcategory_name') + ' EN';
                    vueInst.label_editing_category_hu=this.$t('message.subcategory_name') + ' HU';
                    vueInst.label_editing_category_de=this.$t('message.subcategory_name') + ' DE';
                }else{
                    vueInst.label_editing_category_ro=this.$t('message.category_name') + ' RO';
                    vueInst.label_editing_category_en=this.$t('message.category_name') + ' EN';
                    vueInst.label_editing_category_hu=this.$t('message.category_name') + ' HU';
                    vueInst.label_editing_category_de=this.$t('message.category_name') + ' DE';
                }
                if(response.category.withLength=='y' && response.category.positionLength*1>0){orderedDimensions.push({ label: this.$t('message.length'), value: 'l' })}else{vueInst.listDimensionsNotUsed.push({ label: this.$t('message.length'), value: 'l' })}
                if(response.category.withWidth=='y' && response.category.positionWidth*1>0){orderedDimensions.push({ label: this.$t('message.width'), value: 'w' })}else{vueInst.listDimensionsNotUsed.push({ label: this.$t('message.width'), value: 'w' })}
                if(response.category.withThickness=='y' && response.category.positionThickness*1>0){orderedDimensions.push({ label: this.$t('message.thickness'), value: 't' })}else{vueInst.listDimensionsNotUsed.push({ label: this.$t('message.thickness'), value: 't' })}
                if(response.category.withDiameter=='y' && response.category.positionDiameter*1>0){orderedDimensions.push({ label: this.$t('message.diameter'), value: 'd' })}else{vueInst.listDimensionsNotUsed.push({ label: this.$t('message.diameter'), value: 'd' })}
                if(response.category.withHeight=='y' && response.category.positionHeight*1>0){orderedDimensions.push({ label: this.$t('message.height'), value: 'h' })}else{vueInst.listDimensionsNotUsed.push({ label: this.$t('message.height'), value: 'h' })}
                if(response.category.withAlloy=='y' && response.category.positionAlloy*1>0){orderedDimensions.push({ label: this.$t('message.aliaj'), value: 'a' })}else{vueInst.listDimensionsNotUsed.push({ label: this.$t('message.aliaj'), value: 'a' })}
                if(response.category.withEyes=='y' && response.category.positionEyes*1>0){orderedDimensions.push({ label: this.$t('message.eyes'), value: 'k' })}else{vueInst.listDimensionsNotUsed.push({ label: this.$t('message.eyes'), value: 'k' })}
                if(response.category.withEyes2=='y' && response.category.positionEyes2*1>0){orderedDimensions.push({ label: this.$t('message.eyes') + ' 2', value: 'e' })}else{vueInst.listDimensionsNotUsed.push({ label: this.$t('message.eyes') + ' 2', value: 'e' })}

                if(response.category.withLength=='y' && response.category.positionLength*1>0){orderedDimensions[response.category.positionLength-1]={ label: this.$t('message.length'), value: 'l' };}
                if(response.category.withWidth=='y' && response.category.positionWidth*1>0){orderedDimensions[response.category.positionWidth-1]={ label: this.$t('message.width'), value: 'w' };}
                if(response.category.withThickness=='y' && response.category.positionThickness*1>0){orderedDimensions[response.category.positionThickness-1]={ label: this.$t('message.thickness'), value: 't' };}
                if(response.category.withDiameter=='y' && response.category.positionDiameter*1>0){orderedDimensions[response.category.positionDiameter-1]={ label: this.$t('message.diameter'), value: 'd' };}
                if(response.category.withHeight=='y' && response.category.positionHeight*1>0){orderedDimensions[response.category.positionHeight-1]={ label: this.$t('message.height'), value: 'h' };}
                if(response.category.withAlloy=='y' && response.category.positionAlloy*1>0){orderedDimensions[response.category.positionAlloy-1]={ label: this.$t('message.aliaj'), value: 'a' };}
                if(response.category.withEyes=='y' && response.category.positionEyes*1>0){orderedDimensions[response.category.positionEyes-1]={ label: this.$t('message.eyes'), value: 'k' };}
                if(response.category.withEyes=='y' && response.category.positionEyes2*1>0){orderedDimensions[response.category.positionEyes2-1]={ label: this.$t('message.eyes') + ' 2', value: 'e' };}
                vueInst.listDimensionsUsed=orderedDimensions;

                ServiceAdminNomCategory.getImageCategoryAsString(pid).then((response:any)=>{
                    //console.log('getImageCategoryAsString with response=%o',response)
                    vueInst.ImgCategoryString64=response;
                })

                console.log(vueInst.editingCategory);
            }
        })
    }
    deleteCategory(pid:string,name:string){
        const vueInst=this;
        vueInst.$q.dialog({
            title: 'Confirm',
            message: `Stergeti categoria ${name}?`,
            cancel: true,
            persistent: true
        }).onOk(() => {
            ServiceAdminNomCategory.deleteCategory(pid).then(response=>{
                if(response.status=='success'){
                    vueInst.$q.notify({
                        color: 'teal',
                        textColor: 'white',
                        position:'top',
                        timeout: 500,
                        message: response.message
                    });
                    vueInst.loadTreeDataCategoriesFromDB();
                }
            })
        })
    }

    imgForCategoryWasAdded(file:any){
        const vueInst=this;
        vueInst.editingCategory.img = file[0];
    }

    public onSubmitFormCategory(){
        const vueInst=this;
        vueInst.$q.loading.show();
        // console.log('vueInst.filesSelectedForUpload=%o',vueInst.filesSelectedForUpload)
        // console.log('onListDimensionsChanged vueInst.listDimensionsUsed=%o',vueInst.listDimensionsUsed)
        vueInst.editingCategory.withLength='n';
        vueInst.editingCategory.withWidth='n';
        vueInst.editingCategory.withThickness='n';
        vueInst.editingCategory.withDiameter='n';
        vueInst.editingCategory.withHeight='n';
        vueInst.editingCategory.withAlloy='n';
        vueInst.editingCategory.withEyes='n';
        vueInst.editingCategory.withEyes2='n';
        vueInst.editingCategory.positionLength=0;
        vueInst.editingCategory.positionWidth=0;
        vueInst.editingCategory.positionThickness=0;
        vueInst.editingCategory.positionDiameter=0;
        vueInst.editingCategory.positionHeight=0;
        vueInst.editingCategory.positionAlloy=0;
        vueInst.editingCategory.positionEyes=0;
        vueInst.editingCategory.positionEyes2=0;
        // console.log('vueInst.editingCategory=%o',vueInst.editingCategory)
        vueInst.listDimensionsUsed.forEach((dimension,index)=>{
            if(dimension.value==='l'){vueInst.editingCategory.withLength='y';vueInst.editingCategory.positionLength=index*1+1;}
            if(dimension.value==='w'){vueInst.editingCategory.withWidth='y';vueInst.editingCategory.positionWidth=index*1+1;}
            if(dimension.value==='t'){vueInst.editingCategory.withThickness='y';vueInst.editingCategory.positionThickness=index*1+1;}
            if(dimension.value==='d'){vueInst.editingCategory.withDiameter='y';vueInst.editingCategory.positionDiameter=index*1+1;}
            if(dimension.value==='h'){vueInst.editingCategory.withHeight='y';vueInst.editingCategory.positionHeight=index*1+1;}
            if(dimension.value==='a'){vueInst.editingCategory.withAlloy='y';vueInst.editingCategory.positionAlloy=index*1+1;}
            if(dimension.value==='k'){vueInst.editingCategory.withEyes='y';vueInst.editingCategory.positionEyes=index*1+1;}
            if(dimension.value==='e'){vueInst.editingCategory.withEyes2='y';vueInst.editingCategory.positionEyes2=index*1+1;}
        })

        ServiceAdminNomCategory.postCategory(vueInst.editingCategory).then(response=>{
            if(response.status=='success'){
                vueInst.$q.notify({
                    color: 'teal',
                    textColor: 'white',
                    position:'top',
                    timeout: 500,
                    message: response.message
                });
                vueInst.isOpenDialogCategory=false;
                vueInst.onResetFormCategory();
                vueInst.loadTreeDataCategoriesFromDB();
                vueInst.$q.loading.hide();
            }
        });
    }

    public onResetFormCategory(){
        this.editingCategory={
            appid:'',
            pid:'',
            name_ro:'',
            name_en:'',
            name_hu:'',
            name_de:'',
            parent_pid:'',
            parent_name_ro:'',
            parent_name_en:'',
            parent_name_hu:'',
            parent_name_de:'',
            category_level:'',
            children:null,
            img:null,
            withLength: '',
            withWidth: '',
            withThickness: '',
            withDiameter: '',
            withHeight: '',
            withAlloy: '',
            withEyes: '',
            withEyes2: '',
            is_parent_for_articles: 'n',
            hasChilds:'n',
            positionLength: 0,
            positionWidth: 0,
            positionThickness: 0,
            positionDiameter: 0,
            positionHeight: 0,
            positionAlloy: 0,
            positionEyes: 0,
            positionEyes2: 0,
            um1:'x',
            um2:'x',
            ord:0
        }
        this.ImgCategoryString64='';
        if(this.$refs.inputImgForCategory){this.$refs.inputImgForCategory.reset();}
    }

    public myFilterMethod (node:TCategory, filter:string) {
        const filt = filter.toLowerCase()
        console.log('filter=%o',filt)
        const f = this.$i18n.locale == 'de' ? node.name_de && node.name_de.toLowerCase().indexOf(filt) > -1 : this.$i18n.locale == 'ro' ? node.name_ro && node.name_ro.toLowerCase().indexOf(filt) > -1 : 
                  this.$i18n.locale == 'en' ? node.name_en && node.name_en.toLowerCase().indexOf(filt) > -1 : node.name_hu && node.name_hu.toLowerCase().indexOf(filt) > -1
        return f;
    }

    public loadTreeDataCategoriesFromDB(){
        const vueInst=this;
        vueInst.$q.loading.show();
        ServiceAdminNomCategory.getNomCategoryTreeData().then(result=>{
            if(result.status=='success'){
                vueInst.treeDataCategories=result.treeDataCategories;
                // vueInst.$refs.refTreeGrid.expandAll();
                vueInst.$q.loading.hide();
            }
        });
    }

//vueInst.EventBusStore.set_event({name:'eventClickAddBtn',params:null})
    @Watch('EventBusStore.event.eventId')
    onEventIdChanged() {
        const vueInst=this;
        if(vueInst.EventBusStore.event.name=='eventClickAddBtn'){
            if(vueInst.isAdminCategoryActivated){
                vueInst.openFormAddNewCategory('','', '', '', '');
            }
        }
    }

    public getIndex(node: TCategory): number {
        const vueInst = this;
        return (vueInst.treeDataCategories.indexOf(node) + 1);
    }

    public moveUP(index: number){
        const vueInst=this;
        if (index > 0) {
            const currentItem = vueInst.treeDataCategories[index];
            currentItem.ord = index-1;
            const newItem = vueInst.treeDataCategories[index-1];
            newItem.ord = index;
            vueInst.treeDataCategories.splice(index, 1,newItem);
            vueInst.treeDataCategories.splice(index-1, 1, currentItem);
            // vueInst.orderChanged = true;
            vueInst.changeOrder(currentItem, newItem);
        }
    }

    public moveDown(index: number){
        const vueInst=this;
        if (index < vueInst.treeDataCategories.length-1) {
            const currentItem = vueInst.treeDataCategories[index];
            currentItem.ord = index+1;
            const newItem = vueInst.treeDataCategories[index+1];
            newItem.ord = index;
            vueInst.treeDataCategories.splice(index, 1,newItem);
            vueInst.treeDataCategories.splice(index+1, 1, currentItem);
            // vueInst.orderChanged = true;
            vueInst.changeOrder(currentItem, newItem);
        }
    }

    public changeOrder(category1: TCategory, category2: TCategory){
        const vueInst=this;
        vueInst.$q.loading.show();
        ServiceAdminNomCategory.changeOrder(category1).then(response => {
            if(response.status=='success'){
                ServiceAdminNomCategory.changeOrder(category2).then(response => {
                    vueInst.$q.loading.hide();
                    if(response.status=='success'){
                        vueInst.$q.notify({
                            color: 'teal',
                            textColor: 'white',
                            position:'top',
                            timeout: 500,
                            message: this.$t('message.order_changed')
                        });
                    }
                });
            } else {
                vueInst.$q.loading.hide();
            }
        });
    }

    public activated(){
        const vueInst=this;
        vueInst.isAdminCategoryActivated=true;
        vueInst.listDimensionsUsed=[];
        vueInst.listDimensionsNotUsed = [
            { label: this.$t('message.length'), value: 'l' },
            { label: this.$t('message.width'), value: 'w' },
            { label: this.$t('message.thickness'), value: 't' },
            { label: this.$t('message.diameter'), value: 'd' },
            { label: this.$t('message.height'), value: 'h' },
            { label: this.$t('message.aliaj'), value: 'a' },
            { label: this.$t('message.eye'), value: 'k' },
            { label: this.$t('message.eye') + ' 2', value: 'e' }
        ];
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar(this.$t('message.manage_categories'));
        }
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-left';
            this.dialogTransitionHide ='slide-right';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        vueInst.loadTreeDataCategoriesFromDB();
    }

    public deactivated() {
        this.isAdminCategoryActivated = false;
    }
}

