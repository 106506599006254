import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import {CONFIG_ENV} from '@/config';
import {ServiceUser} from '@/services/ServiceUser';
import user from '@/store/user';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import { ServiceCerere } from '@/services/ServiceCerere';
import { TCerereHeader } from '@/types/TCerereHeader';
import eventbus from "@/store/eventbus";
import Cerere from '@/pages/Cerere/Cerere.vue';
import { ServiceMail } from '@/services/ServiceMail';
import { ServiceDownload } from '@/services/ServiceDownload';
import VuePdfEmbed from 'vue-pdf-embed';
import ServiceLogs from '@/services/ServiceLogs';

@Options({
    name: "AdminBackOffice",
    components: { Cerere, VuePdfEmbed }
})
export default class AdminBackOffice extends Vue {
    public inputSearchArticle='';
    declare public $refs: any;
    public loadingOffers = true;
    public offersHeaders:Array<TCerereHeader>=[];
    public userStore = getModule(user);
    public pagination ={rowsPerPage: 10};
    public showDialogForUploadPdf=false;
    public selectedCerere=0;
    public reoffersCount = 0;
    public titleUploadCerere='';
    getUrlForUploadProfileImage:string=CONFIG_ENV.URL_COMPANY_USERS.uploadOfertaPDF+'/'+Math.random();
    
    public dynamicComponent='';
    public dynamicComponentTitle='';
    public dialogViewOferta=false;
    public selectedOfferId = '';
    public selectedOfferSlid = '';
    public selectedOfferDate = '';
    public searchInput='';
    public searchCompany='';
    public searchAgent='';
    
    public dialogTransitionShow ='';
    public dialogTransitionHide ='';
    public EventBusStore = getModule(eventbus);
    
    public pdfTitle='';
    public fixed=false;
    public pdf='';
    public popupTitle='';
    public isLoadingOffer=false;
    public widthPdf=300;
    public appid = 0;
    public resendEmail = false;
    
    public columns: any = [];

    public visibleColumns: any = [];

    public filters = {
        id: { value: '', keys: ['id'] },
        data: { value: '', keys: ['data'] },
        nr_items: { value: '', keys: ['nr_items'] },
        user: { value: '', keys: ['user'] },
        cif: { value: '', keys: ['cif'] },
      };

    get user(): TUser {
        return this.userStore.user;
    }

    public refreshFilters(){
        const vueInst = this;
        vueInst.searchInput='';
        vueInst.searchCompany='';
        vueInst.searchAgent='';
        vueInst.getOffersFromDB();
    }


    public getOffersFromDB(){
        const vueInst=this;
        vueInst.$q.loading.show();
        ServiceCerere.getCereri(vueInst.searchInput, vueInst.searchCompany, vueInst.searchAgent).then(response=>{
            vueInst.$q.loading.hide();
            vueInst.loadingOffers=false;
            if(response.status=='success'){
                vueInst.offersHeaders=JSON.parse(JSON.stringify(response.offers));
            }
        })
    }

    /*
     if(vueInst.stocArticol == 0) { //red
            vueInst.labelStock = this.$t('message.on_demand_stock');
            vueInst.labelStockColor = '#FF0000';
        } else if(vueInst.qUm1 > vueInst.stocArticol) {//orange
            vueInst.labelStock = this.$t('message.partial_stock');
            vueInst.labelStockColor = '#FFA500';
        } else if(vueInst.turnover_rate_month > 30){//green
            vueInst.labelStock = this.$t('message.in_stock');
            vueInst.labelStockColor = '#008000';
        } else {//yellow
            vueInst.labelStock = this.$t('message.low_stock');
            vueInst.labelStockColor = '#FFFF00';
        }
    */

    public statusColor(status: string) {
        switch (status) {
            case 'p': return '#E4D900';
            case 'c':  return '#FF0000';
            case 'o':  return '#FFA500';
            case 'k':  return '#008000';
            case 'm':  return '#000000';
            case 'r':  return '#990000';
        }
    }

    public statusHumanReadable(status: string) {
        switch (status) {
            case 'p': return this.$t('message.request_sent');
            case 'c': return this.$t('message.cancelled');
            case 'o': return this.$t('message.offer_sent');
            case 'k': return this.$t('message.order_sent');
            case 'm': return this.$t('message.order_modified');
            case 'r': return this.$t('message.offer_rejected');
        }
    }

    public showUploadForCerere(id_offer: number, reoffers_count: number){
        const vueInst=this;
        vueInst.selectedCerere = id_offer;
        vueInst.reoffersCount = reoffers_count;
        vueInst.showDialogForUploadPdf = true;
        vueInst.titleUploadCerere = this.$t('message.upload_offer_for') + id_offer;
        vueInst.getUrlForUploadProfileImage=CONFIG_ENV.URL_COMPANY_USERS.uploadOfertaPDF+'/oferta_cerere_'+id_offer+'/'+reoffers_count;
    }

    imgForProfileWasUploaded(){
        const vueInst=this;
        vueInst.showDialogForUploadPdf=false;
        ServiceDownload.checkOfferOnSystem(vueInst.selectedCerere.toString(), vueInst.reoffersCount).then(responce => {
            if(responce.status == 'success') {
                vueInst.$q.notify({
                    color: 'teal',
                    textColor: 'white',
                    position:'top',
                    timeout: 1500,
                    message: this.$t('message.offer_was_uploaded')
                });
                ServiceMail.sendMailNewOffer(vueInst.selectedCerere.toString()).then(responce => {
                    vueInst.$q.notify({
                        color: 'teal',
                        textColor: 'white',
                        position:'top',
                        timeout: 1500,
                        message: responce.message
                    });
                });
                ServiceCerere.increaseModifiedNumber(vueInst.selectedCerere.toString());
                ServiceCerere.updateCerereStatus(vueInst.selectedCerere.toString(), 'o').then(response=>{ 
                    if(response.status=='success'){
                        console.log('change status success');
                        vueInst.getOffersFromDB();
                    }
                });
                vueInst.selectedCerere = 0;
            } else {
                vueInst.$q.notify({
                    color: 'red',
                    textColor: 'white',
                    position:'top',
                    timeout: 1500,
                    message: this.$t('message.offer_error_upload')
                });
            }
        }).catch(error => {
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                position:'top',
                timeout: 1500,
                message: this.$t('message.offer_error_upload')
            });
        });
    }

    public resendEmailOffer(id_cerere: string) {
        const vueInst=this;
        vueInst.resendEmail = true;
        ServiceMail.sendMailNewOffer(id_cerere).then(responce => {
            vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                position:'top',
                timeout: 1500,
                message: responce.message
            });
            vueInst.resendEmail = false;
        });
    }

    public onOpenCerere(pOfferId:string|null, pofferDate:string|null){
        if(pOfferId && pofferDate) {
            this.dynamicComponent='Cerere';
            this.dynamicComponentTitle=this.$t('message.request') ;
            this.selectedOfferId = pOfferId;
            this.selectedOfferSlid = pOfferId;//ca sa nu mai fac variabile separate
            this.selectedOfferDate = pofferDate;
            if (this.$q.platform.is.mobile) {
                this.dialogTransitionShow = 'slide-right';
                this.dialogTransitionHide = 'slide-left';
            } else {
                this.dialogTransitionShow = 'slide-right';
                this.dialogTransitionHide = 'slide-left';
            }
            this.dialogViewOferta = true;
        }
    }
    public closeCurrentView(){
        const vueInst=this;
        //vueInst.dialogViewOferta = false;
        vueInst.EventBusStore.set_event({name:'closeCurrentView', params:null});
    }

    public closeFormViewOferta(needRefresh:boolean){
        const vueInst=this;
        vueInst.dialogViewOferta = false;
        // if(needRefresh){
        //     this.getOffersFromDB();
        // }
        vueInst.selectedOfferId='';
    }

    @Watch('EventBusStore.event.eventId')
    onEventIdChanged() {
        const vueInst=this;
        if(vueInst.EventBusStore.event.name=='eventCloseDialogViewOffer'){
            vueInst.dialogViewOferta=false;
        }
        if(vueInst.EventBusStore.event.name=='changeLanguage'){
            this.columns = [
                { name: 'id_offer', label: this.$t('message.request_id'), field: 'id_offer', align: 'center', sortable: true },
                { name: 'track_creation_date', label: this.$t('message.request_date'), field: 'track_creation_date', align: 'left', sortable: true },
                { name: 'nr_articole',  label: this.$t('message.no_items'), field: 'nr_articole', align: 'right', sortable: true },
                { name: 'track_user_id', label: this.$t('message.user'), field: 'track_user_id', align: 'left', sortable: true },
                { name: 'cif',  label: this.$t('message.cif'), field: 'cif', align: 'left', sortable: true },
                { name: 'denumire',  label: this.$t('message.name2'), field: 'denumire', align: 'left', sortable: true },
                { name: 'agent',  label: this.$t('message.agent2'), field: 'agent', align: 'left', sortable: true },
                { name: 'status',  label: this.$t('message.status'), field: 'status', align: 'center', sortable: true },
                { name: 'upload', label: this.$t('message.upload_offer'), field: 'upload', align: 'left' }
            ]
        }
    }

    public changeWitdhPdf(op: string){
        if(op == 'in') {
            if(this.widthPdf > 1500) {return;}
            this.widthPdf *= 1.25; 
        } else {
            if(this.widthPdf < 300) {return;}
            this.widthPdf /= 1.25; 
        }
    }


    public downloadPDF() {
        const vueInst = this;
        const linkSource = vueInst.pdf;
        const downloadLink = document.createElement("a");
        const fileName = vueInst.pdfTitle;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    public generareOferta(offerSlid: string, reoffers_count: number){
        const vueInst = this;
        vueInst.pdf = '';
        vueInst.pdfTitle = '';
        vueInst.popupTitle = '';
        vueInst.isLoadingOffer= true;  
        vueInst.fixed = true;  
        ServiceDownload.generareOfertaPdfLink2(offerSlid, reoffers_count).then(response => {
            vueInst.isLoadingOffer = false;
            if(response.status == 'success') {
                const pathPdf = response.message;
                vueInst.pdfTitle = 'Offer_for_resuest_'+offerSlid+'_'+this.userStore.user.companyName+'.pdf'
                vueInst.pdf = 'data:application/pdf;base64,'+ pathPdf +'#title='+ vueInst.pdfTitle;
                vueInst.popupTitle = this.$t('message.offer') +' ' + offerSlid + ' ' + reoffers_count;
            } else{
                vueInst.fixed = false;  
            }
        });
        
    }

    public activated(){
        this.columns = [
            { name: 'id_offer', label: this.$t('message.request_id'), field: 'id_offer', align: 'center', sortable: true },
            { name: 'track_creation_date', label: this.$t('message.request_date'), field: 'track_creation_date', align: 'left', sortable: true },
            { name: 'nr_articole',  label: this.$t('message.no_items'), field: 'nr_articole', align: 'right', sortable: true },
            { name: 'track_user_id', label: this.$t('message.user'), field: 'track_user_id', align: 'left', sortable: true },
            { name: 'cif',  label: this.$t('message.cif'), field: 'cif', align: 'left', sortable: true },
            { name: 'denumire',  label: this.$t('message.name2'), field: 'denumire', align: 'left', sortable: true },
            { name: 'agent',  label: this.$t('message.agent2'), field: 'agent', align: 'left', sortable: true },
            { name: 'status',  label: this.$t('message.status'), field: 'status', align: 'center', sortable: true },
            { name: 'upload', label: this.$t('message.upload_offer'), field: 'upload', align: 'left' }
        ]
        this.visibleColumns = [ 'id_offer', 'track_creation_date', 'nr_articole', 'track_user_id', 'cif', 'upload', 'status', 'denumire', 'agent'];
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar(this.$t('message.back_office'));
        }
        this.getOffersFromDB();
        const vueInst = this;
        vueInst.appid = 0;
        ServiceLogs.updateTimeSpentPerPage('back_office', '0', vueInst.appid).then(responce => {
            if(responce.status == 'success'){
                vueInst.appid = responce.appid;
            }
        })
        window.addEventListener('beforeunload', function (event) {
            
            ServiceLogs.updateTimeSpentPerPage('back_office', '0', vueInst.appid);
        });
    }

    deactivated(): void {
        const vueInst=this;
        ServiceLogs.updateTimeSpentPerPage('back_office', '0', vueInst.appid);
    }
}
